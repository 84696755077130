const dansk = {
    // Generic
    Update: 'Opdater',
    Cancel: 'Tilbage',
    Create: 'Opret',
    Delete: 'Slette',
    'Delete singular': 'Slet',
    Upload: 'Upload',
    Close: 'Luk',
    Read: 'Læs',
    'Generate Report': 'Hent Rapport',
    'Search the table': 'Søg i tabellen',
    Page: 'Side',
    of: 'af',
    Images: 'Billeder',
    Dates: 'Datoer',
    Date: 'Dato',
    Note: 'Note',
    Status: 'Status',
    Country: 'Land',
    'Select all': 'Vælg alle',
    Allow: 'Tillad',
    Business: 'Forretning',
    Search: 'Søg',
    More: 'Mere',
    Hide: 'Skjul',
    Other: 'Andre',
    Save: 'Gem',
    Unknown: 'Ukendt',
    Choose: 'Vælg',
    Website: 'Hjemmeside',
    more: 'mere',
    'Created date': 'Oprettet dato',
    Parkinglots: 'Parkeringspladser',

    // User
    Username: 'Brugernavn',
    Password: 'Kodeord',
    'Created By': 'Oprettet af',
    'Created by': 'Oprettet af',
    'Created Date': 'Oprettet dato',
    'Updated Date': 'Opdateret dato',
    'Last login web': 'Sidste login web',
    'Last login app': 'Sidste login app',
    Admin: 'Admin',

    // Menu
    Home: 'Hjem',
    Dashboard: 'Dashboard',
    'Map of Parking Lots': 'Kort over P-pladser',
    'Parking Lots': 'Parkeringspladser',
    Users: 'Brugere',
    Permits: 'Tilladelser',
    Fines: 'Afgifter',
    Tickets: 'P-billet',
    Customers: 'Kunde',
    Backoffice: 'Backoffice',
    Map: 'Kort',
    Statistics: 'Statistik',
    Subscriptions: 'Abonnementer',
    'Organisation list': 'Organisationsliste',
    Finance: 'Finans',
    'Ticket Service': 'Køb en P-billet',
    'Buy a permit': 'Køb en P-billet',
    'Get a permit': 'Få en P-billet',
    'Private Ticket Service': 'Få en P-billet',
    Reports: 'Rapporter',
    Rent: 'Leje',
    Prices: 'Priser',
    'Short term': 'Korttidsvilkår',
    Rental: 'Abonnementsvilkår',

    // Settings
    Settings: 'Indstillinger',
    'User Details': 'Brugeroplysninger',
    Language: 'Sprog',
    'Parking Lot': 'Parkeringsplads',
    'Parking lot': 'Parkeringsplads',
    Street: 'Gade',
    'Street Number': 'Gadenummer',
    ZipCode: 'Postnummer',
    City: 'By',
    'Notification settings': 'Notifikationsindstillinger',
    'WELCOME TO': 'VELKOMMEN TIL',

    // Login
    Login: 'Log ind',
    'Forgot Password': 'Glemt kodeord',
    Email: 'Email',
    'Reset Password': 'Nulstil kodeord',
    'Password reset email sent. Please check your email.':
        'Email til nulstilling af adgangskode sendt. Tjek venligst din email',
    'Something went wrong': 'Noget gik galt',
    'Wrong username or password': 'Forkert brugernavn eller adgangskode',
    'Error resetting password, please retry':
        'Fejl ved nulstilling af adgangskode. Prøv venligst igen',
    'Digital parking solution for every need':
        'Digital parkeringsløsning til ethvert behov',

    // Search bar
    'None selected': 'Ingen valgte',
    'All selected': 'Alle valgt',
    'Only active': 'Kun aktive',
    'Show cancelled': 'Vis annullerede',

    // Frontoffice
    'Latest fines': 'Seneste afgifter',
    'No data available!': 'Ingen tilgængelig data!',
    'There were no datapoints to show on this graph':
        'Der var ingen datapoint at vise på denne graf',
    'Good fines': 'Gode afgifter',
    Annulled: 'Annulleret',
    'Could not fetch fines - please refresh and try again':
        'Kunne ikke hente afgifter - opdater venligst og prøv igen',
    'Something went wrong, could not find any users - Please refresh and try again.':
        'Noget gik galt, kunne ikke finde nogen brugere - vær venlig at opdatere og prøve igen.',
    Activated: 'Aktiveret',
    Customer: 'Kunde',
    'User list': 'Brugerliste',
    'Create User': 'Opret bruger',
    Yes: 'Ja',
    No: 'Nej',
    'Your Cars': 'Dine biler',
    'Location Name': 'Stedsnavn',
    'Permit list': 'Tilladelsesliste',
    'Create Permit': 'Opret tilladelse',
    'Start date': 'Start dato',
    'Start Date': 'Start Dato',
    'End date': 'Slut dato',
    'End Date': 'Slut Dato',
    Type: 'Type',
    'Could not find any parking lots - Please refresh and try again.':
        'Kunne ikke finde nogen parkeringspladser - vær venlig at opdatere og prøve igen.',
    'Parking Lots List': 'Parkering Lots List',
    Address: 'Adresse',
    'Parking Map': 'Parkering Map',
    'Could not find any hourly data - Please refresh and try again.':
        'Kunne ikke finde nogen timedata - opdater venligst og prøv igen.',
    'Something went wrong, could not find any daily data - Please refresh and try again.':
        'Noget gik galt, kunne ikke finde nogen daglige data - opdater venligst og prøv igen.',
    'Something went wrong, could not find any look up data - Please refresh and try again.':
        'Noget gik galt, kunne ikke finde nogen look up data - opdater venligst og prøv igen.',
    'Hourly visits count': 'Timesbesøg tæller',
    'Daily visits count': 'Daglige besøgstal',
    'Fine list': 'Afgiftsliste',
    'Could not generate the report - please refresh and try again':
        'Kunne ikke generere rapporten - opdater venligst og prøv igen',
    'country code': 'Landekode',
    cancelFine: 'Hvorfor annullerer du denne afgift? (Min. 20 tegn)',
    Comments: 'Kommentarer',
    'Cancelled fines': 'Annullerede afgifter',
    'Active fines': 'Aktive afgifter',
    'Total fines': 'Alle afgifter',
    'Parking Lot list': 'Parkeringspladsliste',
    'Reg. nr.': 'Reg. nr.',
    Code: 'Kode',
    // Backoffice
    'Customer list': 'Kunde liste',
    'Something went wrong in searching for customers':
        'Noget gik galt i at søge efter kunder',
    'Create Customer': 'Opret kunde',
    Name: 'Navn',
    'Create Fine': 'Opret Afgift',
    'Generate DMR report': 'Hent DMR-rapport',
    'Advanced Report': 'Avanceret rapport',
    'Mass change': 'Masseændring',
    'Customers info': 'Kunder info',
    'Accounting info': 'Regnskabsinfo',
    'Create p-lot': 'Opret p-plads',
    Add: 'Tilføj',
    'Parking Lot Info': 'Parkeringsplads info',
    'Add ruleset': 'Tilføj regelsæt',
    Rules: 'Regler',
    'Show Spots and Groups': 'Vis steder og grupper',

    // Modals
    'Send Password': 'Send adgangskode',
    Contractor: 'Håndværker',
    'Settlement dates': 'Udbetalingsdatoer',
    Offence: 'Forseelse',
    'Incident information': 'Hændelsesoplysninger',
    'Incident Information': 'Hændelsesoplysninger',
    'Leave a comment...': 'Efterlad en kommentar...',
    Latitude: 'Breddegrad',
    'Loading...': 'Indlæser...',
    'Could not find the parking lot you were looking for.':
        'Kunne ikke finde parkeringspladsen, du ledte efter.',
    'Successfully updated the parking lot!':
        'Succesfuldt opdateret parkeringspladsen!',
    'Successfully created new parking lot!':
        'Med succes skabt ny parkeringsplads!',
    'You can now close the modal.': 'Du kan nu lukke modal.',
    'Geographic coordinate': 'Geografisk koordinat',
    Longitude: 'Længdegrad',
    'Parking Lot Details': 'Parkeringsplads detaljer',
    'Rules for parking': 'Regler for parkering',
    'Rules for guest parking': 'Regler for gæsteparkering',
    'Show customer': 'Vis kunde',
    'Number of active permits created by one user at the same time':
        'Antal aktive tilladelser oprettet af én bruger på samme tid',
    'Maximum length for created permits in app':
        'Maksimal længde for oprettede tilladelser i ca',
    'Note for app': 'Bemærkninger til app',
    'Profile Image': 'Profilbillede',
    'Street Name': 'Gadenavn',
    'Camera Fines': 'Kameraafgifter',
    'Camera fine list': 'Kameraafgiftsliste',
    'Camera Settings': 'Kameraindstillinger',
    Warning: 'Advarsel',
    'Changing Reg. No.': 'Ændring af Reg. No.',
    'Country and reg. no.': 'Land og reg. nr.',
    'If reg. no. is not correct and you need to adjust it, "Registered before" will not update. Please doublecheck your changes are correct before applying them':
        'Hvis reg. no. ikke er korrekt, og du skal rette det, vil "tidligere registreringer" ikke opdatere. Dobbelttjek venligst, at dine ændringer er korrekte, før du anvender dem',
    'Postal Code': 'Postnummer',
    'House.No': 'Hus.Nr',
    'Parking Terms': 'Parkeringsbetingelser',
    'Fine Price': 'Afgift Pris',
    'Control Type': 'Kontroltype',
    'Camera control': 'Kamerakontrol',
    'Camera Control': 'Kamerakontrol',
    'Guard control': 'P-vagtskontrol',
    'Guard Control': 'P-vagtskontrol',
    'Self control': 'Selvkontrol',
    'Self Ticketing': 'Selvbilletering',
    'Need for collection': 'Behov for indsamling',
    'Parking Spots': 'Parkeringsbåse',
    'Rent?': 'Leje?',
    Distribution: 'Fordeling',
    'Amount of Duty': 'Toldbeløb',
    'Why are you canceling this Fine? - Minimum 20 characters':
        'Hvorfor annullerer du denne afgift? - Minimum 20 tegn',
    'Send and Cancel Fine': 'Send og annullér afgift',
    'Cancel Fine': 'Annullér afgift',
    'Could not find the fine you were looking for.':
        'Kunne ikke finde den afgift du søger.',
    'Could not find the permit you were looking for.':
        'Kunne ikke finde den tilladelse, du ledte efter.',
    'Belongs to': 'Tilhører',
    'Internal note': 'Intern note',
    'External note': 'Ekstern note',
    'Could not create the permit - close and try again please':
        'Kunne ikke oprette tilladelsen - tæt og prøv igen venligst',
    'Could not update the permit - close and try again please':
        'Kunne ikke opdatere tilladelsen - luk og prøv igen venligst',
    'User successfully created, you can close the modal again':
        'Bruger med succes oprettet, kan du lukke modal igen',
    'User successfully updated, you can close the modal again':
        'Bruger med succes opdateret, kan du lukke modal igen',
    'User credentials': 'Brugeroplysninger.',
    'Confirm password': 'Bekræft kodeord',
    'The password matches': 'Adgangskoden matches',
    'The password does not matches': 'Adgangskoden svarer ikke til',
    Permissions: 'Tilladelser',
    'Users to manage': 'Brugere til at styre',
    'Activate User': 'Aktiver Bruger',
    'Access to Website': 'Adgang til hjemmesiden',
    'Access to App': 'Adgang til App',
    'Access to API': 'Adgang til API',
    'Handle Fines': 'Håndter Afgifter',
    'Handle Permits': 'Håndter Tilladelser',
    User: 'Bruger',
    Permit: 'P-billetter',
    Fine: 'Afgift',
    'Update customer': 'Opdateringskunde',
    'Customer Type': 'Kunde type',
    Created: 'Oprettet',
    Updated: 'Opdateret',
    'Connected Users': 'Tilknyttede brugere',
    'Connected parking lots': 'Tilknyttede parkeringspladser',
    'Total amount of users:': 'Samlet antal brugere:',
    'Fine successfully updated, you can close the modal again':
        'Afgiften er opdateret, du kan lukke modalet igen',
    'Fine successfully created, you can close the modal again':
        'Afgiften er oprettet, du kan lukke modalet igen',
    'Update Fine': 'Opdater Afgift',
    'New Fine': 'Ny Afgift',
    Owner: 'Ejer',
    'Parking guard': 'P vagt',
    'House number': 'Husnummer',
    'House nr.': 'Husnummer',
    'Open Parking Lot': 'Åben parkeringsplads',
    'Update parking lot': 'Opdater parkeringsplads',
    'External Customers': 'Eksterne kunder.',
    'Back to Fine': 'Tilbage til afgift',
    'Update user': 'Opdater brugeren',
    'Update User': 'Opdater Bruger',
    'New user': 'Ny bruger',
    'Update subscription': 'Opdater abonnement',
    'New subscription': 'Nyt abonnement',
    'Create subscription': 'Opret abonnement',
    'Create Subscription': 'Opret Abonnement',
    'Creating subscription...': 'Opretter abonnement...',
    'Show Expired': 'Vis Inactive',
    'Please select a company': 'Vælg venligst et firma',
    "Customer's parking lots": 'Kundens parkeringspladser',
    'User Identity': 'Brugeridentitet',
    Identity: 'Identitet',
    'User Identities': 'Brugeridentiteter.',
    'No identities for user': 'Ingen identiteter for brugeren',
    'Fine Details': 'Afgiftsdetaljer',
    'No one has commented or changed status yet.':
        'Ingen har kommenteret eller ændret status endnu.',
    'Leave a note on the incident': 'Forlad en note på hændelsen',
    'Update Fines': 'Opdater Afgifter',
    'This will reset the password and email the new password. Do you want to continue?':
        'Dette vil nulstille adgangskoden og e-maile den nye adgangskode. Vil du fortsætte?',
    'Parking Info': 'Parkeringsinfo',
    Organizations: 'Organisationer',
    Organization: 'Organisation',
    'Payout Details': 'Udbetalingsdetaljer',
    'Bank Registration Number': 'Bank registreringsnummer',
    'Bank Account Number': 'Kontonummer',
    '% for parking guard': '% til p-vagt',
    '% for customer': '% til kunde',
    '% for OPARKO': '% til OPARKO',
    'Charge Distributions': 'Afgiftsfordelinger',
    'There are no Parking Lots': 'Der er ikke nogen parkeringspladser',
    'There are no Users': 'Der er ikke nogen brugere',
    'External Organizations': 'Eksterne Organisationer',
    'Select image': 'Vælg billede',
    'Outside booth': 'Udenfor bås',
    'No permit to the parking area': 'Ingen tilladelse til parkeringspladsen',
    'P-disc exceeded': 'P-skive overskredet',
    'Parking or stopping in prohibited area':
        'Parkering eller stop i forbudt område',
    'Parking in fire lane': 'Parkering på brandvej',
    'Handicap booth without permission': 'Handicapbås uden tilladelse',
    "Organization's Parking Lots": 'Organisationens parkeringspladser',
    'Choose Parking bays': 'Vælg parkeringsbåse',
    'Permanent user': 'Permanent bruger',
    'New Permit': 'Ny Tilladelse',
    'Update Permit': 'Opdater Tilladelse',
    'Created for user': 'Oprettet for bruger',
    Notes: 'Noter',
    'Permit Information': 'Tilladelsesoplysninger',
    'Date not assigned': 'Dato ikke tildelt',
    'Note from creation': 'Note fra oprettelsen',
    'Created at': 'Oprettet',
    'Updated at': 'Opdateret',
    'Address name': 'Adressenavn',
    'Address Name': 'Adressenavn',
    'Arrival date': 'Ankomstdato',
    'Arrival Date': 'Ankomstdato',
    'Depart. Date': 'Afrejsedato',
    'Depart. date': 'Afrejsedato',
    Camera: 'Kamera',
    'Camera note': 'Kamera note',
    'Other - Leave a note': 'Andet - Lav et notat',
    'Parking time exceeded': 'Parkeringstid overskredet',
    'Total parking time': 'Samlet parkeringstid',
    Approve: 'Godkend',
    Decline: 'Afvis',
    Back: 'Tilbage',
    'Employee Paid Ticket': 'Employee Paid Ticket',
    'Street name': 'Gadenavn',
    'Postal code': 'Postnummer',
    'Fine price': 'Afgiftspris',
    'Profile image': 'Profilbillede',
    'Parking terms': 'Parkeringsbetingelser',
    'No results found': 'Ingen resultater fundet',
    'Buffer time in minutes': 'Buffertid i minutter',
    'Active permits': 'Aktive tilladelser',
    'Duration of permits': 'Tilladelsers varighed',
    selected: 'valgte',
    Distance: 'Afstand',
    'Organization type': 'Organisationstype',
    'Organization address': 'Organisationens adresse',
    'Organization details': 'Organisationsoplysninger',
    'Create Organization': 'Opret Organisation',
    'The permit will be made for every parking lot chosen, and based on the users start and end date.':
        'Parkeringstilladelsen vil blive givet for hver valgt parkeringsplads, baseret på brugernes start- og slutdato.',
    'Comment added by': 'Kommentar tilføjet af',
    'Status changed from': 'Status ændret fra',
    'Status changed by': 'Status ændret af',
    'Not defined': 'Ikke defineret',
    'Not Defined': 'Ikke defineret',
    'Self ticketing': 'Selvbillettering',
    Combination: 'Kombination',
    'P-guard': 'P-vagt',
    'Registered before': 'Tidligere registreringer',
    'Send receipt copy': 'Send kopi af kvittering',
    'You already have a user': 'Du har allerede en bruger',
    'Login to purchase an extra subscription':
        'Log ind for at købe et ekstra abonnement',
    'Missing payment in min': 'Manglende betaling i min',
    'Open Subscriber': 'Åbn abonnent',
    'Every permit row is clickable and show data in Permit Modal':
        'Hver tilladelsesrække er klikbar og viser data i Permit Modal',
    'Choosing multiple parking lots creates a singular permit for each address selected':
        'Valg af flere parkeringspladser skaber en enkelt tilladelse for hver valgt adresse',
    'These rules only affects the permits created from the app, not permits created by admin from Web':
        'Disse regler påvirker kun den tilladelse, der er oprettet via appen, ikke tilladelser oprettet af admin via Web',
    '0 = none and empty = unlimited': '0 = ingen og tom = ubegrænset',
    Coords: 'Placering',
    'Additional images': 'yderligere billeder',
    'Fine rules': 'Afgiftsregler',
    'Permit rules': 'Tilladelsesregler',
    'Connected organisations & users': 'Tilknyttede organisationer og brugere',
    'ID generated upon creation': 'ID genereres ved oprettelse',
    'Bank name': 'Bank navn',
    'Bank country': 'Bank land',
    'Billing Email': 'faktureringsmail',
    'Payment frequency': 'Betalingsfrekvens',
    'Agreed by': 'Aftalt af',
    'Agreed on (Date - Start)': 'Aftalt den (Dato - Start)',
    'Agreed on (Date - End)': 'Aftalt den (Dato - Slut)',
    'Connected parking lots & users':
        'Tilknyttede parkeringspladser og brugere',

    // Finance
    DKK: 'kr.',
    pcs: 'stk',
    k: 't',
    Revenue: 'Omsætning',
    'Paid Permits': 'P-billetter',
    'Coming soon': 'Kommer snart',
    'Total revenue': 'Samlet omsætning',
    'Total paid out': 'Samlet udbetalt',
    'Total outstanding': 'Samlet udestående',
    'Revenue sources for permits in DKK':
        'Indtægtskilder for tilladelser i kr.',
    'Permits per parking operator': 'P-billetter pr. parkeringsoperatør',
    'Number of parking tickets made through each parking operator.':
        'Antal p-billetter og omsætning lavet gennem hver parkeringsoperatør.',
    'Subtext: This shows the economy and the number of parking tickets':
        'Her vises økonomien og antallet af p-billetter for hver parkeringsoperatør på området, så fordelingen af omsætning kan ses i den valgte periode.',
    'You have no permits to show': 'Du har ikke tilladelser at vise',
    'Revenue by type in DKK': 'Omsætningsfordeling',
    'Revenue distribution from the car parks owned by your organisation. The graph visualizes the turnover of the different products offered.':
        'Omsætningsfordeling fra de parkeringspladser, som din organisation ejer. Grafen visualiserer omsætningen for de forskellige produkter der tilbydes.',
    'Fines by status': `Antal afgifter efter status`,
    'Post collections': 'Efteropkrævninger',
    'Post collection': 'Efteropkrævninger',

    'Active: status explained':
        'Aktiv: En afgift, der er blevet oprettet, men stadig er under behandling',
    'Cancelled: status explained':
        'Annulleret: Afgifter som er annulleret af jer eller OPARKO',
    'Paid: status explained': 'Betalt: Afgiften er betalt til OPARKO',
    'Settled: status explained': 'Afregnet: Afgiften er betalt til kunden',
    'Protected: status explained':
        'Beskyttet: Afgiften er midlertidigt sat på pause',
    'Debt collection/Collectia: status explained':
        'Inddrivelse: Gældsinddrivelsen er i gang ved OPARKOs inkassoselskab',

    finance_revenue_barchart:
        'Omsætning i kr. pr. måned for indeværende år opdelt efter omsætningstype',

    revenue_by_type_explanation:
        'Omsætningsfordeling fra de abonnemnter, som din organisation tilbyder. Grafen visualiserer omsætningen for de forskellige abonnementsprodukter der tilbydes.',
    'reveneue_sub_text_sub: abo':
        'Her fremvises økonomien og antallet af abonnementer for hver tjenesteudbyder i området, således at fordelingen af indtægter kan observeres i den valgte periode.',
    'Fines: Every fine that is paid or settled':
        'Afgifter: Alle afgifter der er betalt eller afregnet',
    'Permits: Every permit that is paid':
        'P-billetter: Alle P-billetter der er betalt',
    'Subscriptions: Every payment on all subscriptions':
        'Abonnementer: Alle betalinger på alle abonnementer',
    post_collection_explanation: `Efteropkrævning: Beregnes som 'indsamlingsgebyr' + 'pris pr. time' * 'timer parkeret mere end tilladt' og er en ekstra afgift til parkerede personer, der har parkeret længere end deres tilladelser tillod dem, og fungerer som et alternativ til parkeringsafgifter.`,

    permit_revenue_sources_explanation:
        'Indtægtsbeløbet i kr, som er foretaget på tilladelser for hver parkeringsoperatør.',
    number_of_permits_per_parking_operator_explained:
        'Mængden af tilladelser der er blevet lavet af hver parkeringsoperatør.',

    fine_status_distribution_explanation:
        'Den procentvise fordeling af afgiftsstatusser.',
    'Please wait, your data is being prepared...':
        'Vent venligst, dine data forberedes...',

    // Statistic
    fee_search: 'Gammel App',
    fine_search: 'App - afgift oprettet',
    permit_search: 'App - tilladelse oprettet',
    scanner_used: 'App - scanner brugt',
    'Shows a graph of app events for multiple days, sorted by hours.':
        'Viser en graf over app-hændelser for flere dage, sorteret efter timer.',
    'Shows a graph of app events, sorted by dates.':
        'Viser en graf over app-hændelser, sorteret efter datoer.',
    'The use of the old app.': '- Brugen af den gamle app.',
    'Creation of permits in the oparko app.':
        '- Oprettelse af tilladelser i oparko-appen.',
    'Creation of Fines in the oparko app':
        '- Oprettelse af afgifter i oparko-appen.',
    'Scanning of license plates in the oparko app.':
        '- Scanning af nummerplader i oparko-appen.',
    'Permits and Subscriptions per month':
        'Præsenterer månedlige tilladelser, abonnementer og P-billetter, som har været aktiv i denne periode, med en detaljeret opgørelse af, hvad hver enkelt organisation har registreret i den pågældende periode.',
    'Show Day Graph Date': 'Vis graf data i dage',
    'Occupancy by permits': 'Beskæftigelse efter tilladelser',
    'Total parking spots': 'Samlet antal parkeringsbåse',

    'Permits: Shows the amount of permits by period. If more than one week is chosen, the sum of all weeks will be shown on a give period on a weekday.':
        'Tilladelser: Viser antal tilladelser pr. periode. Hvis mere end en uge er valgt vil antallet af tilladelser gældende på hver hverdag blive lagt sammen',
    'Total parking spots: Shows the total amount of parking spots':
        'Samlet antal parkeringsbåse: Viser det samlede antal parkeringsbåse',
    'Average hourly occupancy over the selected period.':
        'Gennemsnitlig timebelægning i den valgte periode',
    'Average daily occupancy over the selected period.':
        'Gennemsnitlig dagsbelægning i den valgte periode.',
    'Average monthly occupancy over the selected period.':
        'Gennemsnitlig månedsbelægning i den valgte periode.',
    'Average for the chosen period': 'Gennemsnit for den valgte periode',
    'Fines for the chosen period': 'Afgifter for den valgte periode',
    Occupancy: 'Gennemsnit',
    'Highest Occupancy': 'Højeste',
    'Lowest Occupancy': 'Laveste',
    'Highest avg for the days in the period.':
        '- Højeste procent for dagene i perioden.',
    'Lowest avg for the days in the period.':
        '- Laveste procent for dagene i perioden.',
    'Avg for the days in the period.': '- Gennemsnit for dagene i perioden.',
    no_permit: 'Ingen tilladelse',
    permit: 'Tilladelse',
    ticket: 'P-billet',
    subscription: 'Abonnement',
    'Too many organisations selected': 'For mange organisationer valgt',
    'Can not generate report for the selected parking lots.':
        'Kan ikke generere rapport for de valgte parkeringspladser.',
    'Can not generate report for the selected organisations.':
        'Kan ikke generere rapport for de valgte organisationer.',

    Average: 'Gennemsnit',
    Highest: 'Højeste',
    Lowest: 'Laveste',
    'Hourly camera activity count': 'Kameraaktivitet pr. time',
    'Daily camera activity count': 'Kameraaktivitet pr. dag',
    'Camera activity': 'Kameraaktivitet',
    'App activity': 'App aktivitet',
    Arrivals: 'Ankomster',
    Departures: 'Afgange',
    'Show only fine activity': 'Vis kun afgiftsaktivitet',
    'Hourly app activity count': 'App aktivitet pr. time',
    'Daily app activity count': 'App aktivitet pr. dag',
    Scanner: 'Scanner',
    'Fine created': 'Afgift oprettet',

    // Date Picker
    Between: 'Imellem',
    From: 'Fra',
    To: 'Til',
    Unit: 'Periode',
    Max: 'Maks antal',
    Min: 'Minimum antal',
    Prior: 'Før',
    Custom: 'Tilpas',
    Today: 'Idag',
    'Since Yesterday': 'Efter igår',
    'This Week': 'Denne uge',
    Anytime: 'Enhver tid',
    'Last month': 'Sidste md',
    'Last 3 months': 'Sidste 3 mdr',
    'Last 12 months': 'Sidste 12 mdr',
    Ticket_Rule: 'Tilladelse Regel',
    Rule_sets: 'Regelsæt',
    Details: 'Detaljer',
    InformationGuide: 'Informationsguide',
    RuleSetsDescription:
        "Under 'Regelsæt' kan du definere unikke billetregler. Du kan lave så mange, som du har brug for:",
    PickDays: 'Vælg hvilke dage reglerne gælder for',
    PickTime: 'Vælg tidspunktet på dagen',
    PickUnit:
        'Vælg enheden efterfulgt af "minimum" og "maksimum" antal timer/dage, der kan købes med denne regel',

    // Table Values
    company: 'selskab',
    private: 'privat',
    Inactive: 'Inaktiv',
    Active: 'Aktiv',
    Paid: 'Betalt',
    'Not paid': 'Ikke betalt',
    'Paid ': 'Betalt',
    Settled: 'Afregnet',
    'Settled ': 'Afregnet',
    Hidden: 'Annulleret',
    'Hidden ': 'Annulleret',
    Cancelled: 'Annulleret',
    Protected: 'Beskyttet',
    'Protected ': 'Beskyttet',
    'Guest parking': 'Gæsteparkering',
    Ticket: 'Billet',
    'Paid Ticket': 'Betalt billet',
    'Only for backoffice': 'Kun for backoffice',
    'Editable permit': 'Redigerbar tilladelse',
    Employee: 'Medarbejder',
    'Permanent permit': 'Permanent tilladelse',
    'Debt collection': 'Inddrivelse',
    Collectia: 'Collectia',
    'Active - handled manually': 'Aktiv - håndteres manuelt',
    'Active - manuel': 'Aktiv - manuel',
    'Active - system': 'Aktivt - system',

    // Validation
    'Issues: ': `Problemer: `,

    'no Name chosen, ': 'ingen Navn valgt, ',
    'no Organization chosen, ': 'ingen Organisation valgt, ',
    'no Street Name chosen, ': 'ingen Vej Navn valgt, ',
    'no Street Number chosen, ': 'ingen Vej Nummer valgt, ',
    'no Postal Code chosen, ': 'ingen By Nummer valgt, ',
    'no City chosen, ': 'ingen By valgt, ',
    'no Latitude chosen, ': 'Ingen Breddegrad valgt, ',
    'Latitude must be above -90, ': 'Breddegrad skal være over -90, ',
    'Latitude must be below 90, ': 'Breddegrad skal være under 90, ',
    'no Longitude chosen, ': 'ingen Længdegrad valgt, ',
    'Longitude must be above -90, ': 'Længdegrad skal være over -90, ',
    'Longitude must be below 90, ': 'Længdegrad skal være under 90, ',
    'no type chosen, ': 'ingen Type valgt, ',
    'parking terms do not accept more than 255 character':
        'parkeringsbetingelser accepterer ikke mere end 255 tegn',

    Reset: 'Nulstil',
    'Accept our terms': 'Accepter vores vilkår',
    'Enter your registration no.': 'Indtast dit registreringsnr.',
    'phone number': 'telefonnummer',
    email: 'e-mail',
    'then press ok': 'og tryk derefter på opret',
    'Your permit ends': 'Din tilladelse gælder',
    'by today 23:59': 'til i dag 23:59',
    after: 'efter ',
    'after the creation': 'efter oprettelse',
    minutes: 'minuter',
    and: 'og',
    until: 'indtil',
    'ends in': 'i ',

    'Reg. no.': 'Reg. nr.',
    'Phone number': 'Telefonnummer',
    Subscription_percentage_not_within_range: `Abonnement '% til OPARKO' skal være i intervallet 0% til 100%`,
    Permit_percentage_not_within_range: `Tilladelse '% til OPARKO' skal være i intervallet 0% til 100%`,
    Camera_control_percentage_not_within_range: `Kamerakontrol '% til OPARKO' skal være i intervallet 0% til 100%`,
    Selfticketing_percentage_not_within_range: `Selvbilletering '% til OPARKO' skal være i intervallet 0% til 100%`,
    Guard_control_percentage_not_within_range: `P-vagtskontrol '% til OPARKO' skal være i intervallet 0% til 100%`,

    // Ticket Service validation
    'must be a positive integer': 'skal være et positivt heltal',
    'Reg. no. have not been specified': 'Reg. nr. er ikke angivet',
    'E-mail has not been specified': 'E-mail er ikke angivet',
    'Terms and conditions must be accepted':
        'Vilkår og betingelser er ikke accepteret',
    Issues: 'Problemer',

    // Act as user
    'Take Over': 'Overtag bruger',

    // Old Terms
    Terms_titles:
        'Vilkår og betingelser for brug af Oparko og parkeringskompagniet.dk',
    Terms_para_1:
        'Vi opbevarer og transmitterer brugeroplysninger krypteret. Indtastede personoplysninger registreres som beskrevet i vores datapolitik (se afsnit 4). Ved at acceptere nærværende betingelser, accepterer Brugeren samtidig at være bekendt med og at have accepteret nærværende vilkår og betingelser for brug af Parkeringskompagniets produkter. For særlige regler om Oparko abonnementsparkering se herunder under ”Oparko Abonnementsparkering”. Parkeringskompagniet kan indsamle information og udarbejde statistikker og rapporter med henblik på at forbedre og levere det bedste produkt samt service til Brugeren.',
    Terms_main_point_1: {
        main_title: 'Anvendelse',
        sub_point_1:
            'Disse Almindelige Vilkår og Betingelser (de ”Almindelige Vilkår og Betingelser”) gælder for leveringen af Tjenester fra Parkeringskompagniet ApS, CVR nr. 37127385, Trindsøvej 4, 8200 Aarhus, (”Parkeringskompagniet”), til brugere (”Brugeren”) i Danmark.',
        sub_point_2:
            'BEMÆRK! Brug af Parkeringskompagniets tjenester kræver, at du er registreret ejer eller bruger af køretøjet eller at du på anden vis har tilladelse til brug og registrering af køretøjet.',
        sub_point_3: {
            sub_point_3_title:
                'Alle Tjenester leveres i henhold til gældende lovgivning og i henhold til:',
            sub_point_3_1:
                'eventuelle individuelt aftalte betingelser og vilkår, og',
            sub_point_3_2: 'disse Almindelige Vilkår og Betingelser.',
            sub_point_3_3:
                'I tilfælde af uoverensstemmelser mellem ovenstående bestemmelser, har de prioritet i ovenstående rækkefølge.',
        },
        sub_point_4:
            'Punkt 5.1.4, 6.4 og 7.7 indeholder særlige bestemmelser om korrekt og gyldig indtastning af registreringsnummer og områdekode.',
        sub_point_5:
            'Punkt 5.3. indeholder særlige bestemmelser om anvendelsen af Oparko-charge.',
        sub_point_6:
            'Ved registrering hos Oparko (se punkt 5), accepterer Brugeren at være underlagt disse Almindelige Vilkår og Betingelser. Der bliver indgået en bindende aftale (”Aftalen”), når Parkeringskompagniet har bekræftet Brugerens registrering, og Brugeren får adgang til Parkeringskompagniets Tjenester.',
        sub_point_7:
            'Disse Almindelige Vilkår og Betingelser udgør Aftalen mellem Parkeringskompagniet og Brugeren.',
        sub_point_8:
            'Disse Almindelige Vilkår og Betingelser er gældende for alle transaktioner og registreringer via Parkeringskompagniet, herunder også Oparko og Oparko Abonnementsparkering.',
    },
    Terms_main_point_2: {
        main_title: 'Definitioner',
        sub_point_1: {
            sub_point_1_title:
                'I disse Almindelige Vilkår og Betingelser og i forbindelse med Tjenesterne gælder følgende definitioner:',
            sub_point_1_1:
                'Ved ” Oparko ” forstås den digitale parkeringstjeneste som kan benyttes i forbindelse med betaling for parkering, via App og hjemmesiden;',
            sub_point_1_2:
                'Som ”App” forstås Parkeringskompagniets mobiltelefonapplikation;',
            sub_point_1_3:
                '”Oparko-System” er Oparko digitale system for tidssvarende parkering, som Brugeren opnår adgang til via, Appen og/eller via Hjemmesiden;',
            sub_point_1_4:
                'Ved ”Hjemmeside” henvises til Parkeringskompagniets hjemmeside <a class="text-pk-blue" href="https://parkeringskompagniet.dk/">https://parkeringskompagniet.dk/</a>.',
            sub_point_1_5:
                '”Parkeringstjeneste” er Brugerens mulighed for at forvalte parkeringer i Appen;',
            sub_point_1_6:
                'En ”Parkeringsplads” er et fysisk område som matcher med et parkeringsområde markeret på oversigtskortet (ved parkeringsplads er ikke at forstå en afmærket parkeringsbås);',
            sub_point_1_7:
                '”Parterne” og ”Part” betyder Parkeringskompagniet og/eller Brugeren;',
            sub_point_1_8:
                '”Bruger” anvendes i disse Almindelige Vilkår og Betingelser om parkanten som benytter Parkeringskompagniet i forbindelse med betaling for parkering.',
            sub_point_1_9:
                '”Partner” betyder partnere, som Parkeringskompagniet samarbejder med;',
            sub_point_1_10:
                '”Tjeneste(r)” har den betydning der er angivet i punkt 3.1.1.',
            sub_point_1_11:
                '”Parkeringsafgiften” er prisen for den foretagne parkering.',
            sub_point_1_12:
                '”Aktive køretøj” betegner et køretøj som brugeren har valgt, at der automatisk sker betaling for i forbindelse med parkering på såkaldte ANPR-parkeringspladser. Betaling sker via den betalingsmetode som Brugeren har angivet som foretrukket.',
            sub_point_1_13:
                '”ANPR” står for Automatisk NummerPlade Registrering, hvorved forstås at køretøjets nummerplade aflæses automatisk og registreringsnummeret registreres i parkeringssystemet. Parkeringspladser med ANPR systemer er markeret med et kamera i Oparko, og det fremgår af skiltningen på parkeringspladsen, at nummerpladen aflæses.',
            sub_point_1_14:
                'Ved “Oparko Charge” menes der Parkeringskompagniets egne og tilknyttede systemer og apps fra 3. part, som kan benyttes i forbindelse med opladning af el- og hybridbil. Dette er både ladestandere, betalingssystemer og –apps.',
        },
        sub_point_2:
            'Der kan være angivet definitioner andre steder i disse Almindelige Vilkår og Betingelser.',
    },
    Terms_main_point_3: {
        main_title: 'Almindelige Vilkår og Betingelser for Tjenesterne',
        sub_point_1: {
            sub_point_1_title: 'Generelt',
            sub_point_1_1:
                'Parkeringskompagniet tilbyder et digitalt system for parkering (” Oparko ”), hvorved Parkeringskompagniet, (i samarbejde med andre Partnere,) giver Brugeren mulighed for at administrere parkering af køretøjer (”Parkeringstjenesten”) og opladning af el-køretøjer. Parkeringstjenesten og de til enhver tid øvrige tjenester leveret af Parkeringskompagniet kaldes samlet for ”Tjenesterne”.',
        },
        sub_point_2:
            'For en detaljeret beskrivelse af Tjenesterne henvises til de, til enhver tid angivne informationer på Hjemmesiden og <a href="https://parkeringskompagniet.dk/" class="text-pk-blue">https://parkeringskompagniet.dk/.</a>',
    },
    Terms_main_point_4_new: {
        main_title: 'Indsamling og behandling af personoplysninger',
        sub_point_1: {
            sub_point_title: 'Indsamling og behandling af personoplysninger',
            sub_point_1_1:
                'Ved Brugerens anvendelse af Parkeringskompagniets tjenester, samtykker Brugeren samtidigt i, at Parkeringskompagniet indsamler og behandler de af Brugeren opgivne personoplysninger og persondata i overensstemmelse med Parkeringskompagniets gældende datapolitik (<a href="https://parkeringskompagniet.dk/datapolitik/" class="text-pk-blue">https://parkeringskompagniet.dk/datapolitik/</a>).',
            sub_point_1_2:
                'Personlige data opbevares kun, hvis de er frivilligt oplyst, og kun så længe, som de er relevante for Parkeringskompagniet. De pågældende data opbevares på Parkeringskompagniets interne netværk og kan kun tilgås med specielle rettigheder.',
        },
        sub_point_2: {
            sub_point_title: 'Brugerens rettigheder',
            sub_point_2_1:
                'Brugeren har ret til at få indsigt i de personoplysninger, som opbevares om denne hos Parkeringskompagniet, og til at få rettet de pågældende oplysninger.',
            sub_point_2_2:
                'Brugeren har ret til at få sine oplysninger slettet fra Parkeringskompagniets systemer.',
            sub_point_2_3:
                'Brugeren har desuden de rettigheder, som Den Europæiske Unions generelle databeskyttelsesforordning giver enkeltpersoner.',
        },
        sub_point_3: {
            sub_point_title: 'Brugerens pligter',
            sub_point_3_1:
                'Ved brugerens opgivelse af oplysninger om en anden, indestår Brugeren for, at denne har retten til at give oplysningerne til Parkeringskompagniet.',
        },
        sub_point_4: {
            sub_point_title: 'Flytning af Data',
            sub_point_4_1:
                'Brugeren accepterer, at såfremt Parkeringskompagniet finder det nødvendigt at flytte brugerens oplysninger til en anden platform, oprettet med samme formål som indeholdt i nærværende aftale, sker dette uden forudgående meddelelse og i overensstemmelse med gældende databeskyttelsesretlige regler.',
        },
        sub_point_5: {
            sub_point_title: 'Videregivelse af oplysninger',
            sub_point_5_1:
                'Brugerens oplysninger videregives kun, hvis det er nødvendigt for den service, som leveres af Parkeringskompagniet.',
        },
    },
    Terms_main_point_5_new: {
        main_title: 'Særlige betingelser og vilkår for brug af Oparko',
        sub_point_1: {
            sub_point_1_title: 'Betingelser for anvendelse',
            sub_point_1_1: {
                sub_point_1_1_title:
                    'Parkeringstjenesten kan alene anvendes på parkeringspladser og i parkeringsområder/-zoner (hver for sig en ”Parkeringsplads”), som:',
                sub_point_1_1_1:
                    'der er opsat Parkeringskompagniets skiltning på,',
                sub_point_1_1_2:
                    'og har automater med Oparko mærkater, eller på anden vis bærer Oparko mærker, der angiver, at Oparko kan anvendes.',
            },
            sub_point_1_2:
                'Når Parkeringstjenesten anvendes, skal Brugeren påbegynde en parkering ved at aktivere enten; Appen; eller et tilknyttet og godkendt betalingssystem',
            sub_point_1_3:
                'For at sikre, at parkering er påbegyndt på korrekt vis, skal Brugeren undersøge, at en bekræftelse på korrekt og gyldig påbegyndt parkering er modtaget via',
            sub_point_1_3_1: 'Appen, såfremt Appen anvendes; eller',
            sub_point_1_3_2: 'Email-bekræftelse',
            sub_point_1_4:
                'En parkering er kun gyldig, hvis App har bekræftet parkeringen, og viser en igangværende korrekt registreret parkering/betaling.',
            sub_point_1_5:
                'Ved anvendelsen af Parkeringstjenesten, skal Brugeren i forbindelse hermed korrekt angive det parkerede køretøjs registreringsnummer samt områdekode på den pågældende Parkeringsplads.',
            sub_point_1_6:
                'Det er Brugerens ansvar, at køretøjets registreringsnummer og områdekoden er indtastet korrekt. Fejl i registrering og indtastning vil medføre en kontrolafgift.',
            sub_point_1_7:
                'Brugeren skal sikre sig, at områdekoden svarer til områdekoden på den pågældende Parkeringsplads eller E-charge stander, som fremgår af Oparko skiltningen på parkeringspladsen eller i nærheden af Parkeringspladsens automater/E- charge standere. Brugeren er ansvarlig for angivelse af korrekt områdekode og registreringsnummer. Oparko påtager sig intet ansvar i forbindelse med forkert angivne registreringsnumre og/eller områdekoder.',
            sub_point_1_8:
                'Ved parkering på parkeringspladser hvor køretøjets nummerplade aflæses, skal områdekode ikke indtastes. Registreringsnummeret der parkeres med, skal være korrekt indtastet.',
            sub_point_1_9:
                'Det er en gyldighedsbetingelse for parkeringens registrering, at registreringsnummeret og områdekoden er indtastet korrekt.',
            sub_point_1_10: {
                sub_point_1_10_title:
                    'Registrering af parkering i Oparko kan ske på en af følgende måder:',
                sub_point_1_10_1:
                    'Ved manuel registrering skal Brugeren angive den ønskede parkeringstid, og bliver i denne forbindelse oplyst om prisen for den ønskede parkering. Ved Brugerens bekræftelse af den indtastede registrering, reserveres det oplyste beløb. Ved Brugerens stop af parkeringen, modificeres det reserverede beløb, således at der kun betales for den registrerede parkeringstid.',
                sub_point_1_10_2: {
                    sub_point_1_10_2_title:
                        'Ved automatisk registrering (ved parkering på ANPR-pladser) skal Brugeren forud for parkeringen have aktiveret køretøjet under ”køretøjer”. Ved parkering på ANPR-parkeringsplads, da igangsættes parkeringen ved indkørslen og afsluttes samt afregnes ved udkørslen. Det betales for den benyttede parkeringstid.',
                    sub_point_1_10_2_1:
                        'Ved Brugerens aktivering af køretøj accepterer Brugeren at der sker automatisk betaling via den foretrukne betalingsmetode når parkering sker på ANPR-parkeringsplads.',
                },
            },
            sub_point_1_11:
                'Oparko kan ikke gøres ansvarlig for præcisionen af Brugerens telefons GPS-position, ej heller kan Oparko gøres ansvarlig for mobildækning/signal i/på parkeringsområdet. Brugeren er ansvarlig for, at den angivne position modsvarer brugerens parkerede køretøjs position.',
            sub_point_1_12:
                'Oplysninger om parkeringer foretaget af Brugeren, vil være tilgængelige for Brugeren via ”Parkeringer”, og sådanne oplysninger vil som udgangspunkt være tilgængelige i mindst 12 måneder.',
        },
        sub_point_2: {
            sub_point_1_title: 'Generelt',
            sub_point_1_1: {
                sub_point_1_1_title:
                    'Parkeringstjenesten gør det muligt for Brugeren at påbegynde en parkering, og ved manuel påbegyndelse angive foreløbig parkeringstid, stoppe og afslutte den påbegyndte parkering. Yderligere er det muligt for Brugeren at forlænge den foreløbig angivet parkeringstid.',
                sub_point_1_2_1:
                    'Påmindelse: Det er muligt at få en påmindelse før den manuelle igangsatte parkering udløber, forudsat at der i Appen er givet tilladelser til push-meddelelser. Det er brugerens ansvar at sikre, at korrekt afslutning af parkering sker, uanset om påmindelsen udebliver.',
                sub_point_1_2_2: {
                    sub_point_1_2_2_title:
                        'Stop parkering: Forlades parkeringspladsen før den valgte parkerings tid udløber, er det muligt aktivt at stoppe den igangværende parkering, hvorved der kun betales for den periode, hvor parkeringen har været aktiv. Brugeren henvises til altid at forholde sig til parkeringsprisen, som fremgår af skiltningen og/eller betalingsautomaten. Afhængigt af prisstrukturen på parkeringspladsen, da vil det på de fleste parkeringspladser være muligt at stoppe parkeringen, så der ikke betales for ”ubrugt tid”. NB! Nogle parkeringspladser kræver betaling pr. påbegyndt tidsperiode og evt. forud, hvilket betyder, at i tilfælde af, at en sådan parkering stoppes, da opkræves det forud godkendte beløb uanset stopningen. Stoppes parkeringen ikke, vil der ikke kunne ske refusion af betaling for ”overskydende tid”.',
                    sub_point_1_2_2_1:
                        'Ved overskydende tid skal forstås, den periode, hvor der er en gyldig registrering af parkering på parkeringspladsen, men hvor køretøjet ikke har befundet sig på parkeringspladsen.',
                },
                sub_point_1_2_3:
                    'Forlængelse: Forlængelse af en parkering foretages ved at trykke på ”forlæng” og derpå anføre det nye sluttidspunkt for parkeringen, derefter godkendes beløbet. Herefter vil nedtællingen af parkeringsperioden for køretøjet blive opdateret ud fra det forlængede parkeringstidspunkt. Forlængelse medfører, at der igangsættes en ny parkering ved udløb af den oprindelige parkering.',
                sub_point_1_2_4:
                    'Hvis det ikke er muligt at udføre et køb på et givet tidspunkt ved hjælp af Oparko softwaren, er Brugeren ansvarlig for at betale for parkeringen på anden vis f.eks. ved at købe en gyldig P-billet i betalingsautomat og i øvrigt iagttage de skiltede regler og vilkår for parkering. Årsager til at Oparko ikke kan benyttes omfatter, men er ikke begrænset til f.eks.: dårlig/ingen internetadgang, server-nedetid pga. vedligeholdelse, opdateringer m.m.',
            },
            sub_point_1_2:
                'Var Brugeren ikke fører af køretøjet på tidspunktet for parkeringen, men har brugeren angivet køretøjet som aktivt, da har brugeren accepteret at betaling automatisk sker via den foretrukne betalingsmetode. Det interne forhold mellem Brugeren og eventuel tredjemand er Oparko uvedkommende.',
            sub_point_1_3: {
                sub_point_1_3_title:
                    'Pålægges det parkerede køretøj et kontrolgebyr, for en parkering uoverensstemmende med de regler og vilkår som fremgår af skiltningen på parkeringspladsen, hæfter føreren for betaling af et sådant kontrolgebyr, uanset hvem der har foretaget betalingen for parkeringen.',
                sub_point_1_3_1:
                    '”Kontrolgebyr” anvendes i denne sammenhæng som samlebetegnelse for hhv. kontrolgebyr, kontrolafgift, parkeringsbøde etc.',
            },
            sub_point_1_4:
                'I det tilfælde, at parkeringen er foretaget med et køretøj, hvor registreret ejer/bruger er et selskab, og køretøjet er pålagt et kontrolgebyr, er det Brugerens ansvar på tilstrækkeligvis, at dokumentere, at den foretagne parkering ikke er sket i erhvervsøjemed. Kan Brugeren ikke løfte denne bevisbyrde anses den registrerede ejer/bruger, for at være rette debitor og der vil i dette tilfælde, ved forsinket betaling, udover eventuelle rykkere blive opkrævet et kompensationsbeløb på kr. 310,00 i overensstemmelse med bek. nr. 105 af 31/01/2013 § 2, stk. 1.',
            sub_point_1_5:
                'Var Brugeren ikke fører af køretøjet på tidspunktet for parkeringen, accepterer Brugeren herved at skulle oplyse hvem føreren var. Ved mangelfulde eller urigtige oplysninger vil kontrolgebyret, samt evt. rykkergebyrer, i deres helhed blive fastholdt overfor Brugeren.',
            sub_point_1_6:
                'Brugerens betaling af den pågældende parkeringsafgift (inklusiv moms) er fastsat på baggrund af de gældende takster (”Parkeringsafgiften”) i forbindelse med, at Brugeren afslutter en parkering, eller alternativt starter en fast parkeringstid gennem Parkeringstjenesten.',
            sub_point_1_7:
                'Brugeren kan ikke fremsætte krav om reduktion af kontrolgebyret, på baggrund af gennemført betaling i Oparko. Dette vilkår er gældende uanset, hvorvidt kontrolgebyret er pålagt på baggrund af Brugerens fejlregistrering i Oparko ellers Brugerens parkering i strid med de øvrige skiltede regler og vilkår på parkeringspladsen.',
            sub_point_1_8:
                'Brugeren har desuden ikke krav på tilbagebetaling af betaling foretaget via Oparko, medmindre opkrævningen skyldes en fejl hos Oparko, som Brugeren ikke har haft eller kunne have indflydelse på.',
        },
        sub_point_3: {
            sub_point_1_title: 'Oparko-charge vilkår',
            sub_point_1_1:
                'Betaling for opladning af køretøj kan ske gennem apps i relation til de på pladsen opstillede ladestandere. Betaling for parkering skal ske særskilt gennem Oparko (se punkt 5).',
            sub_point_1_2:
                'BEMÆRK: Ved parkering i parkeringsbåse reserveret til el-køretøjer under opladning, skal opladningen være registreret i den benyttede app, inden parkering og opladning påbegyndes. Desuden skal parkering være registreret i Oparko-appen, som angivet i punkt 5.',
            sub_point_1_3:
                'Den benyttede app skal anvendes til betaling for opladning via opstillede ladestandere.',
            sub_point_1_4:
                'Ved brug af Oparko i forbindelse med benyttelse af opstillet ladestander, giver Brugeren meddelelse til Parkeringskompagniet om, at opladning af parkeret køretøj påbegyndes, den foreløbige ladetid, afslutning af opladning og hvis nødvendigt, om forlænget ladetid.',
            sub_point_1_6: {
                sub_point_1_6_title:
                    'Ved betaling og brug af opstillet ladestander betales for en ladeafgift.',
                sub_point_1_6_1: 'Ladeafgiften er variabel.',
                sub_point_1_6_2:
                    'Betaling for ladeafgift sker særskilt og kravet fremgår i den benyttede appen  forinden registrering og betaling gennemføres.',
            },
            sub_point_1_7:
                'Ved registrering er de øvrige betingelser og vilkår for brug af Oparko appen ligeledes gældende, herunder at korrekt og gyldig registrering samt betaling forudsætter korrekt indtastning af områdekode samt det parkerede køretøjs registreringsnummer. Det er brugerens ansvar, at de indtastede oplysninger er korrekte og kontrollerbare samt at betaling kan gennemføres.',
            sub_point_1_8: {
                sub_point_1_8_title:
                    'Områdekoden som skal benyttes i forbindelse med betaling for opladning samt parkering fremgår af skiltningen opsat ud for båsene, som er reserveret til el-køretøjer. Det er brugerens ansvar, at korrekt områdekoden indtastes.',
                sub_point_1_8_1:
                    'Ved betaling til områdekode tilknyttet Oparko-charge ladestander, men uden at en parkeringsbås reserveret til Oparko-charge benyttes, da vil der ikke ske refusion af indbetaling.',
            },
            sub_point_1_9: {
                sub_point_1_9_title:
                    'Det er til hver en tid brugerens ansvar, at de skiltede regler og vilkår som fremgår af parkeringspladsen iagttages. Ved uoverensstemmelser mellem skiltningen og disse betingelser og vilkår, da har skiltningens ordlyd forrang. Såfremt parkering ikke er foretaget i overensstemmelse med de skiltede regler og vilkår vil et kontrolgebyr kunne pålægges, som ikke efterfølgende vil kunne blive annulleret.',
                sub_point_1_9_1:
                    'Parkering i båse reserveret til El-køretøjer kan kun ske mod betaling og køretøjet skal være under opladning i hele perioden, som parkeringsbåsen benyttes. Er køretøjet ikke under opladning og/eller er parkeringen ikke registreret i Oparko, da vil et kontrolgebyr som ikke kan annulleres, blive pålagt.',
                sub_point_1_9_2:
                    'Opstår der problemer i forbindelse med brug af Oparko-charge ladestander skal brugeren kontakte Parkeringskompagniet på det på ladestanderen oplyste telefonnummer.',
            },
            sub_point_1_10:
                'Oplysninger som Brugeren har registreret i forbindelse med registrering og betaling sker på samme måde som nævnt i pkt. 4.',
        },
    },

    Terms_main_point_6: {
        main_title: 'Adgang til Tjenesterne, registrering af oplysninger, osv.',
        main_text:
            'Adgang til Tjenesterne, oprettelse samt betaling for parkering via Oparko kan ske ved, at Brugeren oprettes som bruger. Brug af Oparko kræver, at der er tilknyttet et gyldigt betalingsmiddel i Brugerens Oparko App. De betalingsmidler som Brugeren gyldigt kan tilknytte som betalingsmiddel er; Bankkort Det er en gyldighedsbetingelse, at der er dækning på det tilknyttede og valgte betalingsmiddel.',
        sub_point_1: {
            sub_point_1_title:
                'Ved oprettelsen og senere brug af Oparko er det en forudsætning, at Brugeren oplyser korrekt og gyldigt:',
            sub_point_1_1: {
                sub_point_1_1_1: 'Navn',
                sub_point_1_1_2: 'Mailadresse',
                sub_point_1_1_3: 'Mobilnummer',
                sub_point_1_1_4: 'Adgangskode',
                sub_point_1_1_5: 'Registreringsnummer, på mindst ét køretøj',
                sub_point_1_1_6: {
                    sub_point_1_1_6_title: 'Betalingsmiddel',
                    sub_point_1_1_6_1: {
                        sub_point_1_1_6_1_title:
                            'Grupper af medarbejdere med adgang til de registrerede oplysninger:',
                        sub_point_1_1_6_1_1:
                            'Underleverandører (under udøvelse af support)',
                        sub_point_1_1_6_1_2:
                            'Parkeringskompagniets Medarbejdere (under udøvelse af support i forbindelse med Brugers henvendelser)',
                    },
                },
            },
        },
        sub_point_2:
            'En parkering igangsættes via App eller <a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk.</a>',
        sub_point_3: {
            sub_point_3_title:
                'For at anvende Oparko skal der forud for parkeringens registrering være tilknyttet og valgt et gyldigt betalingsmiddel i Appen eller på <a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk.</a>',
            sub_point_1_1: {
                sub_point_1_1_1:
                    'BEMÆRK: Det er ikke muligt at ændre betalingsmiddel i forbindelse med en igangværende parkering, hvorfor det er Brugerens ansvar, at det korrekte og gyldige betalingsmiddel vælges forud for parkeringen.',
            },
        },
        sub_point_4: {
            sub_point_4_title:
                'Instrukserne som fremgår af skærmen skal følges:',
            sub_point_1_1: {
                sub_point_1_1_1:
                    'For at Brugeren kan benytte Oparko til betaling for parkeringer, er det en gyldighedsbetingelse, at Brugeren registrerer korrekt og gyldigt registreringsnummer (nummerplade), områdekode, betalingsmiddel og parkeringstid.',
                sub_point_1_1_2:
                    'Det skal eftertjekkes, at det/den registrerede registreringsnummer og områdekode er korrekt angivet, af Brugeren.',
                sub_point_1_1_3:
                    'Oparko forbeholder sig ret til at afvise en ansøgning om oprettelse og betaling for parkering.',
                sub_point_1_1_4:
                    'Det præciseres, at der kun er sket gyldig betaling for parkeringen, hvis der er foretaget korrekt angivelse af registreringsnummeret på det køretøj, der parkeres samt, at den indtastede områdekode er overstemmende med områdekoden som fremgår af Oparko skiltningen gældende for den pågældende parkeringsbås, hvor køretøjet befinder sig.',
            },
        },
        sub_point_5:
            'Ved manglende gyldig registrering af parkeringen risikerer køretøjet at blive pålagt et kontrolgebyr.',
        sub_point_6:
            'Ved gennemført køb/reservation, sendes en bekræftelse via e-mail til den af Brugeren oplyste e-mailadresse.',
        sub_point_7:
            'Ved køb gennem Oparko stilles der en uspecificeret parkeringsbås til rådighed på den/det valgte parkeringsplads/parkeringssted og i den valgte periode. Parkeringskompagniet kan ikke gøres ansvarlig, hvis der ikke er ledige parkeringsbåse (da der ikke er tale om booking). Parkering bør derfor ikke købes før køretøjet er behørigt parkeret på parkeringspladsen.',
        sub_point_8:
            'Bruger har ikke krav på tilbagebetaling af ubenyttet parkering. Se punkt 5.2.1.2.',
    },

    Terms_main_point_7: {
        main_title: 'Brugerens forpligtelser og ansvar',
        sub_point_1: {
            sub_point_1_1: `Brugeren er til hver en tid ansvarlig for at afgive korrekte oplysninger om Brugeren, og at relevante køretøjer er korrekt registrerede hos Oparko. Brugeren skal umiddelbart efter oprettelsen/registreringen, logge på ”Min Konto” for at sikre, at de registrerede oplysninger er korrekte. Oparko kan aldrig være eller blive ansvarlig for fejlregistreringer/-indtastninger, uanset registreringsmetode.`,
            sub_point_1_2: `Brugeren er ansvarlig for løbende at sikre, at betalingsmidlet som er registreret, og som anvendes via Oparko, ikke er blokeret, er gyldig og at der er tilstrækkelig dækning på det registrerede betalingsmiddel. Ved udløb af et registreret betalingsmiddel skal Brugeren give Oparko oplysninger (ved en opdatering i Appen under ”betalingsmetoder”) om et nyt betalingsmiddel. Oplysningen om nyt betalingsmiddel skal gives senest ved udgangen af den kalendermåned, der går forud for den kalendermåned, hvor det registrerede betalingsmiddel ikke længere er anvendeligt.`,
            sub_point_1_3: `Sikker og forsvarlig opbevaring af login-oplysningerne, er Brugerens ansvar.`,
            sub_point_1_4: `Yderligere er det Brugerens ansvar at login-oplysningerne ikke videregives eller på anden måde gøres tilgængelig for uautoriserede personer.`,
            sub_point_1_5: {
                sub_point_1_5_title: `Det er udelukkende Brugerens ansvar, at Brugerens Oparko konto kun anvendes og bliver anvendt af rette personer.`,
                sub_point_1_5_1: {
                    sub_point_1_5_1_title: `Brugeren skal straks informere Oparko (f.eks. ved henvendelse til Oparko Kundeservice eller ved en opdatering af oplysningerne i ”Min Konto”), såfremt:`,
                    sub_point_1_5_1_1: `Brugeren har grund til at tro, at en uautoriseret person har fået adgang eller kendskab til Login-oplysningerne;`,
                    sub_point_1_5_1_2: `registrerede oplysninger om Brugeren er blevet ændret og/eller bør opdateres; eller`,
                    sub_point_1_5_1_3: `Brugeren ikke længere råder over et registreret køretøj eller ikke længere skal betale for parkeringer foretaget med et registreret køretøj,`,
                },
                sub_point_1_5_2: `Informationen skal i første omgang ske ved, at Brugeren selv opdaterer oplysningerne i Oparko. Det er Brugerens ansvar, at der ikke er registreret køretøjer, som brugeren ikke skal betale for. Oparko kan herved tage de nødvendige forholdsregler, eksempelvis at ændre/blokere Login-oplysningerne og/eller Brugerens konto eller bede Brugeren opdatere Brugerens registrerede oplysninger.Brugeren er ligeledes forpligtet til at orientere Oparko om øvrige forhold af betydning for Aftalen og/eller leveringen af Tjenesterne.`,
                sub_point_1_5_3: `Brugeren er ansvarlig for at sikre, at Appen til enhver tid er opdateret. Mobiltelefon eller andet teknisk udstyrs korrekte funktionalitet, er til hver en tid Brugerens ansvar. Det er Brugerens ansvar at det benyttede medie er kompatibelt med Tjenesterne og Appen. De til enhver tid gældende funktionskrav til Tjenesterne og Appen kan indhentes ved henvendelse til Kundeservice.`,
            },
            sub_point_1_6: `Brugeren er til enhver tid forpligtet til at overholde gældende love og regler samt de regler og vilkår, som fremgår af skiltningen på den pågældende parkeringsplads, hvor Brugeren parkerer sit køretøj.`,
            sub_point_1_7: `Brugeren er ansvarlig for, at parkering påbegyndes korrekt ved angivelse af det parkerede køretøjs registreringsnummer og områdekode gældende for den benyttede parkeringsplads. Brugeren er ansvarlig for at sikre, at områdekoden angivet af Brugeren er korrekt, også selvom områdekoden foreslås i Appens lokalitetsfunktion. BEMÆRK: Såfremt der ikke indtastes korrekt registreringsnummer og/eller områdekode, er der ikke foretaget gyldig betaling for parkeringen, hvorfor det parkerede køretøj risikerer at blive pålagt et kontrolgebyr`,
            sub_point_1_8: `Brugeren er ansvarlig for at sikre, at en påbegyndt parkering afsluttes. Såfremt Brugeren ikke angiver en foreløbig sluttid eller en total parkeringstid, når parkering påbegyndes, er Brugeren ansvarlig for at afslutte parkeringen manuelt. Afslutning af parkeringen kan ske via Appen, eller ved henvendelse til Oparko vha. de kontaktoplysninger som fremgår af Appen, hjemmesiden og Oparko skiltene.`,
            sub_point_1_9: {
                sub_point_1_9_title: `Såfremt Parkeringstjenesten ikke er tilgængelig eller er ude af drift, når Brugeren skal parkere, eller mens Brugeren parkerer (f.eks. af grunde der kan henføres til telefonen, mobiltelefonen eller andet teknisk udstyr anvendt af Brugeren, eller på grund af fejl, afbrydelse eller forsinkelse af telefon, internet eller andre kommunikationsnetværk), er Brugeren ansvarlig for, at der foretages betaling på anden vis og som angivet af den opslåede skiltning på parkeringspladsen (eksempelvis ved betaling i den pågældende automat).`,
                sub_point_1_9_1: `Brugeren er ansvarlig for betaling af eventuelle kontrolgebyrer eller afgifter for ukorrekt parkering (som meddeles eller indkræves i forbindelse med parkeringsrestriktionsovertrædelser).`,
                sub_point_1_9_2: `Såfremt det parkerede køretøj er registreret til et selskab, anses parkeringen for foretaget i arbejdsmæssigsammenhæng, hvorfor Selskabet i denne situation vil blive anset for rette debitor.`,
            },
            sub_point_1_10: `Brugeren har mulighed for at se parkeringshistorikken for de parkeringer Brugeren har betalt for via Oparko.`,
            sub_point_1_11: `Al parkering købt/reserveret via Oparko sker på eget ansvar, medmindre andet specifikt fremgår af disse vilkår og betingelser.`,
            sub_point_1_12: `Parkeringskompagniet påtager sig intet ansvar i forbindelse med ulykker, tyveri, skader på personer, køretøjer, udstyr og effekter, i forbindelse med at køretøjet holder parkeret på en parkeringsplads. Parkeringskompagniet kan ikke gøres ansvarlig for nogen form for indirekte tab eller følgeskader.`,
        },
    },
    Terms_main_point_8: {
        main_title: 'Klageadgang',
        sub_point_1: `I tilfælde af mangelfuld Tjeneste eller forkert opkrævning, skal Brugeren straks og senest 30 dage efter ydelsen af den pågældende Tjeneste blev leveret eller efter, at Brugeren konstaterede eller burde have konstateret den pågældende forkerte opkrævning, indsende en skriftlig klage til Parkeringskompagniet kundeservice. Klagen skal klart angive manglen eller fejlen. Brugeren skal yde rimelig assistance til Parkeringskompagniet i forbindelse med eventuelle undersøgelser i forbindelse med klagen.`,
        sub_point_2: `Klager over pålagte kontrolgebyrer skal ske til udstederen af det/de pålagte kontrolgebyr(er).`,
    },
    Terms_main_point_9: {
        main_title: 'Vilkår og Priser',
        sub_point_1: `Brugeren hæfter til hver en tid for alle betalinger foretaget via Oparko`,
        sub_point_2: `Betalinger foretages ved, at Brugeren registrer et gyldigt betalingsmiddel i Appen. Betaling vil blive opkrævet via det af Brugeren registrerede betalingsmiddel.
        Når et køretøj er markeret som aktivt, da har Brugeren accepteret, at betaling automatisk sker via det angivne foretrukne betalingsmiddel.
        Administrator vil blive faktureret ved betalinger hvor Erhvervsparkering er registreret som betalingsmiddel. Brugeren vælger og godkender betalingsmetoden i forbindelse med ansøgning om registrering.
        Det er Brugerens ansvar, at korrekt og gyldigt betalingsmiddel angives inden parkeringen igangsættes.
        Fejlagtig anvendelse af Erhvervsparkering er alene et anliggende mellem Administrator og Brugeren.`,
        sub_point_3: `Ved forsinket betaling vil der blive opkrævet eventuelle rykkere i overensstemmelse med gældende lovgivning.`,
        sub_point_4: `Alle priser angives i danske kroner og inklusive moms. Prisen som skal betales for den foretagne parkering, afhænger af den valgte parkeringsplads, den valgte parkeringsperiode, samt om en igangværende parkering forlænges.`,
        sub_point_5: `Ved oprettelse af en parkering samt ved forlængelse oplyses det, hvor meget den pågældende parkering eller forlængelse koster, og dette beløb skal godkendes, før parkeringen bliver oprettet og/eller forlænget.`,
        sub_point_6: `I nogle tilfælde vil der ud over timeprisen for parkering blive opkrævet et driftsgebyr. Dette vil altid fremgå af App’en eller Hjemmesiden ved køb af tilladelse.`,
        sub_point_7: `Parkeringskompagniet forbeholder sig retten til at ændre afgifter og priser.`,
        sub_point_8: `BEMÆRK: Oparko forbeholder sig retten til at opdatere og ændre Appens såvel som hjemmesidens indhold uden forudgående varsel.`,
    },
    Terms_main_point_10: {
        main_title: 'Fortrydelsesret',
        sub_point_1: `I henhold til den danske forbrugeraftalelov (lov nr. 1457 af 17. december 2013), har Brugeren ret til at fortryde indgåelsen af Aftalen ved meddelelse herom til Oparko kundeservice inden for 14 dage efter godkendelse af registreringen.`,
        sub_point_2: `Brugeren er indforstået med at købelovens (lov nr. 1853 af 24/09/2021) normale fortrydelsesret på 14 dage kun gælder parkering, som endnu ikke er trådt i kraft (gyldige) på fortrydelsestidspunktet. Bestiller Brugeren således parkering med gyldighedsperiode fra samme dag (straks) ophører fortrydelsesretten ligeledes samme dag (straks) og de ellers gældende 14 dages fortrydelsesret fraskrives dermed af Brugeren. Ved Brugerens køb af parkering med gyldighedsperiodes begyndelse senere end 14 dage fra indgåelse af abonnementsaftale har Brugeren 14 dages fortrydelsesret.`,
        sub_point_3: {
            sub_point_3_title: `For at kunne benytte fortrydelsesretten, skal fortrydelsesmeddelelsen være indleveret til Oparko senest på 14. dagen for den dag, hvor indbetalingen er registreret hos Oparko.`,
            sub_point_3_1: `Udløber fristen på en helligdag, lørdag, grundlovsdag, juleaftensdag eller nytårsaftensdag forlænges fristen til den følgende hverdag.`,
            sub_point_3_2: `For at udøve fortrydelsesretten skal Brugeren sende Parkeringskompagniet en e-mail, hvor Brugeren skriver, at køb/reservation fortrydes (se punkt 12.2.8.).`,
        },
        sub_point_4: `Vær opmærksom på, at indkørsel på parkeringspladsen/godkendelse af købet i app eller <a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk.</a>, anses for accept af, at parkering påbegyndes. Fortryder du herefter dit køb i app eller <a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk.</a>, reduceres din tilbagebetaling med en forholdsmæssig del af den samlede pris for parkeringen i den købte periode, baseret på det antal dage/timer/minutter, køretøjet har holdt parkeret, indtil Oparko modtog registreringen om udøvelse af fortrydelsesretten.`,
    },
    Terms_main_point_11: {
        main_title: 'Vilkår for afmelding',
        sub_point_1: `Brugeren kan vælge at lukke Brugerens konto i Appen, eller ved at lukke Brugerens konto via hjemmesiden, hvorved Brugerens Oparko konto ugyldiggøres, således at Brugeren ikke længere har mulighed for at betale for parkering via Oparko.`,
        sub_point_2: `Ubetalte forhold vil blive opkrævet i overensstemmelse med indgået aftale, selvom Brugeren lukker Oparko kontoen.`,
    },
    Terms_main_point_12: {
        main_title: 'Løbetid, førtidig opsigelse mv.',
        sub_point_1: `Denne Aftale er gyldig fra Oparko godkendelse af Brugeren og indtil den opsiges i overensstemmelse med vilkårene i Aftalen eller indtil andet skriftligt aftales.`,
        sub_point_2: {
            sub_point_2_title: `Oparko er berettiget til straks at suspendere Brugers adgang til Tjenesterne, annullere Brugerens konto og/eller opsige Aftalen med øjeblikkelig virkning, såfremt`,
            sub_point_2_1: `Brugeren væsentligt misligholder sine forpligtelser i henhold til denne Aftale;`,
            sub_point_2_2: `Brugeren ikke opfylder, eller der er rimelig grund til at antage, at Brugeren ikke vil opfylde sine betalingsforpligtelser i forhold til Oparko,`,
            sub_point_2_3: `Brugeren, efter Oparko rimelige vurdering, kan forventes at blive betalings udygtig;`,
            sub_point_2_4: `Brugeren anvender Oparko Tjenester i strid med Aftalen eller som på anden vis er til skade for eller påvirker Oparko eller eventuelle tredjeparter negativt;`,
            sub_point_2_5: `Brugeren gentagne gange har parkeret et køretøj i strid med gældende love, regler og/eller regler angivet af den pågældende Parkeringsudbyder;`,
            sub_point_2_6: `Brugeren har afgivet forkerte, mangelfulde eller vildledende oplysninger, eller`,
            sub_point_2_7: `Oparko, på baggrund af en overordnet vurdering, finder det sandsynligt, at Brugeren kan være involveret i eller indblandet i kriminelle aktiviteter.`,
            sub_point_2_8: `Opsigelse af Aftalen skal ske skriftligt til service@oparko.com eller via formularen på Hjemmesiden: <a href="https://parkeringskompagniet.dk/kontakt/" class="text-pk-blue">https://parkeringskompagniet.dk/kontakt/</a>`,
        },
        sub_point_3: `Opsigelse af Aftalen (uanset grund) påvirker ikke de rettigheder og/eller forpligtelser, som en Part har pådraget sig overfor den anden Part forud for datoen for opsigelsen af Aftalen.`,
    },
    Terms_main_point_13: {
        main_title: 'Oparko erstatningsansvar',
        sub_point_1: `Oparko samlede erstatningsansvar kan under ingen omstændigheder overstige et beløb svarende til Kr. 38.000,00, med mindre Oparko har handlet forsætligt eller har udvist grov uagtsomhed.`,
        sub_point_2: `Parkeringskompagniet er ikke ansvarlig for ulykker, tyveri, personskader, skader på køretøjer, udstyr eller andre effekter monteret på eller i køretøjet.`,
        sub_point_3: `Parkeringskompagniet er ikke ansvarlig for hverken indirekte tab eller følgeskader. Parkeringskompagniet og Oparko er ikke erstatningsansvarlig i relation til Brugerens forhold til evt. tredjemand`,
        sub_point_4: {
            sub_point_4_title: `Parkeringskompagniet er ikke erstatningsansvarlig for skader eller tab, som Administrator og/eller Brugeren har pådraget sig som følge af:`,
            sub_point_4_1: `Parkering på en parkeringsplads;`,
            sub_point_4_2: `Brugerens fejl eller uagtsomhed, herunder hvis Brugeren ikke på korrekt vis har påbegyndt eller afsluttet en parkering (uanset om der er valgt at modtage en påmindelse eller en push-meddelelse fra Oparko), eller hvis Brugeren har angivet en forkert områdekode i forhold til en Parkeringsplads og/eller registreringsnummer, ved påbegyndelsen af en parkering (uanset om Brugeren har angivet en sådan områdekode manuelt, eller denne er foreslået via lokalitetsfunktionen i Appen);`,
            sub_point_4_3: `at Brugeren ikke har overholdt sine forpligtelser i henhold til denne Aftale og vilkår for brug af Oparko eller at Brugeren på anden vis ikke har fulgt Oparko instrukser;`,
            sub_point_4_4: `at Brugeren har parkeret køretøjet i strid med gældende love, regler og/eller regler angivet på den opslåede skiltning på pågældende parkeringsplads, hvor Brugeren parkerer køretøjet;`,
            sub_point_4_5: `fejl eller utilstrækkelig funktionalitet på Brugerens mobiltelefon eller andet teknisk udstyr (såsom at udstyret er slukket eller batteriet er løbet tør for strøm);`,
            sub_point_4_6: `sammenbrud, afbrydelse eller forsinkelser på telefon, internet eller andre kommunikationsnetværk, eller telekommunikationsoperatørens handlinger eller udeladelser, som påvirker Tjenestens funktionalitet eller tilgængelighed;`,
            sub_point_4_7: `fejl eller defekt i en teknisk funktion, som angiver prisen for den pågældende Parkeringsafgift;`,
            sub_point_4_8: `at Tjenesten er blevet afbrudt af Oparko på grund af forhold, der viser sig at være forkerte, men som Oparko havde grund til at tro var korrekte på tidspunktet for afbrydelsen, og som retfærdiggjorde afbrydelsen;`,
            sub_point_4_9: `afbrydelser eller utilstrækkelig adgang til en eller flere Tjenester, som Oparko ikke med rimelighed havde kunnet forudse;`,
            sub_point_4_10: `at en Parkeringsplads foreslået af Oparko ikke er ledig, når Brugeren ankommer dertil;`,
            sub_point_4_11: `uautoriseret brug af Login-oplysninger og/eller Tjenester; eller`,
            sub_point_4_12: `force majeure (se punkt 16).`,
        },
        sub_point_5: `Oparko er under ingen omstændigheder erstatningsansvarlig for indirekte skader såsom eksempelvis driftstab og tab af data. Oparko er ikke erstatningsansvarlig i relation til Administrators og/eller Brugerens forhold til eventuelle tredjeparter.`,
    },
    Terms_main_point_14: {
        main_title: `Brugerens erstatningsansvar`,
        sub_point_1: `Brugeren skal holde Parkeringskompagniet skadesløs for erstatningskrav eller tab som følge af Brugerens fejl eller uagtsomhed, Brugerens manglende opfyldelse af sine forpligtelser i henhold til denne Aftale eller på anden vis manglende overholdelse af Oparko instrukser. Brugeren skal ligeledes holde Oparko skadesløs for krav fra tredjeparter, som er en direkte eller indirekte følge af Brugerens anvendelse af en Tjeneste.`,
    },
    Terms_main_point_15: {
        main_title: `Immaterielle rettigheder`,
        sub_point_1: `Brugeren accepterer, at software aldrig kan testes i alle mulige situationer, og at afvigelser fra den aftalte funktionalitet samt at uforudsete fejl og afbrydelser kan opstå. Oparko er generelt tilgængeligt 24 timer i døgnet, dog med undtagelse af planlagte afbrydelser til opgraderinger, ændringer og vedligeholdelse. Retten til at opdatere Oparko med nye funktioner eller på anden vis foretage ændringer, eksempelvis for at tilpasse systemet til ny teknologi, nye sikkerhedsstandarder eller nye administrative procedurer etc. forbeholdes af Parkeringskompagniet. Parkeringskompagniet påtager sig intet ansvar for eventuelle skader eller tab i forbindelse med manglende adgang til Oparko, at Oparko er ude af drift eller indeholder fejl.`,
        sub_point_2: `Alle ophavsrettigheder (herunder rettigheder til kildekoder, computerprogrammer, algoritmer og objektkoder) og øvrige immaterielle rettigheder (heriblandt patentrettigheder og varemærker) vedrørende Oparko og dets indhold, ejes eller licenseres af Parkeringskompagniet eller dets leverandører og partnere. Administrator eller Brugeren får ikke i medfør af denne Aftale overdraget nogen af disse rettigheder. Det er ikke tilladt at anvende Oparko eller dets indhold til kommercielle formål. Brugeren har ikke ret til at kopiere, distribuere, sælge, offentliggøre, overdrage, udlåne, underlicensere, ændre eller på anden vis råde over softwaren indeholdt i Oparko. Brugeren har ikke ret til at foretage, demontering, reverse engineering, dekompilering eller på anden vis forsøge at få adgang til software kildekoden.`,
        sub_point_3: `At alle oplysninger og al data, som overføres til Oparko, er fri for skadelige elementer, kildekoder eller malware (såsom virus, orm og trojanske heste) skal sikres og garanteres af Brugeren. Oplysninger, som Brugeren uploader til Mine Sider eller gør tilgængelig via Appen, må ikke krænke tredjemands immaterielle rettigheder eller på anden vis strider mod love, regler eller andres rettigheder. Det er Brugerens ansvar tredjemands eller andres immaterielle rettigheder ikke krænkes.`,
        sub_point_4: `Medmindre Oparko har handlet groft uagtsomt eller forsætligt, er Oparko ikke ansvarlig for, at eventuelle data bliver gjort tilgængelige for tredjemand. Oparko anvender sædvanlige sikkerhedsforanstaltninger til beskyttelse af data`,
    },
    Terms_main_point_16: {
        main_title: `Force majeure`,
        sub_point_1: `Oparko er ikke erstatningsansvarlig eller ansvarlig for nedbrud eller forsinkelser i gennemførelsen af betingelserne i denne Aftale som følge af forhold, som er uden for Oparko rimelige kontrol.`,
    },
    Terms_main_point_17: {
        main_title: `Fortrolighed`,
        sub_point_1: `Brugeren accepterer, at Oparko rapporterer eventuelt misbrug af Tjenesterne, ulovlige aktiviteter, ulovlig eller upassende adfærd og/eller mistanke herom til politiet eller andre kompetente myndigheder.`,
    },
    Terms_main_point_18: {
        main_title: `Lovvalg og tvister`,
        sub_point_1: `Denne Aftale er underlagt og skal fortolkes i henhold til dansk ret. Enhver tvist, uenighed eller ethvert krav opstået på grundlag af eller i forbindelse med denne Aftale, eller enhver misligholdelse, opsigelse eller ugyldighed heraf, skal som udgangspunkt løses ved frivillig aftale mellem Parterne. Hvis enighed ikke kan opnås i første omgang, afholdes en drøftelse mellem en repræsentant valgt af Brugeren og Parkeringskompagniet. Såfremt Parterne ikke kan nå til en aftale, kan tvisten, blive behandlet af de danske domstole med byretten i Aarhus som første instans. Dette punkt 19.1 er gældende efter ophøret eller udløbet af denne Aftale.`,
        sub_point_2: `Hvis en bestemmelse i denne aftale tilsidesættes som ugyldig, skal Aftalen i øvrigt opretholdes, og den ugyldige bestemmelse erstattes med en gyldig bestemmelse, som det er muligt.`,
        sub_point_3: `Parkeringskompagniet forbeholder sig ret til at ændre disse almindelige betingelser og vilkår for brug af Parkeringskompagniet. Såfremt dette sker, skal Parkeringskompagniet straks oplyse herom, og gøre en ny version af de almindelige betingelser og vilkår for brug af Oparko tilgængelig. Såfremt Brugeren herefter fortsætter med at anvende tjenesterne, anses dette for en accept af disse ændringer. Uanset dette forbeholder Parkeringskompagniet sig ret til at ændre priser og gebyrer/afgifter som følge af stigning i driftsomkostninger, øvrige omkostninger samt afgifts- og gebyrstigninger mv. De til enhver tid gældende almindelige vilkår og betingelser findes i App eller på www.parkeringskompagniet.dk under punktet ”Kundeservice”.`,
    },
    Subscription_terms_heading: `ABONNEMENTSBETINGELSER`,
    Subscription_terms_1: {
        main_title: `Ved oprettelse og benyttelse af Oparko Abonnementsparkering, accepteres, udover nedenstående, også de almindelige vilkår og betingelser for brug af Oparko. Disse betingelser fremgår af Oparko appen og på <a href="https://www.parkeringskompagniet.dk/oparko" class="text-pk-blue">www.parkeringskompagniet.dk/oparko</a>`,
        sub_point_1: `Ved indgåelse af aftale om abonnementsparkering opnår Brugeren en tidsbegrænset digital p-tilladelse.`,
    },
    Subscription_terms_2: {
        main_title: `Abonnementsparkeringsaftalen gælder for det valgte antal abonnementsparkeringsaftaler, som berettiger til parkering i samme antal parkeringsbåse på den valgte parkeringsplads. For Abonnementsparkering er det en gyldighedsbetingelse, at køretøjet registreres korrekt i Oparko. Korrekt og gyldig registrering kræver, at registreringsnummeret er registreret korrekt, samt at køretøjet er korrekt registreret på abonnementsparkeringsproduktet. Det registrerede køretøj må parkere i parkeringsbåse, som ikke på anden måde er reserveret. Dette gælder døgnet rundt alle ugens dage.`,
        sub_point_1: `Abonnementsparkeringen vil fremgå under aktive parkeringer, lejede P-pladser.`,
        sub_point_2: `Det er en gyldighedsbetingelse for korrekt og gyldig registrering, at det registrerede køretøj er valgt som favoritbil, og fremgår korrekt registreret på abonnementsparkeringsproduktet under lejede P-pladser.`,
    },
    Subscription_terms_3: {
        main_title: `Abonnementsparkeringsaftalen gælder for benyttelse af, en afmærket parkeringsbås efter ”først til mølle-princippet’. Dvs. Brugeren opnår med Abonnementsparkeringsaftalen alene en ”flydende ret” til en afmærket parkeringsbås, som ikke på anden vis er reserveret. I tilfælde af, at der ikke forefindes en ledig afmærket parkeringsbås, kan Parkeringskompagniet ikke gøres ansvarlig herfor, ligesom brugeren ikke har krav på reduktion af vederlaget.`,
        sub_point_1: `Den omstændighed, at Brugeren ikke benytter abonnementsparkering, i den fulde valgte periode, medfører ikke at Brugeren er berettiget til en reduktion af vederlaget.`,
    },
    Subscription_terms_4: {
        main_title: `Abonnementsparkeringsaftalen træder i kraft fra og med den af Brugeren valgte dato, og løber i den anførte periode. Abonnementsparkeringsaftalen kan uden nærmere begrundelse opsiges straks af Parkeringskompagniet. Brugeren kan tidligst opsige til udgangen af en købt periode. Opsigelse sker ved, at Brugeren ikke fornyer eller forlænger Abonnementsparkeringsaftalen. I tilfælde af, at Brugeren har valgt automatisk fornyelse, skal brugeren stoppe automatisk fornyelse for at opsige abonnementsparkeringsaftalen.`,
        sub_point_1: `Brugeren kan til enhver tid kun opsige Abonnementsparkeringsaftalen til udløb af den valgte periode. I så fald vil der ikke blive hævet penge fra Brugerens betalingskort før der oprettes en ny Abonnementsparkeringsaftale.`,
        sub_point_2: `Abonnementsparkeringen er gældende fra den valgte dato og i den valgte periode. En abonnementsparkeringsaftale er således ikke nødvendigvis gældende fra den 1. til den 1. i en måned, medmindre Brugeren har valgt dette.`,
    },
    Subscription_terms_5: {
        main_title: `Brugeren er til enhver tid selv ansvarlig for, at der er en gyldig abonnementsaftale på det køretøj, som parkeres på parkeringspladsen. Det er ligeledes Brugerens eget ansvar, at tilknyttede køretøjer er registreret korrekt og fyldestgørende på profilen. Der kan pålægges kontrolafgift, såfremt der ikke er sket korrekt og fyldestgørende registrering af et parkeret køretøj. Kontrolafgiften kan ikke efterfølgende eftergives.`,
    },
    Subscription_terms_6: {
        main_title: `Abonnementsparkeringsaftalens indhold, priser og eventuelle særlige betingelser for Produktpakkerne vil til enhver tid være angivet på Hjemmesiden og i Appen. Vederlaget betales forud. Første gang ved Abonnementsparkeringsaftalens indgåelse.`,
        sub_point_1: `Brugeren vil i nogle tilfælde få udleveret en unik produktkode, som skal anvendes ved registreringen. I forbindelse med modtagelse af den unikke produktkode orienteres Brugeren om, hvorledes brugeren skal forholde sig i forbindelse med betalingsforpligtelsen samt hvorledes registrering af den unikke produktkode skal ske.`,
        sub_point_2: `I tilfælde af at abonnementsparkeringsaftalen enten opsiges, ophæves, fortrydes eller at den udleverede unikke produktkode annulleres eller spærres, er Parkeringskompagniet berettiget til at benytte de registrerede oplysninger, herunder brugers e-mailadresse mv. i.h.t. pkt. 17., til at afgive meddelelse herom.`,
    },
    Subscription_terms_7: {
        main_title: `Vederlaget pristalsreguleres hvert år den 1. januar uden yderligere varsel, hvor det reguleres med nettoprisindekset for oktober md. i det foregående år, dog med minimum 3% (afrundet til nærmeste hele kroner) i forhold til vederlagsbetalingen året før. Indeksregulering af vederlaget sker på grundlag af det umiddelbart før reguleringen gældende vederlag, uanset om dette vederlag måtte være fremkommet ved regulering på anden måde end ved indeksregulering.`,
        sub_point_1: `Udover ovenstående indeksregulering forbeholder Parkeringskompagniet sig ret til at ændre priser og gebyrer/afgifter som følge af stigning i driftsomkostninger, øvrige omkostninger samt afgifts- og gebyrstigninger mv. Sådanne ændringer træder tidligst i kraft ved udgangen af løbende måned + 1 måned efter, at brugeren er blevet informeret om sådanne ændringer.`,
    },
    Subscription_terms_8: {
        main_title: `Parkeringskompagniet tager forbehold for prisfejl, prisændringer og opdateringsfejl.`,
    },
    Subscription_terms_9: {
        main_title: `Såfremt Brugeren har valgt automatisk fornyelse, er det brugerens eget ansvar at ”stoppe” den automatiske fornyelse, ved at opsige Abonnementsparkeringsaftalen. Det forhold at betaling stoppes, er ikke at anse for en opsigelse. Betaling opkræves indtil opsigelse af Abonnementsparkeringsaftalen.`,
    },
    Subscription_terms_10: {
        main_title: `Al færdsel og parkering foregår på brugers eget ansvar. Parkeringskompagniet er ikke ansvarlig for tyveri eller skader, som måtte blive påført brugerens køretøj, uanset årsagen hertil.`,
    },
    Subscription_terms_11: {
        main_title: `Abonnementsparkeringsaftalen må ikke uden skriftligt samtykke fra Parkeringskompagniet, bruges til andet end parkering af det korrekt registrerede køretøj, med en registreret totalvægt på max. 3.500 kg.`,
    },
    Subscription_terms_12: {
        main_title: `Brugeren er ved denne Abonnementsparkeringsaftale gjort udtrykkeligt opmærksom på, at indgåelse af denne Abonnementsparkeringsaftale ikke berettiger brugeren til at annullere pålagte kontrolgebyrer. Abonnementsparkeringsaftalen er først gyldig fra det tidspunkt, hvor registreringsnummeret er registreret korrekt. Abonnementsparkeringsaftalen er kun gyldig for det køretøj, som er registreret korrekt.`,
        sub_point_1: `Abonnementsparkeringsaftalen berettiger kun til parkering på det område, som fremgår af abonnementsparkeringsaftalen, i de afmærkede parkeringsbåse hvor det ikke fremgår, at der er særlige regler gældende for parkering i disse parkeringsbåse. Der gøres opmærksom på, at Abonnementsparkeringsaftalen ikke er en gyldig parkeringstilladelse til parkering i parkeringsbåse, som er reserveret.`,
    },
    Subscription_terms_13: {
        main_title: `Ved brugerens væsentlige misligholdelse af aftalen kan Parkeringskompagniet ophæve Abonnementsparkeringsaftalen med øjeblikkelig virkning. Som væsentlig misligholdelse betragtes blandt andet, men er ikke begrænset til, manglende betaling af det aftalte vederlag, misligholdelse af denne aftales bestemmelser samt enhver overtrædelse af parkeringsbestemmelserne. Ved væsentlig misligholdelse er Parkeringskompagniet berettiget til at pålægge kontrolgebyr og eventuelt fjerne eller tage brugerens køretøj i forvaring indtil, de skyldige gebyrer og omkostninger er betalt. Brugeren har ikke adgang til modregning, selvom der måtte være/ er uenighed om kravet`,
        sub_point_1: `Parkeringskompagniet kan opsige Abonnementsparkeringsaftalen skriftligt med 1 måneds varsel i tilfælde af, at der skal udføres vedligehold, ombygning, renovation o.lign. forhold, der medfører, at Parkeringskompagniet permanent ikke kan benytte parkeringsbåsen længere.`,
    },
    Subscription_terms_14: {
        main_title: `I henhold til den danske forbrugeraftalelov (Lov nr. 1457 af 17. december 2013), har Brugeren ret til at fortryde indgåelsen af Aftalen ved meddelelse herom til Parkeringskompagniets kundeservice inden for 14 dage efter godkendelse af registreringen. For at kunne benytte fortrydelsesretten, skal fortrydelsesmeddelelsen være indleveret til Parkeringskompagniet senest på 14. dagen for den dag, hvor indbetalingen er registreret hos Oparko.`,
        sub_point_1: `Udløber fristen på en helligdag, lørdag, grundlovsdag, juleaftensdag eller nytårsaftensdag forlænges fristen til den følgende hverdag`,
        sub_point_2: `For at udøve fortrydelsesretten skal Brugeren sende os en e-mail eller et brev, hvor Brugeren skriver, at køb/reservation fortrydes. Vær opmærksom på, at indkørsel på parkeringspladsen/godkendelse af købet i app eller www.parkeringskompagniet.dk, anses for accept af, at parkering påbegyndes og en accept af at 14 dages fortrydelsesret frasiges. Fortryder du herefter dit køb i app eller www.parkeringskompagniet.dk, reduceres din tilbagebetaling med en forholdsmæssig del af den samlede pris for parkeringen i den købte periode, baseret på det antal dage/timer/minutter, køretøjet har holdt parkeret, indtil Parkeringskompagniet modtog registreringen om udøvelse af fortrydelsesretten`,
    },
    Subscription_terms_15: {
        main_title: `Eventuelle tvister mellem Parkeringskompagniet og Brugeren vedrørende forståelsen af denne aftale skal afgøres ved de almindelige domstole med anvendelse af dansk ret. Som værneting er aftalt Retten i Aarhus.`,
    },
    Subscription_terms_16: {
        main_title: `Brugeren er ikke berettiget til at fremleje parkeringsbåsen. Overtrædelse heraf anses som misligholdelse, jf. punkt 13.`,
    },
    Subscription_terms_17: {
        main_title: `Parkeringskompagniet er parkeringsadministrator på vegne af pladsejer/pladsejers ejendomsadministrator. Al henvendelse vedr. nærværende kontrakt skal ske til Parkeringskompagniet. Ophører Parkeringskompagniet som parkeringsadministrator, vil aftalen blive overdraget til ny parkeringsadministrator.`,
    },
    Subscription_terms_18: {
        main_title: `I forhold til opbevaring og behandling af personoplysninger, da henvises til den til enhver tid gældende datapolitik som fremgår af <a href="https://www.parkingskompagniet.dk/datapolitik/" class="text-pk-blue">https://www.parkingskompagniet.dk/datapolitik/</a>. Hvis oplysningerne ønskes slettet, betragtes dette som opsigelse af abonnementsparkeringsaftalen. Det skyldes, at Parkeringskompagniet i disse tilfælde ikke kan opretholde aftalen uden de nævnte oplysninger.`,
    },
    Subscription_terms_footer_heading_1: `BEMÆRK, at Brugeren har pligt til at holde de i Oparko registrerede oplysninger ajourført. Dvs. Brugeren skal give Parkeringskompagniet besked om, hvis der sker ændring i adresse, e-mail mv. eller evt. registreringsnummer på det køretøj, der skal benyttes i Oparko. Såfremt dette ikke efterkommes, er Parkeringskompagniet berettiget til at slette de allerede registrerede oplysninger og ophæve abonnementsparkeringsaftale for at overholde den gældende persondatalovgivning.`,
    Subscription_terms_footer_heading_2: `Parkingkompaniet ApS`,
    Footer_contact_info: {
        contact_title: `Kontaktoplysninger`,
        company_name: `Parkeringskompagniet ApS`,
        address_line: `Trindsøvej 4, 8000 Aarhus`,
        cvr: `CVR: 37127485`,
        email: `E-mail: <a href="mailto:service@oparko.com" class="text-pk-blue">service@oparko.com</a>`,
        tel: `Tlf.nr.: <a href="tel:+ 45 88 74 31 94" class="text-pk-blue">+ 45 88 74 31 94</a>`,
        website: `<a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk</a>`,
        updatedAt: `Revideret den 16.11.2022`,
    },

    Terms_title: 'Vilkår og betingelser',
    Terms_heading: 'DATAPOLITIK OG COOKIES',
    Terms_last:
        'Det er chaufførernes fulde ansvar, at de afgivne oplysninger er korrekte. Samtidig fraskriver Parkeringskompagniet sig ansvaret for eventuelle fejl under oprettelsen',

    Terms_1: {
        para_1: 'Disse retningslinjer gælder for de informationer, vi indsamler på folk, som afgiver dem via hjemmesiden, mail og telefonisk henvendelse. Når du giver Parkeringskompagniet personoplysninger, samtykker du samtidig i, at vi behandler dine persondata i overensstemmelse med vores datapolitik. Personlige oplysninger omfatter enhver form for information om en identificerbar person. Vi forbeholder os retten til løbende at opdatere vores datapolitik i overensstemmelse med gældende lovgivning på området og vil i videst muligt omfang opdatere vores kunder herom.',
        para_2: 'Databehandler er Parkeringskompagniet ApS',
        para_3: 'Ryesgade 7b, 8000 Aarhus',
        para_4: 'CVR-nr: 37127485',
        link_1: 'Telefon: <a href="tel:+ 45 88 77 31 50" class="text-pk-blue">+ 45 88 77 31 50</a>',
        link_2: 'Mail: <a href="mailto:service@oparko.com" class="text-pk-blue">service@oparko.com</a>',
    },

    Terms_2: {
        heading: 'Persondatapolitik',
        para_1: 'Personlige data gemmes kun, hvis de er frivilligt oplyst, og vi gemmer dem kun så længe, de er relevante for os.',
        para_2: 'Hvis du giver os oplysninger om en anden, er det dit ansvar at sikre, at du har retten til at give os disse oplysninger.',
        para_3: 'Vi videregiver kun dine personoplysninger, hvis det er nødvendigt for den service, vi leverer.',
        link_1: 'Du har til hver en tid ret til at få indsigt i de personoplysninger, vi har på dig, og hvis de ikke er korrekte, har du ret til at få rettet de pågældende oplysninger. Herudover har du altid ret til at blive slettet af vores systemer. Desuden har du også altid de rettigheder, som Den Europæiske Unions generelle databeskyttelsesforordning giver enkeltpersoner. Hvis du har nogle henvendelser angående dine personlige oplysninger, bedes du henvende dig på <a href="mailto:service@oparko.com" class="text-pk-blue">service@oparko.com</a>.',
    },

    Terms_3: {
        heading: 'Indsamling og behandling af dine personoplysninger',
        para_1: 'Afhængigt af de tjenester, du bruger, registrerer og gemmer vi dine personoplysninger. Hvis du f.eks. skriver en klage til os eller bliver oprettet som kunde, gemmer vi typisk dit navn, email-adresse og telefonnummer. Vi gemmer oplysningerne for at kunne betjene dig bedst muligt. Du vil til hver en tid blive informeret i forbindelse med indsamling af oplysninger.',
        ul: {
            heading:
                'Indsamling af personlige oplysninger sker oftest i forbindelse med:',
            list: [
                'Betaling af parkeringsafgift',
                'Henvendelse ved klage eller interesse i en aftale',
                'Udstedelse af parkeringsafgift',
                'Parkeringskontrol',
                'Ved leje eller reservation af en parkeringsplads',
                'Ved indtastning i vores app',
                'Ved tjenester, som kræver betaling, gemmer vi nødvendige kreditkortdata og faktureringsoplysninger',
                'Til nyhedsbreve og månedsrapporter, hvis du er eksisterende kunde i Parkeringskompagniet',
            ],
        },
    },

    Terms_4: {
        heading: 'Videregivelse af personoplysninger',
        para_1: 'Vi deler ikke dine personlige oplysninger med andre, medmindre du har givet os tilladelse til det, eller hvis det er påkrævet i henhold til gældende lovgivning om persondatapolitik.',
        row_1: {
            heading: 'Retsgrundlaget for behandling',
            para_1: 'Databeskyttelsesforordningens artikel 6, stk. 1, litra b, når der er indgået en kontrakt mellem dig som parkant og Parkeringskompagniet ApS',
            para_2: 'Databeskyttelsesforordningens artikel 6, stk. 1, litra f, når Parkeringskompagniet ApS har en legitim interesse i at forfølge et krav mod dig som parkant, f.eks. hvis du har holdt i strid med bestemmelserne på en P-plads og er blevet pålagt en parkeringsafgift.',
            para_3: 'Databeskyttelsesforordningens artikel 6 stk.1, litra a, når Parkeringskompagniet ApS har fået dit samtykke til at sende nyhedsbreve ud til dig.',
        },

        row_2: {
            heading: 'Kilde',
            para_1: 'Parkeringskompagniet indsamler personoplysninger direkte hos dig og/eller tredjemand, f.eks. hos vores kunde eller hos vores underleverandører.',
        },

        row_3: {
            heading: 'Hvor lagres dine personlige oplysninger?',
            para_1: 'Dine personlige oplysninger gemmes på vores interne netværk og kan kun tilgås med specielle rettigheder. Vi har ikke adgang til betalingsoplysninger, da disse håndteres krypteret af vores betalingspartnere.',
            para_2: 'Cookies er en tekstfil, som lægges på den maskine, du bruger til at besøge vores hjemmeside, og som gør det muligt at genkende maskinen og samle information om, hvilke sider og funktioner, der besøges. Cookies indeholder oplysninger i anonymiseret form.',
            para_3: 'Du er anonym i vores cookie-data, indtil du selv melder dig til en af vores services eller sender os en henvendelse, hvori du selv angiver personlige informationer.',
            link: 'Vores hjemmeside bruger Google Analytics, som er en hjemmeside, der hjælper os med webanalyseservice - de anvender cookies. Det gør de udelukkende, for at vi kan forbedre oplevelsen for vores brugere. Vi opsamler udelukkende information om din brug af Parkeringskompagniets hjemmeside. Google Analytics indsamler oplysninger om dit operativsystem, browser, IP-adresse, den tidligere besøgte hjemmeside og dato og tidspunkt for, hvornår du har besøgt vores hjemmeside. Vi benytter ikke data fra cookies til at indsamle personlige oplysninger om dig, og ingen af de informationer, vi får fra cookies, sendes videre eller sælges. For flere oplysninger om Google Analytics’ brug af cookies, kan du besøge følgende hjemmeside: <a href="https://policies.google.com/privacy?hl=da" class="text-pk-blue">HTTPS://POLICIES.GOOGLE.COM/PRIVACY?HL=DA</a>',
        },

        row_4: {
            heading: 'Fravalg af cookies',
            link: 'Hvis du på et tidspunkt ønsker at fravælge cookies, kan du gøre det via dine browser-indstillinger. Du kan læse om, hvordan du fravælger cookies via din browser, på <a href="http://www.minecookies.org/" class="text-pk-blue">WWW.MINECOOKIES.ORG</a>',
        },

        row_5: {
            heading: 'Hvad sker der, hvis jeg fravælger cookies?',
            para_1: 'Hvis du fravælger cookies, vil det ikke blive registreret, når du browser. Du skal være opmærksom på, at når du fravælger cookies, så fravælger du også en del af hjemmesidens funktioner, og det kan gøre, at hjemmesiden måske ikke vil fungere korrekt.',
        },

        row_6: {
            heading: 'Hvorfor informerer vi om cookies?',
            para_1: 'Vi informerer om cookies, fordi alle danske websites er forpligtet til at informere om dem jf. bekendtgørelse om krav til information og samtykke ved lagring af eller adgang til oplysninger i slutbrugerens terminaludstyr.',
        },

        row_7: {
            heading: 'Ønsker du at kontakte os?',
            link: 'Har du uforløste spørgsmål eller ønske om at kontakte os yderligere, henviser vi til <a href="https://parkeringskompagniet.dk/kontakt/" class="text-pk-blue">kontakt siden</a>',
        },
    },
    ReceptionUser: {
        countryError: 'Landekode er ikke valgt',
        registrationError: 'Udfyld Registreringsnummer',
        emailError: 'Udfyld E-mail',
        customEndDateError: 'Vælg sluttidspunkt (mindst 30 min fra aktuel tid)',
        phoneNumberError: 'Telefonnummer er ikke valgt',
        acceptError: 'Vilkår er ikke accepteret',
        countryCodeError: 'Landekode er ikke valgt',
    },
    'Select the Date of Parking': 'Vælg parkeringsdatoen',
    'Enter your Full Name': 'Indtast dit fulde navn',

    Phonenumber: 'Telefonnummer',
    'Enter your Email': 'Udfyld din email',
    'Choose your enddate': 'Vælg sluttidspunkt',
    'Custom end date': 'Vælg selv sluttidspunkt',
    'Max time in min (0 is no limit)': 'Max tid i minutter (0 = ubegrænset)',

    'Valid from': 'Gyldig fra',
    'Valid until': 'Gyldig indtil',
    'Create new guest parking': 'Opret ny gæsteparkering',
    'Please enter your registration number':
        '* Udfyld venligst dit registreringsnummer',
    'Please enter your E-mail address': '* Udfyld venligst din E-mail adresse',
    'Please choose a date/time':
        '* Sluttidspunktet skal være mindst 30 minutter efter den aktuelle tid',
    'Please enter your phone number': '* Udfyld venligst dit telefonnummer',
    'Please accept our terms to continue': '* Påkrævet',

    ConfirmPermit: {
        lineOne: 'Der er nu opsat en gæsteparkering kl',
        for: 'til',
        on: 'på',
        at: 'på',
        lineTwo: 'som er gældende til og med',
    },
    'Receive SMS?': 'Modtag SMS?',
    'Guest Parking has been created!': 'Gæsteparkering er blevet oprettet!',
    'A Guest parking permit has now been created':
        'Der er nu oprettet en gæsteparkeringstilladelse',

    // Entities

    Reception: 'Reception',
    HotelReception: 'Hotel Reception',
    EmployeeReception: 'Medarbejder reception',
    TimesReception: 'Times Reception',
    HotelAdmin: 'Hotel Admin',
    ConfirmationReception: 'Konfirmationsmodtagelse',
    HotelReceptionConfirmation: 'Hotel receptionsbekræftelse',
    StayNTouchReception: 'Stay-N-Touch-modtagelse',
    APIPartner: 'API-partner',
    SMSConfirmationReception: 'SMS bekræftelse modtagelse',
    Guest_parking_created:
        'Oprettet gæsteparkering for {{regNo}} der er gyldig til og med {{date}}',
    'Something went wrong. Please try again.':
        'Noget gik galt. Prøv venligst igen.',
    Enter_registration_number: 'Indtast registreringsnummer',
    Confirm: 'Bekræft',
    Clear_all: 'Ryd alt',

    View: 'Udsigt',
    Edit: 'Redigere',
    AppFines: 'AppFines',
    AppPermits: 'AppPermits',
    NOT_DEFINED: 'NOT_DEFINED',
    SELF_TICKETING: 'SELF_TICKETING',
    COMBI: 'COMBI',
    P_WARDEN: 'P_WARDEN',
    CAMERA_CONTROL: 'CAMERA_CONTROL',
    Partner: 'Partner',
    Private: 'Privat',
    Company: 'Selskab',
    'Not Handled': 'Ikke håndteret',
    'Not handled': 'Ikke håndteret',
    Approved: 'Godkendt',
    Rejected: 'Afvist',
    'Other - write a comment in the note':
        'Andet - skriv en kommentar i notatet',
    'Outside Booth': 'Udenfor Booth',
    'No permission to the area': 'Ingen tilladelse til området',
    'Parking disc exceeded': 'Parkeringsskive overskredet',
    'Parking or full stop on prohibited area':
        'Parkering eller punktum på forbudt område',
    'Parking on fire access road': 'Parkering på brandadgangsvej',
    'Handicapped parking without permission':
        'Handicapparkering uden tilladelse',
    Deleted: 'Slettet',
    'Guest Parking': 'Gæsteparkering',
    Resident: 'Beboer',
    'Paid ticket': 'Betalt billet',
    'Employee paid ticket': 'Medarbejder betalt billet',
    'Employee upper basement': 'Medarbejder øverste kælder',
    'Employee lower basement': 'Medarbejder nederste kælder',
    Permanent: 'Permanent',
    License: 'Licens',
    Reminder: 'Påmindelse',
    'Active - foreign': 'Aktiv - Udenlandsk',
    'Debt collection - Paragraph': 'Inkasso - Stk',
    Ended: 'Afsluttet',
    Foreign: 'Udenlandsk',
    Paused: 'Pause',
    'Debt collection - Paragraph - installment agreement with demand':
        'Inkasso - Stykke - afdragsaftale med påkrav',
    'Debt collection - Paragraph - with demand':
        'Inkasso - Paragraf - med påkrav',
    'Debt collection - Liquidation': 'Inkasso - Likvidation',
    'Debt collection - Liquidation - installment agreement with demand':
        'Inkasso - Likvidation - afdragsaftale med påkrav',
    'Complaint Council': 'Klagerådet',
    All: 'Alle',
    'Occupancy in': 'Belægning i procent',

    // Subscription
    Next: 'Næste',
    Previous: 'Forrige',
    Subscription: 'Abonnement',
    Submit: 'Indsend',
    Subscribers: 'Abonnenter',
    'Already subscribed': 'Allerede abonnent',
    'E.g.': 'F.eks.',
    '12th Star Avenue': 'Stjernevej 12',
    'Step 1: Which parking lot do you want to park at?':
        'Step 1: Hvilken parkeringsplads vil du parkere på?',
    'Step 2: Which license plate does your car have?':
        'Step 2: Hvilken nummerplade har din bil?',
    'Step 3: When should your subscription start?':
        'Step 3: Hvornår skal dit abonnement starte?',
    'Step 4: Your monthly price is based on the selected parking lot, and will be:':
        'Step 4: Din månedlige pris er baseret på den valgte parkeringsplads og er:',
    'Step 5: To be allowed to create a subscription for this private parking lot, you must enter a pin code below. Next, click on Create to go to the payment site. Once you are done, you will be redirected back to our page, where you can find your subscription in the table.':
        'Step 5: For at få lov til at oprette et abonnement til denne private parkeringsplads skal du indtaste en pinkode nedenfor. Klik derefter på knappen Opret, og du vil blive ført til en betalingsside. Når betalingen er gennemført vil du blive sendt tilbage her til vores side, hvor du kan finde dit abonnement i tabellen.',
    'Step 5:  Click on Create to go to the payment site. Once you are done, you will be redirected back to our page, where you can find your subscription in the table.':
        'Step 5:  Klik på Opret for at gå til betalingssiden. Når du er færdig, bliver du sendt tilbage til vores side, hvor du kan finde dit abonnement i tabellen.',
    'Below you can below see the available days, times and prices':
        'Du kan nedenfor se ledige dage, tider og priser',
    'Available days, times and prices': 'Ledige dage, tider og priser',
    'Transaction fee': 'Transaktionsgebyr',
    'Subscription Info': 'Abonnement Info',
    'Monthly price': 'Månedlig pris',
    'Last recurring payment date': 'Sidste tilbagevendende betalingsdato',
    'Car Info': 'Bil Info',
    'Enter registration number': 'Indtast nummerplade',
    'Buy monthly subscription': 'Køb månedligt abonnement',
    'Buy short ticket': 'Køb enkelt billet',
    'Short ticket': 'Enkelt billet',
    'The price is': 'Prisen er',
    'kr pr. month, but the price on specific parking spots may vary':
        'kr pr. måned, men kan variere fra specifikke parkeringsbåse',
    'Registration number': 'Registreringsnummer',
    'PIN code': 'PIN kode',
    'Email id is already registered with us':
        'E-mail adressen er allerede registeret hos os',
    'Buy ticket': 'Køb billet',
    'Get ticket': 'Få billet',
    'Permit id': 'Tilladelse id',
    'Last payment': 'Sidste betaling',
    'Price per period': 'Pris pr. periode',
    'Stop subscription': 'Stop abonnement',
    'Price: 500 kr. per month (normal price is 625 kr. per month - The discount is active for the rest of 2023)':
        'Pris: 500 kr. pr. måned (Normalpris er 625 kr. pr. måned - Rabatten gælder resten af 2023)',
    'Payment Options': 'Betalingsmuligheder',

    // FAQs
    'How do I see and create a permit?':
        'Hvordan ser og opretter man tilladelser?',
    'How do I see the fines that has been made at my parking space?':
        'Hvordan ser jeg afgifter som er lavet på min parkeringsplads?',
    'How do I cancel a fine?': 'Hvordan annullerer jeg en afgift?',
    'How do I create a user?': 'Hvordan opretter man en bruger?',
    'How do I change the numberplate on an existing permit?':
        'Hvordan ændrer jeg nummerplade på en tilladelse?',
    'How do I extend a permit?': 'Hvordan forlænger jeg en tilladelse?',
    "What to do if something doesn't work?": `Hvad gør jeg hvis noget ikke virker?`,
    'How often do you pay out customer shares?': `Hvor ofte afregner I?`,
    'Why am I being paid for a parking fee that is several years old now?':
        'Hvorfor afregnes jeg for en flere år gammel afgift nu?',
    'Status and their meanings': 'Status og deres betydninger',

    answer_1: {
        paragraph: `Tryk på “Dashboard” i venstre side, og vælg derefter “Tilladelser”. På denne side kan man se de nyeste tilladelser. Hvis man vil søge efter en specifik tilladelse kan dette gøres ved at søge på et reg. nr. i søgefeltet øverst. Hvis man vil oprette en ny tilladelser trykker man på knappen “+ Opret tilladelse”.
        Udfyld felterne med oplysningerne om bilen. Reg. nr. Parkeringsplads og Type er obligatoriske. Noter kan bruges til at give flere oplysninger om tilladelsen. “Note” kan kun ses af administratoren. “Ekstern note” kan ses af vagterne, når de laver P-kontrol. Hvis en bil kun må holde på en bestemt plads, kan man fx skrive i ekstern note: “Plads 22”. Husk at sætte en “Slut dato” for tilladelsen. Skal bilen have en permanent tilladelse, så trykker man på boksen “Permanent tilladelse?” Til sidst trykker man på “Opret”.`,
    },

    answer_2: {
        paragraph: `Tryk på “Dashboard” og derefter på “Afgifter”. På denne side kan man se de nyeste oprettede afgifter. Ønsker man at søge efter en specifik afgift, kan man gøre det ved at søge efter bilens reg. nr.  eller afgiftens ID i søgefeltet øverst.
        For at se mere info omkring en specifik afgift skal man trykke på den. “Status” viser hvor langt afgiften er i inddrivelsesprocessen. “Aktiv” betyder at afgiften er oprettet. “Inddrivelse” betyder at der er sendt brev til afgiftsmodtager. “Betalt” betyder at vi har modtaget betaling for afgiften. “Afregnet” betyder at vi har udbetalt jeres andel af afgiften til jer. “Annulleret” betyder at afgiften er annulleret.`,
    },
    answer_4: {
        paragraph: `Åben afgiften, som skal annulleres, ved at trykke på den. Et vindue med flere informationer åbner derefter op. Tryk derefter på feltet “Status” og vælg “Annulleret”. Scroll ned på siden og skriv en kommentar om hvorfor afgiften skulle annulleres. Tryk på “Gem” for at gemme kommentaren, og til sidst trykker man på “Opdater”. Afgiften er nu annulleret.`,
    },
    answer_3: {
        paragraph: `Tryk på “Dashboard” og derefter på “Brugere”. Her kan man se en liste med alle de brugere, som er tilknyttet jeres organisation. For at oprette en ny bruger trykker man på “+ Opret bruger”. Indtast oplysningerne på brugeren. For nemhedens skyld anbefaler vi at man bruger Email til brugernavnet også. Vælg hvilke P-pladser, som brugeren skal have adgang til, samt om brugeren skal have adgang til platformen og/eller appen. Vælg en slut dato for brugeren. Skal brugeren være permanent trykker man på boksen “Permanent bruger”. Til sidst trykker man på “Opret”. En Email sendes derefter til brugeren, med deres login info.`,
    },
    answer_5: {
        paragraph: `På siden 'Tilladelser' skriver du nummerpladen på bilen ind i søgefeltet og trykker enter/Søg. På listen nedenfor klikker du på den relevante tilladelse. I feltet ‘Reg.nr.’ ændrer du til det nye registreringsnummer. Herefter tjekker du at parkeringspladsen, slutdato og det øvrige ser korrekt ud og klik på 'Opdater'. `,
    },
    answer_6: {
        paragraph: `På siden 'Tilladelser' skriver du nummerpladen på bilen ind i søgefeltet og trykker enter/Søg. På listen nedenfor klikker du på den relevante tilladelse. I feltet ‘Reg.nr.’ ændrer du til det nye registreringsnummer. Herefter tjekker du at parkeringspladsen, slutdato og det øvrige ser korrekt ud og klik på 'Opdater'. `,
    },
    answer_7: {
        paragraph: `Det hjælper ofte at logge ud og ind igen, så det anbefaler vi dig altid at du gør først. Virker det stadig ikke er du altid velkommen til at skrive en mail til os på service@oparko.com . For at vi hurtigere kan hjælpe dig, må du meget gerne medsende skærmbilleder af problemet (hvis der er sådan ét).Har du brug for hjælp her og nu, så er vores telefon åben mandag, tirsdag og torsdag 11-15 og fredag 11-13. Ring på telefon 8874 3194.`,
    },
    answer_8: {
        paragraph: `I den samarbejdsaftale vi har indgået/kontrakt finder du svar på, hvor ofte der afregnes. Standarden er én gang hver tredje måned.`,
    },
    answer_9: {
        paragraph: `Hvor hurtigt en afgift betales efter udstedelse kan variere fra 'med det samme' til 'aldrig'. Det betyder, at afgifter der er udstedt for måneder eller år siden kan fremgå på jeres dags dato afregning, fordi den er blevet betalt siden vi sidst afregnede med jer. `,
    },
    answer_10: {
        paragraph1: `Aktiv: Afgiften er oprettet i vores system.`,
        paragraph2: `Inddrivelse: Afgiften er sendt til inddrivelse ved vores samarbejdspartner og der er sendt brev af sted til parkanten`,
        paragraph3: `Betalt: Når afgiften er betalt, så skifter afgiften status til betalt`,
        paragraph4: `Afregnet: Afgiftsandelen er udbetalt til afgiftsskriveren `,
        paragraph5: `Annulleret: Afgiften er annulleret i vores system`,
        paragraph6: `Beskyttet: Udenlandsk sag`,
    },

    // weekdays
    Mon: 'Man',
    Tue: 'Tir',
    Wed: 'Ons',
    Thur: 'Tor',
    Fri: 'Fre',
    Sat: 'Lør',
    Sun: 'Søn',
    Monday: 'Mandag',
    Tuesday: 'Tirsdag',
    Wednesday: 'Onsdag',
    Thursday: 'Torsdag',
    Friday: 'Fredag',
    Saturday: 'Lørdag',
    Sunday: 'Søndag',
    Hourly: 'Timer',
    Weekly: 'Uger',
    Hour: 'Time',
    hour: 'time',
    hours: 'timer',
    Day: 'Dag',
    day: 'dag',
    'half-hour': 'halve time',
    'half-hours': 'halve timer',
    days: 'dage',
    Week: 'Uge',
    weeks: 'uger',
    Month: 'Måned',
    months: 'måneder',
    'Number of': 'Antal',
    First: 'Første',
    to: 'til',
    Free: 'Gratis',
    Percentage: 'Procent',
    'Subscription per month': 'Abonnement pr. måned',
    'No limit on': 'Ingen begrænsning på',
    After: 'Efter',
    Count: 'Antal',

    January: 'Januar',
    February: 'Februar',
    March: 'Marts',
    April: 'April',
    June: 'Juni',
    July: 'Juli',
    August: 'August',
    September: 'September',
    October: 'Oktober',
    November: 'November',
    December: 'December',
    'All days': 'Alle dage',
    'All day': 'Hele døgnet',
    Valid: 'Gælder',
    month: 'måned',
    Per: 'Pr.',

    // popup box - accepted
    accepted_title: 'Køb accepteret',
    accepted_description1: 'Køb af p-billet gennemført',
    accepted_description2:
        'Såfremt der er angivet e-mail i forrige mailfelt, vil der blive sendt en bekræftelsesmail til den angivne e-mailadresse.',
    accepted_description3: 'Som bekræftelse på tilladelsen kan De anvende:',
    accepted_description4: 'Udklip af debiteret bankoverførsel',
    accepted_description5: 'Udklip af MobilePay-betaling',
    accepted_description6: 'Tilsendte bekræftelsesmail.',
    accepted_description7:
        'Såfremt der er yderligere spørgsmål og/eller tvivl vedrørende betaling, kontakt os på:',
    accepted_description8: 'Vinduet kan nu lukkes.',

    Ok: 'Okay',
    // popup box - successfull
    successfull_title: 'Velkommen til Oparko!',
    successfull_description: 'Dit abonnement er nu aktivt.',
    successfull_description1:
        'Du har modtaget alle oplysninger om dit abonnement på din e-mail. Du kan se og opdatere detaljer om dit abonnement direkte på platformen ved at logge ind.',
    successfull_description2:
        'Du kan nu lukke dette vindue for at få adgang til din konto.',
    // popup box - declined
    declined_title: 'Desværre opstod der en fejl med din betaling.',
    declined_description:
        'Din abonnementskonto blev stadig oprettet, men den er ikke aktiv. Dette betyder, at vi ikke vil tage nogen betalinger fra dig, og du vil heller ikke have en aktiv tilladelse til at parkere din bil.',
    declined_description1:
        'Du har modtaget alle nødvendige oplysninger til at logge ind på vores platform via din e-mail, fra vores platform kan du finde betalings-linket til at forsøge endnu en betaling, hvis du ønsker det.',
    declined_description2:
        'Alternativt kan du bruge nedenstående link til at forsøge igen:',
    declined_description3: 'Eller kontakt Oparko Support her:',
    declined_description4:
        'Hvis du har spørgsmål vedrørende betalingen, kan du kontakte os på ',
    'Open Subscription': 'Åben abonnement',
    pay_subscription_heading: 'Betaling af abonnementet',
    pay_subscription_description:
        'Du forsøger at betale for din abonnements konto, bekræft venligst for at fortsætte med din betaling.',
    'Cancel Subscription': 'Annuller abonnement',
    cancel_sub_description:
        'Vi er kede af at se dig forlade Oparko. Hvis du er sikker på, at du vil annullere dit abonnement, skal du klikke på "Accepter" for at bekræfte din beslutning.',
    cancel_sub_description1:
        'Bemærk venligst, at dit abonnement vil forblive aktivt indtil udgangen af din nuværende betalingsperiode. Vi vil ikke opkræve dig yderligere, og dit abonnement vil blive inaktivt fra næste måned.',

    ticket_service_disclaimer_1:
        'Vi gør opmærksom på at det ikke er muligt at stoppe sin parkeringstilladelse før tid. Det er ikke muligt at forlænge sin parkeringstilladelse, ønsker man at blive holdende, skal man købe en ny tilladelse, når den første er udløbet. Det er endvidere ikke muligt at annullere en tilladelse, når denne først er oprettet.',
    ticket_service_disclaimer_2:
        'Vi gør særligt opmærksom på, at det er parkantens fulde ansvar, at de angivne oplysninger er korrekte. Samtidig fraskriver Parkeringskompagniet sig ansvaret for enhver fejl ved oprettelsen. Når du køber en p-billet samtykker du samtidig til vores vilkår og betingelser. Du samtykker derfor også til vores persondatapolitik.',
    subscription_service_disclaimer:
        'Vi gør særligt opmærksom på, at det er parkantens fulde ansvar, at de angivne oplysninger er korrekte. Samtidig fraskriver Parkeringskompagniet sig ansvaret for enhver fejl ved oprettelsen. Når du køber et abonnement, samtykker du samtidig til vores vilkår og betingelser. Du samtykker derfor også til vores persondatapolitik.',
    subscription_service_disclaimer_2: `Abonnementsparkeringsaftalen berettiger kun til parkering på det område, som fremgår af abonnementsparkeringsaftalen, i de afmærkede parkeringsbåse hvor det ikke fremgår, at der er særlige regler gældende for parkering i disse parkeringsbåse. Der gøres opmærksom på, at Abonnementsparkeringsaftalen ikke er en gyldig parkeringstilladelse til parkering i parkeringsbåse, som er reserveret.`,
    'Update Card': 'Opdater kort ',
    'Pay Subscription': 'Betal Abonnement',
    'Update payment card': 'Opdater betalingskort',
    update_card_description:
        'Du forsøger at opdatere dine kreditkortoplysninger. Bekræft venligst for at fortsætte med opdateringen.',
    update_card_cancel:
        'Desværre opstod der en fejl med opdateringen af dit kort. ',
    update_card_cancel_description:
        'Dit abonnementskonto er stadig aktiv, med en aktiv tilladelse. Hvis du ønsker at opdatere dine kortoplysninger, bedes du venligst forsøge igen.',
    update_card_cancel_description2:
        'Hvis du har spørgsmål vedrørende opdatering af dit kort, kan du kontakte os på ',
    update_card_accept: 'Opdateringen af dit kort var vellykket.',
    update_card_accept_description:
        'Dine kortoplysninger er blevet opdateret til din abonnementskonto. ',
    update_card_accept_description2:
        'Hvis du har spørgsmål vedrørende opdatering af dit kort, kan du kontakte os på ',
    'No Future payments': 'Ingen fremtidige betalinger',
    // Months
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Apr',
    May: 'Maj',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Oct: 'Okt',
    Nov: 'Nov',
    Dec: 'Dec',

    // redirectFromPK
    'Important announcement': 'Vigtig opdatering',
    'Welcome! You have landed here because Parkeringskompagniet has now become':
        'Velkommen! Du er landet her, fordi Parkeringskompagniet nu er blevet til',
    'We have upgraded to a new management platform to offer you an even better user experience':
        'Vi har opgraderet til en ny administrationsplatform for at tilbyde dig en endnu bedre brugeroplevelse.',
    'What should you do now?': 'Hvad skal du gøre nu?',
    'Your existing information is automatically transferred. You just have to log in from the new platform now':
        'Dine eksisterende oplysninger er automatisk overført. Du skal blot fremover logge ind på den nye platform',
    'New Functions': 'Nye Funktioner',
    'The new platform can do everything you are used to, plus much more!':
        'Den nye platform kan alt det, du er vant til, plus meget mere!',
    'Do you have questions? Contact us at service@oparko.com':
        'Har du spørgsmål? Kontakt os på service@oparko.com',
    'E-mail at': 'E-mail til',
    'Call at': 'Ring til',
    'Phone hours:': 'Telefontider:',
    'Go to new admin platform': 'Gå til ny admin platform',

    // Pay fine
    'How to appeal': 'Klagevejledning',
    'Get documentation': 'Hent dokumentation',
    'Complaint form': 'Klageformular',
    'Select a reason': 'Vælg en årsag',
    Appeal: 'Klage kommentar',
    'Submit an appeal': 'Opret klage',
    'Pay fine': 'Betal afgift',
    'License plate': 'Nummerplade',
    'Fine id': 'Afgift-id',
    'Fine documentation': 'Afgiftdokumentation',
    retrieve_fine_documentation_text:
        "Bemærk, at ikke alle afgifter vil resultere i dokumentation. Afgift-id'et er det id, der fulgte med din afgift, du kan altid finde dit afgift-id i den email du har modtaget.",
    Subject: 'Emne',
    'Valid permit': 'Gyldig parkeringstilladelse',
    'Type in complaint here': 'Skriv klage her',
    Attachments: 'Vedhæft filer',
    'Select files': 'Vælg filer',
    'Select file': 'Vælg fil',
    complaint_form_text:
        'Vær opmærksom på, at alle henvendelser vedrørende parkeringsafgifter, herunder klager, betalinger, rykkere og lignende, skal indsendes skriftligt ved brug af nedenstående formular. Det er afgørende, at vi modtager alle nødvendige oplysninger skriftligt.',
    Offense: 'Vælg forseelse',
    '01': 'Parkeret udenfor bås',
    '001': 'Afgiften er udsted på grund af parkering udenfor afmærket bås. Der kan enten være tale om en parkering ud over stregerne eller helt uden for en bås. Som det fremgår af skiltningen på pladsen, er parkering kun tilladt i afmærket p-bås.Overtrædelse af dette vilkår medfører kontrolafgift. P-vagten har dokumenteret parkeringen med fotos.',
    '02': 'Parkeret uhensigtsmæssigt f.eks. foran varelevering eller køreareal',
    '002': 'Afgiften er udstedt, da parkeringen er uhensigtsmæssig i forhold til f.eks. varelevering, tilkørselsvej eller lignende.Dette er tilfældet hvis din bil f.eks. spærrer for tilkørsel til andre veje eller blokerer for trafikken i området.',
    '03': 'Ingen gyldig tilladelse til området',
    '003': 'Afgiften er udstedt grundet manglende tilladelse. Som det fremgår af skiltningen på pladsen, er der krav om tilladelse ved standsning og parkering på pladsen.Bilens ophold på pladsen overskrider den fastsatte observationstid. Derfor er der sket en overtrædelse af pladsens gældende vilkår, hvilket medfører en kontrolafgift.',
    '04': 'Tilladelse var ikke synlig eller udløbet',
    '004': 'Afgiften er udstedt, da tilladelsen ikke har været synlig eller er udløbet.Hvis p-vagten ikke har været i stand til at kontrollere tilladelsen tilstrækkeligt, eller hvis denne er udløbet, så er vilkårene overtrådt. En overtrædelse af vilkårene medfører en kontrolafgift.',
    '05': 'Ikke betalt for parkering på en betalingsplads',
    '005': 'Afgiften er udstedt på grund af manglende tilladelse. Som det fremgår af skiltningen på pladsen, er der krav om tilladelse ved standsning og parkering på pladsen.Vi har ikke registreret en købt tilladelse i vores system til den pågældende plads og vilkårene på pladsen er dermed overtrådt. En overtrædelse af vilkårene medfører en kontrolafgift.',
    '06': 'Tid på parkeringsskiven er overskredet',
    '006': 'Afgiften er udstedt, da bilens p-skive overskredet. Det fremgår af skiltene, at parkering er tilladt i begrænset tid med korrekt indstillet p-skive.Bilens p-skive har været indstillet forkert, slet ikke indstillet eller bilen har parkeret længere tid, end hvad der er tilladt. P-vagten har dokumenteret parkeringen med fotos.',
    '07': 'Du har ændret tiden på p-skiven uden at flytte bilen',
    '007': 'Afgiften er udstedt, da p-skiven er ændret, hvorefter parkering er fortsat.Det er kun tilladt at parkere på området i begrænset tid og ved at ændre på p-skiven, så kan der parkeres længere tid end hvad reglerne tillader. Dette medfører en kontrolafgift.',
    '08': 'Parkering eller standsning i standsning forbudt område',
    '008': 'Afgiften er udstedt da bilen har været parkeret et sted på området, hvor standsning og parkering er forbudt. Dette medfører en kontrolafgift. Parkeringen er dokumenteret med fotos.',
    '09': 'Parkering på brandvej',
    '009': 'Afgiften er udstedt, da bilen er parkeret på brand- eller redningsvej.Parkering på sådanne veje er ikke tilladt og dette medfører derfor en kontrolafgift. P-vagten har dokumenteret parkeringen med fotos.',
    '10': 'Parkering på handikapplads uden synligt handikapskilt',
    '010': 'Afgiften er udstedt, da bilen har været parkeret i en handicapbås uden synlig tilladelse hertil. Som det fremgår af skiltningen, er den pågældende bås en handicapbås. Dette betyder, at der er krav om handicap-parkeringskort.I denne forbindelse henvises der til bekendtgørelse om parkeringskort for personer med handicap og institutionskort (BEK nr 782 af 07/08/2019), se særligt § 13, stk. 1, som lyder: Når et parkeringskort eller institutionskort anvendes, skal det være anbragt inden for forruden med kortets forside klart synlig for kontrol.”.Her er et link til bekendtgørelsen: https://www.retsinformation.dk/eli/lta/2019/782. P-vagten har dokumenteret parkeringen med fotos.',
    '11': 'Parkeret på en plads reserveret til elbiler der lader, eller ikke flyttet bil efter endt ladning',
    '011': 'Afgiften er udstedt, da parkering på dette område kun er tilladt for el-biler under opladning.Hvis bilen ikke (længere) er under opladning, så er parkering ikke tilladt i dette område. Parkering er således i strid med vilkårene på pladsen, hvilket medfører en kontrolafgift. P-vagten har dokumenteret parkeringen med fotos.',
    '12': 'Du har ikke overholdt regler og vilkår, som er skiltet på pladsen',
    '012': 'Afgiften er udstedt da de skiltede vilkår på pladsen ikke er overholdt.En overtrædelse af vilkårene medfører en kontrolafgift.',
    Disagree:
        'Hvis du mener, at din afgift er uberettiget, kan du indsende en skriftlig klage, udfyld venligst nedenstående felter',
    Price: 'Beløb',
    Location: 'Lokation',
    Continue: 'Fortsæt',
    Topic: 'Emne',
    Validpermit: 'Gyldig tilladelse',
    'Why you received a control fee': 'Årsag til at du modtog et kontrolgebyr',
    Cant_find_Fine_id: `Kunne ikke hente nogen 'Afgift-id' og 'Nummerplade'. Tjek venligst om 'Afgift-id' og 'Nummerplade' er korrekte.`,
    Fine_is_paid: 'Denne afgift er allerede betalt',
    Cant_get_documentation:
        'Det er ikke længere muligt at hente dokumentation til denne afgift.',
    Send_complain: 'Klagen er indsendt',
    Error_send_complain:
        'Der opstod en fejl, tjek venligst at alle felterne er udfyldt korrekt',
    // payment accepts
    payment_approve: 'Godkendt',
    payment_approve_1: 'Betaling af kontrolafgiften er gennemført',
    payment_approve_2:
        'Vi bekræfter, at din betaling af kontrolafgiften er blevet gennemført. Den tilsendte bekræftelsesmail fungerer som dokumentation for, at kontrolafgiften er betalt.',
    payment_approve_3:
        'Vi fremsender altid kontrolafgift med post, men som det fremgår af brevet, så kan der ses bort fra dette, såfremt der allerede er sket fyldestgørende betaling.',
    payment_approve_4:
        'Vilkår og betingelser for betaling af kontrolafgiften kan læses på følgende link:',
    payment_approve_5:
        'Har du yderligere spørgsmål eller tvivl vedrørende betalingen, er du velkommen til at kontakte os på: ',
    payment_approve_6: 'Vinduet kan nu lukkes.',
    // payment decline
    payment_decline: 'Fejl ved Betaling',
    payment_decline_l1: 'Beklager, men noget gik desværre galt.',
    payment_decline_l2:
        'Din betaling for kontrolafgiften kunne ikke gennemføres.',
    payment_decline_l3:
        'Vær venlig at dobbelttjekke, at de indtastede oplysninger er korrekte.',
    payment_decline_l4:
        'For mere information om betaling, se venligst vilkår og betingelser på følgende link: ',
    payment_decline_l5:
        'Har du yderligere spørgsmål eller tvivl vedrørende betalingen, er du velkommen til at kontakte os på:',
    payment_decline_l6: 'Vinduet kan nu lukkes.',
    // Complaint sent
    complaint_appeal_submit: 'Modtaget',
    complaint_appeal_submit_1: 'Vi bekræfter, at vi har modtaget din e-mail',
    complaint_appeal_submit_2:
        'Vores klageteam vil gennemgå din forespørgsel og svare så hurtigt som muligt',
    'Back to start': 'Tilbage til start',
};

export default dansk;
