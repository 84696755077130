const deutsche = {
    // Generic
    Update: 'Aktualisieren',
    Cancel: 'Zurück',
    Create: 'Schaffen',
    Delete: 'Löschen',
    Upload: 'Hochladen',
    Close: 'Schließen',
    Read: 'Lesen',
    'Generate Report': 'Bericht generieren',
    'Search the table': 'Suche die Tabelle',
    Page: 'Seite',
    of: 'von',
    Images: 'Bilder',
    Dates: 'Datum',
    Date: 'Datum',
    Note: 'Hinweis',
    Status: 'Status',
    Country: 'Land',
    'Select all': 'Wählen Sie Alle',
    Allow: 'Zulassen',
    Business: 'Unternehmen',
    Search: 'Suche',
    More: 'Mehr',
    Hide: 'Ausblenden',
    Other: 'Sonstiges',
    Save: 'Speichern',
    Unknown: 'Unbekannt',
    Choose: 'Wählen',
    Website: 'Webseite',
    more: 'mehr',
    'Created date': 'Erstellungsdatum',
    AS: 'ALS',
    Return: 'Zurück',
    Percentage: 'Prozentsatz',
    Count: 'Zählen',
    // User
    Username: 'Nutzername',
    Password: 'Passwort',
    'Created By': 'Erstellt Von',
    'Created by': 'Erstellt von',
    'Created Date': 'Erstellungsdatum',
    'Last login web': 'Letztes Login-Web',
    'Last login app': 'Letztes Login-app',

    Admin: 'Admin',

    // Menu
    Home: 'Heim',
    Dashboard: 'Dashboard',
    'Parking Lots': 'Parkplätze',
    'Parking lots': 'Parkplätze',
    'Map of Parking Lots': 'Parkplatzkarte',
    Users: 'Benutzer',
    Permits: 'Genehmigungen',
    Fines: 'Geldbußen',
    Customers: 'Kunden',
    Backoffice: 'Backoffice',
    Map: 'Karte',
    Statistics: 'Statistiken',
    Subscriptions: 'Abonnements',
    'Ticket Service': 'Kaufen Sie ein Parkschein',
    'Buy a permit': 'Kaufen Sie ein Parkschein',
    'Get a permit': 'Besorg dir ein Parkschein',
    'Private Ticket Service': 'einen Parkschein bekommen',
    Reports: 'Berichte',
    Rent: 'Miete',
    Prices: 'Preisen',

    // Settings
    Settings: 'Einstellungen',
    'User Details': 'Nutzerdetails',
    Language: 'Sprache',
    'Parking Lot': 'Parkplatz',
    'Parking lot': 'Parkplatz',
    Street: 'Straße',
    'Street Number': 'Hausnummer',
    ZipCode: 'Postleitzahl',
    City: 'Stadt',
    'Notification settings': 'Benachrichtigungseinstellungen',
    'WELCOME TO': 'WILLKOMMEN BEI',

    // Login
    Login: 'Anmeldung',
    'Forgot Password': 'Passwort vergessen',
    Email: 'Email',
    'Reset Password': 'Passwort zurücksetzen',
    'Password reset email sent. Please check your email.':
        'Email zum Zurücksetzen des Passworts gesendet. Bitte überprüfen Sie Ihre Email',
    'Something went wrong': 'Etwas ist schief gelaufen',
    'Wrong username or password':
        'Falscher Benutzername oder falsches Passwort',
    'Error resetting password, please retry':
        'Fehler beim Zurücksetzen des Passworts, bitte versuchen Sie es erneut',
    'Digital parking solution for every need':
        'Digitale Parklösung für jeden Bedarf',

    // Search bar
    'None elected': 'Keine ausgewählt',
    'All selected': 'Alle ausgewählt',
    'Only active': 'Nur aktiv',
    'Show cancelled': 'Stornierte Bußgelder anzeigen',

    // Frontoffice
    'Latest fines': 'Neueste Geldbußen',
    'No data available!': 'Keine Daten verfügbar!',
    'There were no datapoints to show on this graph':
        'In diesem Graphen gab es keine Datenpunkte',
    'Good fines': 'Gute Geldbußen',
    Annulled: 'Annulliert',
    'Could not fetch fines - please refresh and try again':
        'Konnte nicht Geldbußen abrufen - bitte erfrischen und versuchen Sie es erneut',
    'Something went wrong, could not find any users - Please refresh and try again.':
        'Etwas ging schief, konnte keine Benutzer finden - bitte erfrischen Sie sich und versuchen Sie es erneut.',
    Activated: 'Aktiviert',
    Customer: 'Kunde',
    'Your Cars': 'Ihre Autos',
    'Location Name': 'Standortnamen',
    'User list': 'Benutzerliste',
    'Create User': 'Benutzer erstellen',
    Yes: 'Jawohl',
    No: 'Nein',
    'Permit list': 'Liste der Erlaubnis',
    'Create Permit': 'Genehmigung erstellen',
    'Start date': 'Startdatum',
    'Start Date': 'Startdatum',
    'End date': 'enddatum',
    'End Date': 'enddatum',
    TYPE: 'Art',
    'Could not find any parking lots - Please refresh and try again.':
        'Konnte keine Parkplätze finden - bitte erfrischen Sie sich und versuchen Sie es erneut.',
    'Parking Lots List': 'Parkplatzliste Parkplatz',
    Address: 'Adresse',
    'Parking Map': 'Parkkarte',
    'Could not find any hourly data - Please refresh and try again.':
        'Konnte keine stündlichen Daten finden - bitte erfrischen Sie und versuchen Sie es erneut.',
    'Something went wrong, could not find any daily data - Please refresh and try again.':
        'Etwas ist schief gelaufen, konnte keine täglichen Daten finden - bitte erfrischen Sie und versuchen Sie es erneut.',
    'Something went wrong, could not find any look up data - Please refresh and try again.':
        'Etwas ging schief, konnte keine Nachschlagdaten finden - bitte erfrischen Sie sich und versuchen Sie es erneut.',
    'Hourly visits count': 'Stundenbesuche',
    'Daily visits count': 'Tägliche Besuche',
    'Fine list': 'Feine Liste',
    'Could not generate the report - please refresh and try again':
        'Konnte den Bericht nicht generieren - bitte aktualisieren und erneut versuchen',
    'Country code': 'Landesvorwahl',
    cancelFine: 'Warum heben Sie diese Geldstrafe auf? (Min. 20 Zeichen)',
    Comments: 'Kommentare',
    'Cancelled fines': 'Annullierte Gebühren',
    'Active fines': 'Aktive Gebühren',
    'Total fines': 'Total fines',
    'Parking Lot list': 'Parkplatzliste',
    'Reg. nr.': 'Reg. nr.',
    'Country and reg. no.': 'Country and reg. no.',

    // Backoffice
    'Customer list': 'Kundenliste',
    'Something went wrong in searching for customers':
        'Bei der Suche nach Kunden ging etwas schief',
    'Create Customer': 'Kunden erstellen',
    Name: 'Name',
    'Create Fine': 'Gut schaffen',
    'Generate DMR report': 'DMR-Bericht generieren',
    'Advanced Report': 'Fortgeschrittener Bericht',
    'Mass change': 'Massenwechsel',
    'Customers info': 'Kunden info',
    'Accounting info': 'Buchhaltung info',
    'Create p-lot': 'Maak p-lot',
    'Organization list': 'Liste der Organisationen',
    Add: 'Hinzufügen',
    'Parking Lot Info': 'Parkplatz Info',
    'Add ruleset': 'Regelset hinzufügen',
    'Show Spots and Groups': 'Zeige Spots und Gruppen',

    // Modals
    'Send Password': 'Passwort senden',
    'Settlement dates': 'Abrechnungsdaten',
    Contractor: 'Handwerker',
    Offence: 'Vergehen',
    'Profile Image': 'Profilbild',
    'Street Name': 'Straßenname',
    'House.No': 'StraßeHausnr',
    'Parking Lot Details': 'Details zum Parkplatz',
    'Postal Code': 'Postleitzahl',
    'Fine Price': 'bestrafe Preis',
    'Parking Terms': 'Parkbedingungen',
    '% for PK': '% zum OPARKO',
    'Incident information': 'Vorfallinformationen',
    'Incident Information': 'Vorfallinformationen',
    'Leave a comment...': 'Hinterlasse einen Kommentar...',
    Latitude: 'Breite',
    'Loading...': 'Wird geladen...',
    'Could not find the parking lot you were looking for.':
        'Konnte den Parkplatz nicht finden, nach dem Sie gesucht haben.',
    'Successfully updated the parking lot!':
        'Der Parkplatz wurde erfolgreich aktualisiert!',
    'Successfully created new parking lot!':
        'Erfolgreicher Parkplatz erfolgreich erstellt!',
    'You can now close the modal.': 'Sie können jetzt das Modal schließen.',
    'Geographic coordinate': 'Geographische Koordinate',
    'Camera Fines': 'Kameragebühren',
    Longitude: 'Längengrad',
    'Rules for parking': 'Regeln für den Parkplatz',
    'Rules for guest parking': 'Regeln für Gästeparkplätze',
    'Show customer': 'Kunden zeigen',
    'Number of active permits created by one user at the same time':
        'Anzahl aktiver Genehmigungen, die gleichzeitig von einem Benutzer erstellt wurden',
    'Maximum length for created permits in app':
        'Maximale Länge für erstellte Genehmigungen in App',
    'Note for app': 'HINWEIS FÜR APP.',
    'Control Type': 'Steuertyp.',
    'Camera control': 'Kamerakontrolle',
    'Camera Control': 'Kamerakontrolle',
    'Guard control': 'Schutzkontrolle',
    'Guard Control': 'Schutzkontrolle',
    'Self control': 'Selbstkontrolle',
    'Self Ticketing': 'Selbstticket',
    'Need for collection': 'Abholung erforderlich',
    'Parking Spots': 'Parkstände',
    'Rent?': 'Mieten?',
    Distribution: 'Verteilung',
    'Amount of Duty': 'Höhe der Pflicht',
    'Why are you canceling this Fine? - Minimum 20 characters':
        'Warum stornst du diese Geldstrafe? - Mindestens 20 Zeichen',
    'Send and Cancel Fine': 'Fein senden und stornieren',
    'Cancel Fine': 'Geld stornieren',
    'Could not find the fine you were looking for.':
        'Konnte die Geldstrafe nicht finden, nach denen Sie gesucht haben.',
    'Could not find the permit you were looking for.':
        'Konnte die Genehmigung nicht finden, nach denen Sie gesucht haben.',
    'Belongs to': 'Gehört',
    'Internal note': 'Interne Note',
    'External note': 'Externe Note',
    'Could not create the permit - close and try again please':
        'Konnte die Genehmigung nicht erstellen - schließen und erneut versuchen',
    'Could not update the permit - close and try again please':
        'Konnte die Genehmigung nicht aktualisieren - schließen und erneut versuchen',
    'User successfully created, you can close the modal again':
        'Benutzer erfolgreich erstellt, Sie können das Modal erneut schließen',
    'User successfully updated, you can close the modal again':
        'Benutzer erfolgreich aktualisiert, können Sie das Modal erneut schließen',
    'User credentials': 'Anmeldeinformationen des Benutzers.',
    'Confirm password': 'Bestätige das Passwort',
    'The password matches': 'Das Passwort passt zu',
    'The password does not matches': 'Das Passwort stimmt nicht überein',
    Permissions: 'Berechtigungen',
    'Users to manage': 'Benutzer zu schalten',
    'Activate User': 'Benutzer aktivieren',
    'Access to Website': 'Zugriff auf die Website',
    'Access to App': 'Zugriff auf App',
    'Access to API': 'Zugriff auf API',
    'Handle Fines': 'Bußgelder handhaben',
    'Handle Permits': 'Genehmigungen handhaben',
    User: 'Benutzer',
    Permit: 'Erlauben',
    Fine: 'Bußgeld',
    'Update customer': 'Kunde aktualisieren',
    'Customer Type': 'Kundentyp',
    Created: 'Erstellt',
    Updated: 'Aktualisiert',
    'Connected Users': 'Verbundene Benutzer',
    'Connected parking lots': 'Zugehörige Parkplätze',
    'Total amount of users:': 'Gesamtbetrag der Benutzer:',
    'Fine successfully updated, you can close the modal again':
        'Gut erfolgreich aktualisiert, können Sie das Modal wieder schließen',
    'Fine successfully created, you can close the modal again':
        'Erfolgreich erfolgreich erstellt, können Sie das Modal wieder schließen',
    'Update Fine': 'Update fein',
    'New Fine': 'Neue Geldstrafe',
    Owner: 'Eigentümer',
    'Parking guard': 'Parkwächter',
    'House number': 'Hausnummer',
    'House nr.': 'Hausnummer',
    'Open Parking Lot': 'Offener Parkplatz',
    'Update parking lot': 'Aktualisieren Sie Parkplatz',
    'External Customers': 'Externe Kunden.',
    'Back to Fine': 'Zurück zu in Ordnung',
    'Update user': 'Benutzer aktualisieren',
    'Update User': 'Benutzer Aktualisieren',
    'New user': 'Neuer Benutzer',
    'Update subscription': 'Abonnement aktualisieren',
    'New subscription': 'Neues Abonnement',
    'Create subscription': 'Abonnement erstellen',
    'Create Subscription': 'Abonnement Erstellen',
    'Creating subscription...': 'erstellt ein Abonnement',
    'Please select a company': 'Bitte wählen Sie ein Unternehmen aus',
    "Customer's parking lots": 'Parkplätze des Kunden',
    'User Identity': 'Benutzeridentität',
    Identity: 'Identität',
    'User Identities': 'Benutzeridentitäten',
    'No identities for user': 'Keine Identitäten für Benutzer',
    'Fine Details': 'Feine Details',
    'No one has commented or changed status yet.':
        'Niemand hat den Status den Status kommentiert oder geändert.',
    'Leave a note on the incident': 'Hinterlassen Sie einen Anmerk zum Vorfall',
    'Update Fines': 'Fines aktualisieren',
    'This will reset the password and email the new password. Do you want to continue?':
        'Dadurch wird das Passwort zurückgesetzt und das neue Passwort per E-Mail gesendet. Möchtest du weiter machen?',
    'Parking Info': 'Parkinfo',
    Organizations: 'Organisationen',
    Organization: 'Organisation',
    'Payout Details': 'Auszahlungsdetails',
    'Bank Registration Number': 'Bankregistrierungsnummer',
    'Bank Account Number': 'Bankkontonummer',
    '% for parking guard': '% für Parkwächter',
    '% for customer': '% zum Kunden',
    'Charge Distributions': 'Gebührenzuweisungen',
    'There are no Parking Lots': 'Es gibt keine Parkplätze',
    'There are no Users': 'Es gibt keine Benutzer',
    'External Organizations': 'Externe Organisationen',
    'Select image': 'Bild auswählen',
    'Outside booth': 'Außerhalb der Parkbuchten',
    'No permit to the parking area': 'Keine Genehmigung für den Parkplatz',
    'P-disc exceeded': 'P-Scheibe überschritten',
    'Parking or stopping in prohibited area':
        'Parken oder Halten im verbotenen Bereich',
    'Parking in fire lane': 'Parken auf Feuerwehrstraße',
    'Handicap booth without permission': 'Handicap booth without permission',
    "Organization's Parking Lots": 'Die Parkplätze der Organisation',
    'Choose Parking bays': 'Wählen Sie Parkplätze',
    'Permanent user': 'Permanenter Benutzer',
    'New Permit': 'Neuer Parkausweis',
    'Update Permit': 'Parkausweis aktualisieren',
    'Created for user': 'Erstellt für den Benutzer',
    Notes: 'Anmerkungen',
    'Permit Information': 'Informationen zum Ausweis',
    'Date not assigned': 'Datum nicht zugeordnet',
    'Note from creation': 'Notiz von der Erstellung',
    'Created at': 'Erstellt bei',
    'Updated at': 'Aktualisiert um',
    'Address name': 'Adressname',
    'Address Name': 'Adressname',
    'Arrival date': 'Ankunftsdatum',
    'Arrival Date': 'Ankunftsdatum',
    'Depart. Date': 'Abfahrtsdatum',
    'Depart. date': 'Abfahrtsdatum',
    Camera: 'Kamera',
    'Other - Leave a note': 'Andere - Hinterlass eine Notiz',
    'Parking time exceeded': 'Parkzeit überschritten',
    'Total parking time': 'Gesamtparkzeit',
    Approve: 'Genehmig',
    Decline: 'Ablehn',
    Back: 'Zurück',
    'Employee Paid Ticket': 'Employee Paid Ticket',
    Details: 'Einzelheiten',
    'Street name': 'Straßenname',
    'Postal code': 'Postleitzahl',
    'Fine price': 'Steuerpreis',
    'No results found': 'keine Ergebnisse gefunden',
    'Buffer time in minutes': 'Buffertid i minutter',
    'Active permits': 'Aktive Parkausweise',
    'Duration of permits': 'Dauer der Parkausweise',
    selected: 'ausgewählte',
    Distance: 'Abstand',
    'Organization address': 'Adresse der Organisation',
    'Organization details': 'Details zur Organisation',
    'Create Organization': 'Organisation Erstellen',
    'The permit will be made for every parking lot chosen, and based on the users start and end date.':
        'The permit will be made for every parking lot chosen, and based on the users start and end date.',
    'Comment added by': 'Kommentar hinzugefügt von',
    'Status changed from': 'Status geändert von',
    'Status changed by': 'Status geändert durch',
    'Not defined': 'Nicht definiert',
    'Not Defined': 'Nicht definiert',
    'Self ticketing': 'Selbstticket',
    Combination: 'Kombination',
    'P-guard': 'Verkehrswachtmeister',
    'Registered before': 'Frühere Anmeldungen',
    'You already have a user': 'Du hast bereits einen Benutzer',
    'Login to purchase an extra subscription':
        'Login um ein zusätzliches Abonnement zu kaufen',
    'Missing payment in min': 'Fehlende Zahlung in Min',
    'Open Subscriber': 'Abonnee openen',
    'Every permit row is clickable and show data in Permit Modal':
        'Elke vergunningsrij is klikbaar en toont gegevens in Permit Modal',
    'Choosing multiple parking lots creates a singular permit for each address selected':
        'Durch die Auswahl mehrerer Parkplätze wird für jede ausgewählte Adresse eine einzige Genehmigung erstellt',
    'These rules only affects the permits created from the app, not permits created by admin from Web':
        'Deze regels zijn alleen van invloed op de vergunning die via de app is gemaakt, niet op de vergunning die door de beheerder via het web is gemaakt',
    '0 = none and empty = unlimited': '0 = none and empty = unlimited',

    // Finance
    DKK: 'DKK',
    pcs: 'Stück',
    k: 'k',
    Revenue: 'Einnahmen',
    'Paid Permits': 'Bezahlte Genehmigungen',
    'Coming soon': 'Kommt bald',
    'Total revenue': 'Gesamteinnahmen',
    'Total paid out': 'Total ausbezahlt',
    'Total unsettled': 'Ausstehende Gesamtbetrag',
    'Permit revenue sources in DKK': 'Einnahmequellen in DKK zulassen',
    'Number of permits per parking operator':
        'Anzahl der Genehmigungen pro Parkbetreiber',
    'Number of parking tickets made through each parking operator.':
        'Anzahl der von jedem Parkbetreiber ausgestellten Parkscheine.',
    'You have no permits to show': 'Sie haben keine Erlaubnis zu zeigen.',
    'Revenue by type in DKK': 'Umsatz nach Art in DKK',
    'Fines by status': 'Bußgelder nach Status',

    'Active: status explained':
        'Aktiv: Eine erstellte Strafe, die sich noch in Bearbeitung befindet',
    'Cancelled: status explained':
        'Versteckt: Sie wurde storniert, überprüfen Sie den Kommentar oder die Notiz, um den Grund zu erfahren',
    'Paid: status explained':
        'Bezahlt: Die Strafe wurde an Parkingskompagniet gezahlt',
    'Settled: status explained':
        'Erledigt: Die Strafe wurde an den Kunden gezahlt',
    'Protected: status explained': 'Geschützt: Sie ist vorübergehend pausiert',
    'Debt collection/Collectia: status explained':
        'Schuldeneintreibung/Collectia: Die Schuldeneintreibung läuft.',

    revenue_by_type_explanation:
        'Der Umsatz basiert auf den Einnahmen aus den Parkplätzen, deren Eigentümer Ihre Organisation ist.',
    'Fines: Every fine that is paid or settled':
        'Bußgelder: Jedes Bußgeld, das gezahlt oder beglichen wird',
    'Permits: Every permit that is paid':
        'Genehmigung: Jede bezahlte Genehmigung',
    'Subscriptions: Every payment on all subscriptions':
        'Abonnement: Jede Zahlung für alle Abonnements',
    'Charging station: Coming soon': 'Ladesäule: Kommt bald',
    'Post Collection: Coming soon': 'Anschließende Abholung: Kommt bald',

    permit_revenue_sources_explanation:
        'Die Höhe der Einnahmen in Kronen, die für jede Organisation aus Genehmigungen erzielt wurden.',
    number_of_permits_per_parking_operator_explained:
        'Die Anzahl der Genehmigungen, die von jedem der Parkbetreiber erstellt wurden.',

    fine_status_distribution_explanation:
        'Die prozentuale Verteilung der Bußgeldstatus.',

    // Statistic
    fee_search: 'Alte App',
    fine_search: 'App - Strafe erstellt',
    permit_search: 'App - Genehmigung erstellt',
    scanner_used: 'App - Scanner verwendet',
    'Shows a graph of app events for multiple days, sorted by hours.':
        'Zeigt ein Diagramm der App-Ereignisse für mehrere Tage, sortiert nach Stunden.',
    'Shows a graph of app events, sorted by dates.':
        'Zeigt ein Diagramm der App-Ereignisse, sortiert nach Daten.',
    'The use of the old app.': '- Die Verwendung der alten App.',
    'Creation of permits in the oparko app.':
        '- Erstellung von Genehmigungen in der oparko-App.',
    'Creation of Fines in the oparko app':
        '- Erstellung von Strafen in der oparko-App.',
    'Scanning of license plates in the oparko app.':
        '- Scannen von Nummernschildern in der oparko-App.',
    'Permits and Subscriptions per month':
        'Genehmigungen und Abonnements pro Monat',
    'Occupancy by permits': 'Belegung nach Genehmigungen',
    'Total parking spots': 'Gesamtzahl der Parkplätze',
    'Permits: Shows the amount of permits by period. If more than one week is chosen, the sum of all weeks will be shown on a give period on a weekday.':
        'Erlaubnisse: Zeigt die Anzahl der Erlaubnisse pro Zeitraum an. Wenn mehr als eine Woche ausgewählt wird, wird die Summe aller Wochen für einen bestimmten Zeitraum an einem Wochentag angezeigt.',
    'Total parking spots: Shows the total amount of parking spots':
        'Gesamte Parkplätze: Zeigt die Gesamtanzahl der Parkplätze',
    'Lowest avg for the days in the period.':
        '- Niedrigster Durchschnitt für die Tage im Zeitraum.',
    'Highest avg for the days in the period.':
        '- Höchster Durchschnitt für die Tage im Zeitraum.',
    'Avg for the days in the period.':
        '- Durchschnitt für die Tage im Zeitraum.',
    'Average for the chosen period': 'Durchschnitt für den gewählten Zeitraum',
    // Date Picker
    Between: 'Dazwischen',
    From: 'Von',
    To: 'Bis',
    Unit: 'Einheit',
    Max: 'Maximale Anzahl',
    Min: 'Mindestanzahl',
    Prior: 'Vor',
    Custom: 'Anpassen',
    Today: 'Heute',
    'Since Yesterday': 'Ab gestern',
    'This Week': 'Diese Woche',
    Anytime: 'Jederzeit',
    'Last month': 'Letzter Monat',
    'Last 3 months': 'Letzte 3 Monate',
    'Last 12 months': 'Letzte 12 Monate',

    'Organization type': 'Organisationstyp',
    Ticket_Rule: 'Ticketregel',
    Rule_sets: 'Regelsätze',
    InformationGuide: 'Informationsleitfaden',
    RuleSetsDescription:
        "Unter 'Regelsätze' können Sie einzigartige Ticketregeln definieren. Sie können so viele erstellen, wie Sie benötigen:",
    PickDays: 'Wählen Sie, an welchen Tagen die Regeln gelten sollen',
    PickTime: 'Wählen Sie die Tageszeit',
    PickUnit:
        'Wählen Sie die Einheit gefolgt von der „minimale“ und „maximale“ Anzahl Stunden/Tage, die mit dieser Regel gekauft werden können',

    // Table Values
    company: 'gesellschaft',
    private: 'privat',
    Inactive: 'Inaktiv',
    Active: 'Aktiv',
    Paid: 'Bezahlt',
    Settled: 'Erledigt',
    Hidden: 'Aufgehoben',
    Protected: 'Geschützt',
    'Guest parking': 'Gastparkplatz',
    Ticket: 'Fahrkarte',
    'Paid Ticket': 'Bezahlt Fahrkarte',
    'Only for backoffice': 'Nur für Backoffice',
    'Editable permit': 'Bearbeitbare Genehmigung',
    Subscription: 'Abonnement',
    Employee: 'Angestellter',
    'Permanent permit': 'Dauerhafte Genehmigung',
    'Debt collection': 'Schuldeneintreibung',
    Collectia: 'Collectia',
    'Active - handled manually': 'Actief - handmatig afgehandeld',
    'Active - System': 'Actief - Systeem',
    // Validation
    'Issues: ': `Ausgaben: `,

    'no Name chosen, ': 'kein Name gewählt, ',
    'no Organization chosen, ': 'keine Organisation ausgewählt, ',
    'no Street Name chosen, ': 'kein Straßenname gewählt, ',
    'no Street Number chosen, ': 'keine Hausnummer gewählt, ',
    'no Postal Code chosen, ': 'no Postal Code chosen, ',
    'no City chosen, ': 'keine Stadt ausgewählt, ',
    'no Latitude chosen, ': 'Kein Breitengrad ausgewählt, ',
    'Latitude must be above -90, ': 'Der Breitengrad muss über -90 liegen, ',
    'Latitude must be below 90, ': 'Der Breitengrad muss unter 90 liegen, ',
    'no Longitude chosen, ': 'kein Längengrad ausgewählt, ',
    'Longitude must be above -90, ': 'Der Längengrad muss über -90 liegen, ',
    'Longitude must be below 90, ': 'Der Längengrad muss unter 90 liegen, ',
    'no Type chosen, ': 'kein Typ ausgewählt, ',
    'parking terms do not accept more than 255 character':
        'Parkbedingungen akzeptieren nicht mehr als 255 Zeichen',

    Reset: 'Zurücksetzen',
    'Accept our terms': 'Akzeptieren Sie unsere Bedingungen',
    'Enter your registration no.': 'Geben Sie Ihre registrierungsnummer',
    'phone number': 'Telefonnummer',
    email: 'email',
    'then press ok': 'und dann auf schaffen klicken',
    'Your permit ends': 'Ihre Erlaubnis endet',
    'by today 23:59': 'bis heute 23:59',
    after: 'nach',
    'after the creation': 'nach der Erstellung',
    minutes: 'Minuten',
    and: 'und',
    until: 'am',
    'ends in': 'in ',

    'Reg. no.': 'Reg.-Nr. nein.',
    'Phone number': 'Telefonnummer',
    Subscription_percentage_not_within_range: `Abonnement '% für PK' muss im Bereich von 0% bis 100% liegen`,
    Permit_percentage_not_within_range: `Erlauben '% für PK' muss im Bereich von 0% bis 100% liegen`,
    Camera_control_percentage_not_within_range: `Kamerakontrolle '% für PK' muss im Bereich von 0% bis 100% liegen`,
    Selfticketing_percentage_not_within_range: `Selbstticket '% für PK' muss im Bereich von 0% bis 100% liegen`,
    Guard_control_percentage_not_within_range: `Schutzkontrolle '% für PK' muss im Bereich von 0% bis 100% liegen`,

    // Ticket Service validation
    'must be a positive integer': 'muss eine positive ganze Zahl sein',
    'Reg. no. have not been specified': 'Reg. nr. wurden nicht angegeben',
    'E-mail has not been specified': 'E-mail wurde nicht angegeben',
    'Terms and conditions must be accepted': 'Die AGB müssen akzeptiert werden',
    Issues: 'Probleme',

    // Terms
    Terms_titles: 'Geschäftsbedingungen',
    Terms_para_1:
        'Wir speichern und übertragen Benutzerinformationen verschlüsselt. Die eingegebenen personenbezogenen Daten werden wie in unserer Datenrichtlinie beschrieben registriert. Indem Sie diese Allgemeinen Geschäftsbedingungen akzeptieren, stimmen Sie auch zu, mit diesen Allgemeinen Geschäftsbedingungen für die Nutzung der Produkte des Parkunternehmens vertraut zu sein und diese akzeptiert zu haben. Sonderregelungen zum Oparko-Abo-Parken siehe unten unter „Oparko-Abo-Parken“. Das Parkunternehmen kann Informationen sammeln und Statistiken und Berichte erstellen, um das beste Produkt und den besten Service für den Benutzer zu verbessern und bereitzustellen.',
    Terms_main_point_1: {
        main_title: 'Anwendung',
        sub_point_1:
            'Diese Allgemeinen Geschäftsbedingungen (die „Allgemeinen Geschäftsbedingungen“) gelten für die Erbringung von Dienstleistungen von ParkeringskompagnietApS, CVR Nr. 37127385, Trindsøvej 4, 8200 Aarhus, („Parkeringskompagniet“), für Benutzer („Benutzer“) in Dänemark.',
        sub_point_2:
            'HINWEIS! Die Nutzung der Dienste des Parkunternehmens setzt voraus, dass Sie ein registrierter Eigentümer oder Benutzer des Fahrzeugs sind oder dass Sie anderweitig die Erlaubnis haben, das Fahrzeug zu nutzen und zu registrieren.',
        sub_point_3: {
            sub_point_3_title:
                'Alle Dienste werden in Übereinstimmung mit geltendem Recht und in Übereinstimmung mit bereitgestellt.',
            sub_point_3_1:
                'etwaige individuell vereinbarte Geschäftsbedingungen und',
            sub_point_3_2: 'diese Allgemeinen Geschäftsbedingungen.',
            sub_point_3_3:
                'Bei Widersprüchen zwischen den vorstehenden Bestimmungen gehen diese in der vorstehenden Reihenfolge vor.',
        },
        sub_point_4:
            'Die Ziffern 5.1.4, 6.4 und 7.7 enthalten besondere Bestimmungen zur korrekten und gültigen Eingabe der Registrierungsnummer und Vorwahl.',
        sub_point_5:
            'Punkt 5.3. enthält besondere Bestimmungen zur Nutzung der Oparko-Gebühr.',
        sub_point_6:
            'Mit der Registrierung bei Oparko (siehe Punkt 4) erklärt sich der Nutzer mit diesen Allgemeinen Geschäftsbedingungen einverstanden. Eine verbindliche Vereinbarung („die Vereinbarung“) kommt zustande, wenn Parkeringskompagniet die Registrierung des Benutzers bestätigt hat und der Benutzer Zugang zu den Diensten des Parkunternehmens erhält.',
        sub_point_7:
            'Diese Allgemeinen Geschäftsbedingungen bilden den Vertrag zwischen dem Parkunternehmen und dem Benutzer.',
        sub_point_8:
            'Diese Allgemeinen Geschäftsbedingungen gelten für alle Transaktionen und Registrierungen über Parkeringskompagniet, einschließlich *service2* und Oparko Abonnementparkering.',
    },
    Terms_main_point_2: {
        main_title: 'Definitionen',
        sub_point_1: {
            sub_point_1_title:
                'In diesen Allgemeinen Geschäftsbedingungen und im Zusammenhang mit den Diensten gelten die folgenden Definitionen:',
            sub_point_1_1:
                'Mit „Oparko“ ist der digitale Parkdienst gemeint, der in Verbindung mit der Bezahlung des Parkens über die App und die Website genutzt werden kann;',
            sub_point_1_2:
                '„App“ bezeichnet die Mobiltelefonanwendung des Parkunternehmens;',
            sub_point_1_3:
                '„Oparko-System“ ist das digitale System von Oparko für zeitgemäßes Parken, auf das der Nutzer über die App und/oder über die Website Zugriff erhält;',
            sub_point_1_4:
                '„Home“ bezieht sich auf die Website der Parkgesellschaft <a class="text-pk-blue" href="https://parkeringskompagniet.dk/">https://parkeringskompagniet.dk/</a>.',
            sub_point_1_5:
                '„Parkservice“ ist die Möglichkeit des Nutzers, Parkplätze in der App zu verwalten;',
            sub_point_1_6:
                'Ein „Parkplatz“ ist eine physische Fläche, die einem auf der Übersichtskarte markierten Parkplatz entspricht (ein Parkplatz bedeutet nicht eine markierte Parkbox);',
            sub_point_1_7:
                '„Parteien“ und „Partei“ bezeichnet Parkeringskompagniet und/oder den Benutzer;',
            sub_point_1_8:
                '„Benutzer“ wird in diesen Allgemeinen Geschäftsbedingungen für den Parkplatz verwendet, der Parkeringskompagniet im Zusammenhang mit der Bezahlung des Parkens nutzt.',
            sub_point_1_9:
                '„Partner“ bedeutet Partner, mit denen Parkeringskompagnietzusammenarbeitet;',
            sub_point_1_10:
                '„Dienst(e)“ hat die in Ziffer 3.1.1 festgelegte Bedeutung.',
            sub_point_1_11: 'Die „Parkgebühr“ ist der Preis für das Parken.',
            sub_point_1_12:
                '„Aktives Fahrzeug“ bezeichnet ein Fahrzeug, für das der Nutzer im Zusammenhang mit dem Parken auf sogenannten ANPR-Parkplätzen automatisch bezahlen möchte. Die Zahlung erfolgt über die Zahlungsmethode, die der Nutzer als bevorzugt angegeben hat.',
            sub_point_1_13:
                '„ANPR“ steht für Automatic Number Plate Registration, was bedeutet, dass das Kennzeichen des Fahrzeugs automatisch gelesen und das Kennzeichen im Parksystem registriert wird. Parkplätze mit ANPR-Systemen sind in Oparko mit einer Kamera markiert und aus der Beschilderung auf dem Parkplatz ist ersichtlich, dass das Nummernschild gelesen werden kann.',
            sub_point_1_14:
                'Mit „Oparko Charge“ sind die eigenen und zugehörigen Systeme und Apps des Parkunternehmens von Drittanbietern gemeint, die in Verbindung mit dem Aufladen von Elektro- und Hybridautos verwendet werden können. Das sind sowohl Ladestationen, Bezahlsysteme als auch Apps.',
        },
        sub_point_2:
            'Definitionen können an anderer Stelle in diesen Allgemeinen Geschäftsbedingungen enthalten sein.',
    },
    Terms_main_point_3: {
        main_title: 'Allgemeine Geschäftsbedingungen für die Dienste',
        sub_point_1: {
            sub_point_1_title: 'Im Algemeinen',
            sub_point_1_1:
                'The Parking Company bietet ein digitales System zum Parken ("Oparko") an, wodurch das Parking Company (in Zusammenarbeit mit anderen Partnern) dem Benutzer ermöglicht, das Parken von Fahrzeugen ("Parking Service") und das Aufladen von Elektrofahrzeugen zu verwalten. Der Parkservice und die anderen Dienstleistungen, die jederzeit vom Parkunternehmen erbracht werden, werden zusammenfassend als „Services“ bezeichnet.',
        },
        sub_point_2:
            'Eine detaillierte Beschreibung der Dienste finden Sie jederzeit auf der Website und in den bereitgestellten Informationen <a href="https://parkeringskompagniet.dk/" class="text-pk-blue">https://parkeringskompagniet.dk/.</a>',
    },
    Terms_main_point_4_new: {
        main_title: 'Erhebung und Verarbeitung personenbezogener Daten',
        sub_point_1: {
            sub_point_title:
                'Erhebung und Verarbeitung personenbezogener Daten',
            sub_point_1_1:
                'Wenn der Benutzer die Dienste des Parkunternehmens nutzt, stimmt der Benutzer gleichzeitig zu, dass Parkeringskompagniet die personenbezogenen Daten und die vom Benutzer bereitgestellten personenbezogenen Daten gemäß der geltenden Datenrichtlinie des Parkunternehmens sammelt und verarbeitet (<a href="https://parkeringskompagniet.dk/datapolitik/" class="text-pk-blue">https://parkeringskompagniet.dk/datapolitik/</a>).',
            sub_point_1_2:
                'Personenbezogene Daten werden nur gespeichert, wenn sie freiwillig angegeben werden und nur so lange, wie sie für Parkeringskompagniet relevant sind. Die betreffenden Daten werden im internen Netzwerk des Parkunternehmens gespeichert und sind nur mit besonderen Rechten zugänglich.',
        },
        sub_point_2: {
            sub_point_title: 'Rechte des Benutzers',
            sub_point_2_1:
                'Der Nutzer hat das Recht, Einsicht in die bei Parkeringskompagnietüber ihn gespeicherten personenbezogenen Daten zu erhalten und die betreffenden Informationen berichtigen zu lassen.',
            sub_point_2_2:
                'Der Benutzer hat das Recht, seine Daten aus den Systemen des Parkunternehmens löschen zu lassen.',
            sub_point_2_3:
                'Der Benutzer hat auch die Rechte, die Einzelpersonen durch die Datenschutz-Grundverordnung der Europäischen Union gewährt werden.',
        },
        sub_point_3: {
            sub_point_title: 'Pflichten des Benutzers',
            sub_point_3_1:
                'Wenn der Benutzer Informationen über eine andere Person bereitstellt, garantiert der Benutzer, dass diese Person das Recht hat, die Informationen an Parkeringskompagniet weiterzugeben.',
        },
        sub_point_4: {
            sub_point_title: 'Übertragung von Daten',
            sub_point_4_1:
                'Der Benutzer akzeptiert, dass, wenn das Parkhaus es für notwendig erachtet, die Informationen des Benutzers auf eine andere Plattform zu verschieben, die mit demselben Zweck wie in dieser Vereinbarung erstellt wurde, dies ohne vorherige Benachrichtigung und in Übereinstimmung mit den geltenden datenschutzrechtlichen Bestimmungen erfolgt.',
        },
        sub_point_5: {
            sub_point_title: 'Offenlegung von Informationen',
            sub_point_5_1:
                'Die Informationen des Benutzers werden nur weitergegeben, wenn dies für die von Parkeringskompagnieterbrachten Dienstleistungen erforderlich ist.',
        },
    },
    Terms_main_point_5_new: {
        main_title: 'Besondere Geschäftsbedingungen für die Nutzung von Oparko',
        sub_point_1: {
            sub_point_1_title: 'Nutzungsbedingungen',
            sub_point_1_1: {
                sub_point_1_1_title:
                    'Der Parkservice kann nur auf Parkplätzen und in Parkbereichen/-zonen (jeweils ein „Parkplatz“) genutzt werden, die:',
                sub_point_1_1_1:
                    'wo die Beschilderung des Parkunternehmens angebracht ist,',
                sub_point_1_1_2:
                    'und Verkaufsautomaten mit Oparko-Aufklebern haben oder anderweitig Oparko-Marken tragen, die darauf hinweisen, dass Oparko verwendet werden kann.',
            },
            sub_point_1_2:
                'Wenn der Parkdienst verwendet wird, muss der Benutzer ein Parken einleiten, indem er entweder aktiviert; Die App; oder ein angeschlossenes und zugelassenes Zahlungssystem',
            sub_point_1_3:
                'Um sicherzustellen, dass das Parken korrekt begonnen hat, muss der Nutzer überprüfen, ob eine Bestätigung des korrekten und gültigen Parkbeginns über empfangen wurde',
            sub_point_1_3_1: 'Die App, sofern die App genutzt wird; oder',
            sub_point_1_3_2: 'Email Bestätigung',
            sub_point_1_4:
                'Ein Parken ist nur gültig, wenn die App das Parken bestätigt hat und ein laufendes korrekt registriertes Parken/Bezahlen anzeigt',
            sub_point_1_5:
                'Bei der Nutzung des Parkservices hat der Nutzer in diesem Zusammenhang das Kennzeichen des geparkten Fahrzeugs sowie die Vorwahl des betreffenden Parkplatzes korrekt einzugeben.',
            sub_point_1_6:
                'Der Benutzer ist dafür verantwortlich, dass das Kennzeichen des Fahrzeugs und die Vorwahl korrekt eingegeben werden. Fehler bei der Anmeldung und Eintragung führen zu einer Kontrollgebühr.',
            sub_point_1_7:
                'Der Benutzer muss sicherstellen, dass die Vorwahl mit der Vorwahl des betreffenden Parkplatzes oder der E-Ladestation übereinstimmt, die auf der Oparko-Beschilderung auf dem Parkplatz oder in der Nähe der Automaten/E-Ladestationen des Parkplatzes erscheint. Der Benutzer ist für die Eingabe der richtigen Vorwahl und Registrierungsnummer verantwortlich. Oparko übernimmt keine Verantwortung im Zusammenhang mit falsch eingegebenen Registrierungsnummern und/oder Vorwahlen.',
            sub_point_1_8:
                'Beim Parken auf Parkplätzen, auf denen das Kennzeichen des Fahrzeugs gelesen wird, darf die Vorwahl nicht eingegeben werden. Das zum Parken verwendete Kennzeichen muss korrekt eingegeben werden.',
            sub_point_1_9:
                'Gültigkeitsvoraussetzung für die Parkregistrierung ist die korrekte Eingabe der Registrierungsnummer und der Vorwahl.',
            sub_point_1_10: {
                sub_point_1_10_title:
                    'Die Registrierung des Parkens in Oparko kann auf eine der folgenden Arten erfolgen:',
                sub_point_1_10_1:
                    'Bei der manuellen Registrierung muss der Nutzer die gewünschte Parkzeit angeben und wird in diesem Zusammenhang über den Preis für den gewünschten Parkplatz informiert. Nach der Bestätigung der eingegebenen Registrierung durch den Benutzer wird der angegebene Betrag reserviert. Wenn der Benutzer das Parken beendet, wird der reservierte Betrag geändert, sodass nur die registrierte Parkzeit bezahlt wird.',
                sub_point_1_10_2: {
                    sub_point_1_10_2_title:
                        'Bei automatischer Registrierung (beim Parken auf ANPR-Parkplätzen) muss der Nutzer das Fahrzeug vor dem Parken unter „Fahrzeuge“ aktiviert haben. Beim Parken auf einem ANPR-Parkplatz beginnt das Parken an der Einfahrt und endet und wird an der Ausfahrt abgerechnet. Es wird für die genutzte Parkzeit bezahlt.',
                    sub_point_1_10_2_1:
                        'Mit der Aktivierung des Fahrzeugs erklärt sich der Nutzer damit einverstanden, dass beim Parken auf einem ANPR-Parkplatz eine automatische Zahlung über die bevorzugte Zahlungsmethode erfolgt.',
                },
            },
            sub_point_1_11:
                'Oparko kann nicht für die Genauigkeit der GPS-Position des Telefons des Benutzers verantwortlich gemacht werden, noch kann Oparko für die Mobilfunkabdeckung/das Signal im/auf dem Parkplatz verantwortlich gemacht werden. Der Benutzer ist dafür verantwortlich, dass die angegebene Position der Position des geparkten Fahrzeugs des Benutzers entspricht.',
            sub_point_1_12:
                'Vom Nutzer vorgenommene Parkinformationen werden dem Nutzer über „Parken“ zur Verfügung gestellt, und diese Informationen sind in der Regel für mindestens 12 Monate verfügbar.',
        },
        sub_point_2: {
            sub_point_1_title: 'Im Algemeinen',
            sub_point_1_1: {
                sub_point_1_1_title:
                    'Der Parkservice ermöglicht es dem Nutzer, einen Parkvorgang zu starten und beim manuellen Start die vorläufige Parkzeit festzulegen, den begonnenen Parkvorgang zu stoppen und zu beenden. Darüber hinaus ist es dem Nutzer möglich, die vorläufig festgelegte Parkzeit zu verlängern.',
                sub_point_1_2_1:
                    'Erinnerung: Es ist möglich, vor Ablauf des manuell initiierten Parkens eine Erinnerung zu erhalten, sofern in der App Push-Benachrichtigungsberechtigungen erteilt wurden. Es liegt in der Verantwortung des Benutzers sicherzustellen, dass das Parken korrekt abgeschlossen wird, unabhängig davon, ob die Erinnerung nicht erscheint.',
                sub_point_1_2_2: {
                    sub_point_1_2_2_title:
                        'Parkstopp: Wenn Sie das Parkhaus vor Ablauf der gewählten Parkzeit verlassen, besteht die Möglichkeit, das laufende Parken aktiv zu beenden, wobei Sie nur für den Zeitraum bezahlen, in dem das Parken aktiv war. Dem Benutzer wird empfohlen, immer auf den Parkpreis zu achten, der auf der Beschilderung und/oder dem Zahlungsautomaten angegeben ist. Abhängig von der Preisstruktur des Parkhauses wird es in den meisten Parkhäusern möglich sein, das Parken zu beenden, damit Sie nicht für "nicht genutzte Zeit" bezahlen. ACHTUNG! Einige Parkplätze sind kostenpflichtig angefangener Zeitraum und evtl im Voraus, was bedeutet, dass im Falle eines Stopps eines solchen Parkens der vorab genehmigte Betrag unabhängig vom Stopp berechnet wird. Wenn das Parken nicht gestoppt wird, wird die Zahlung für „überhöhte Zeit“ nicht zurückerstattet.',
                    sub_point_1_2_2_1:
                        'Als Überzeit ist der Zeitraum zu verstehen, in dem eine gültige Parkregistrierung auf dem Parkplatz vorliegt, das Fahrzeug jedoch nicht auf dem Parkplatz gestanden hat.',
                },
                sub_point_1_2_3:
                    'Verlängerung: Die Verlängerung eines Parkplatzes erfolgt durch Drücken auf „Verlängern“ und anschließender Angabe der neuen Endzeit für den Parkplatz, dann wird der Betrag genehmigt. Danach wird der Countdown der Parkzeit für das Fahrzeug basierend auf der verlängerten Parkzeit aktualisiert. Verlängerung bedeutet, dass ein neues Parken eingeleitet wird, wenn das ursprüngliche Parken abläuft.',
                sub_point_1_2_4:
                    'Wenn es zu einem bestimmten Zeitpunkt nicht möglich ist, einen Kauf über die Oparko-Software zu tätigen, ist der Benutzer dafür verantwortlich, das Parken auf andere Weise zu bezahlen, z. B. durch Kauf eines gültigen P-Tickets an einem Kassenautomaten und unter Beachtung der ausgehängten Parkordnung. Gründe, warum Oparko nicht verwendet werden kann, sind unter anderem: schlechter/kein Internetzugang, Serverausfall aufgrund von Wartungsarbeiten, Updates usw.',
            },
            sub_point_1_2:
                'Wenn der Nutzer zum Zeitpunkt des Parkens nicht der Fahrer des Fahrzeugs war, aber der Nutzer das Fahrzeug als aktiv gekennzeichnet hat, hat der Nutzer akzeptiert, dass die Zahlung automatisch über die bevorzugte Zahlungsmethode erfolgt. Die interne Beziehung zwischen dem Benutzer und Dritten liegt außerhalb der Kontrolle von Oparko.',
            sub_point_1_3: {
                sub_point_1_3_title:
                    'Wenn für das geparkte Fahrzeug eine Kontrollgebühr erhoben wird, weil das Parken nicht den Regeln und Bedingungen entspricht, die sich aus der Beschilderung auf dem Parkplatz ergeben, ist der Fahrer zur Zahlung einer solchen Kontrollgebühr verpflichtet, unabhängig davon, wer die Zahlung für das Parken geleistet hat.',
                sub_point_1_3_1:
                    '„Kontrollgebühr“ wird in diesem Zusammenhang jeweils als Sammelbegriff verwendet Inspektionsgebühr, Inspektionsgebühr, Parkstrafe usw.',
            },
            sub_point_1_4:
                'Für den Fall, dass das Parken mit einem Fahrzeug durchgeführt wird, dessen registrierter Eigentümer/Nutzer ein Unternehmen ist, und das Fahrzeug kontrollgebührenpflichtig ist, liegt es in der Verantwortung des Nutzers, angemessen zu dokumentieren, dass das Parken nicht geschäftlich erfolgt ist Zwecke. Kann der Benutzer dieser Beweislast nicht nachkommen, gilt der registrierte Eigentümer/Benutzer als richtiger Schuldner und in diesem Fall wird im Falle einer verspäteten Zahlung zusätzlich zu etwaigen Mahnungen ein Schadensersatzbetrag von DKK 310,00 in Rechnung gestellt gemäß Anspruch Nr. 105 vom 31.01.2013 § 2, Abs 1.',
            sub_point_1_5:
                'War der Nutzer zum Zeitpunkt des Parkens nicht der Fahrer des Fahrzeugs, stimmt der Nutzer hiermit zu, den Fahrer offenlegen zu müssen. Bei unvollständigen oder unrichtigen Angaben wird die Prüfgebühr, sowie ggf Mahngebühren gehen vollständig zulasten des Nutzers.',
            sub_point_1_6:
                'Die Zahlung der entsprechenden Parkgebühr (einschließlich Mehrwertsteuer) durch den Benutzer wird auf der Grundlage der geltenden Tarife (die "Parkgebühr") in Verbindung mit der Beendigung eines Parkplatzes durch den Benutzer oder alternativ dem Beginn einer festen Parkzeit durch den Parkdienst bestimmt.',
            sub_point_1_7:
                'Der Nutzer kann aufgrund der erfolgten Zahlung in Oparko keinen Anspruch auf Minderung der Prüfungsgebühr geltend machen. Diese Bedingung gilt unabhängig davon, ob die Inspektionsgebühr aufgrund der Fehlregistrierung des Benutzers bei Oparko oder des Parkens des Benutzers unter Verstoß gegen die anderen ausgehängten Regeln und Bedingungen auf dem Parkplatz erhoben wird.',
            sub_point_1_8:
                'Der Nutzer hat auch keinen Anspruch auf Rückerstattung einer über Oparko geleisteten Zahlung, es sei denn, die Belastung beruht auf einem Fehler von Oparko, den der Nutzer nicht hatte oder beeinflussen konnte.',
        },
        sub_point_3: {
            sub_point_1_title: 'Oparko-Gebührenbedingungen',
            sub_point_1_1:
                'Die Bezahlung des Fahrzeugladens kann über Apps in Bezug auf die auf dem Gelände eingerichteten Ladestationen erfolgen. Die Parkgebühr muss separat über Oparko bezahlt werden (siehe Punkt 5).',
            sub_point_1_2:
                'HINWEIS: Beim Parken in für Elektrofahrzeuge reservierten Parkbuchten während des Ladevorgangs muss der Ladevorgang vor Beginn des Parkens und Ladens in der verwendeten App registriert werden. Darüber hinaus muss das Parken in der Oparko-App registriert werden, wie in Punkt 5 angegeben.',
            sub_point_1_3:
                'Das Laden über installierte Ladesäulen muss über die verwendete App bezahlt werden.',
            sub_point_1_4:
                'Bei der Nutzung von Oparko im Zusammenhang mit der Nutzung einer installierten Ladesäule teilt der Nutzer dem Parkunternehmen den Ladebeginn des geparkten Fahrzeugs, die Vorladezeit, den Ladeabschluss und ggf. eine verlängerte Ladezeit mit.',
            sub_point_1_6: {
                sub_point_1_6_title:
                    'Bei Bezahlung und Nutzung einer installierten Ladesäule wird eine Ladegebühr entrichtet.',
                sub_point_1_6_1: 'Die Ladegebühr ist variabel.',
                sub_point_1_6_2:
                    'Die Zahlung der Ladegebühr erfolgt separat und die Anforderung erscheint in der verwendeten App, bevor die Registrierung und Zahlung abgeschlossen sind.',
            },
            sub_point_1_7:
                'Bei der Registrierung gelten zusätzlich die sonstigen Bedingungen zur Nutzung der Oparko-App, unter anderem, dass eine korrekte und gültige Registrierung und Bezahlung die korrekte Eingabe der Vorwahl und des Kennzeichens des geparkten Fahrzeugs voraussetzen. Es liegt in der Verantwortung des Benutzers, dass die eingegebenen Informationen korrekt und nachprüfbar sind und die Zahlung abgeschlossen werden kann.',
            sub_point_1_8: {
                sub_point_1_8_title:
                    'Die für die Bezahlung von Lade- und Parkgebühren zu verwendende Vorwahl ist aus der Beschilderung vor den für Elektrofahrzeuge reservierten Ständen ersichtlich. Es liegt in der Verantwortung des Benutzers, die richtige Vorwahl einzugeben.',
                sub_point_1_8_1:
                    'Bei Zahlung an eine Vorwahl, die einer Oparko-Charge-Ladestation zugeordnet ist, aber ohne Nutzung eines für Oparko-Charge reservierten Parkplatzes, erfolgt keine Rückerstattung der Zahlung.',
            },
            sub_point_1_9: {
                sub_point_1_9_title:
                    'Es liegt in der Verantwortung des Benutzers, jederzeit die ausgeschilderten Regeln und Bedingungen zu beachten, die auf dem Parkplatz angebracht sind. Bei Widersprüchen zwischen der Beschilderung und diesen AGB ist der Wortlaut der Beschilderung maßgebend. Wird das Parken nicht gemäß den ausgehängten Regeln und Bedingungen durchgeführt, kann eine Kontrollgebühr erhoben werden, die nicht nachträglich storniert werden kann.',
                sub_point_1_9_1:
                    'Das Parken in für Elektrofahrzeuge reservierten Stellplätzen kann nur gegen Entgelt erfolgen und das Fahrzeug muss für die gesamte Dauer der Nutzung des Stellplatzes aufgeladen werden. Wenn das Fahrzeug nicht aufgeladen und/oder der Parkplatz nicht in Oparko registriert ist, wird eine Kontrollgebühr erhoben, die nicht storniert werden kann.',
                sub_point_1_9_2:
                    'Treten Probleme im Zusammenhang mit der Nutzung der Ladestation Oparko-Charge auf, muss sich der Nutzer unter der auf der Ladestation angegebenen Telefonnummer an Parkeringskompagniet wenden.',
            },
            sub_point_1_10:
                'Informationen, die der Benutzer im Zusammenhang mit der Registrierung und Zahlung registriert hat, erfolgen auf die gleiche Weise wie im Abschnitt erwähnt 4.',
        },
    },
    Terms_main_point_6: {
        main_title:
            'Zugriff auf die Dienste, Registrierung von Informationen usw.',
        main_text:
            'Der Zugriff auf die Dienste, die Erstellung und die Bezahlung des Parkens über Oparko kann erfolgen, indem der Benutzer als Benutzer erstellt wird. Die Nutzung von Oparko setzt voraus, dass ein gültiges Zahlungsmittel mit der Oparko-App des Benutzers verknüpft ist. Die Zahlungsmittel, die der Benutzer gültig als Zahlungsmittel zuordnen kann, sind: Bankkarte Gültigkeitsvoraussetzung ist die Deckung des zugehörigen und gewählten Zahlungsmittels.',
        sub_point_1: {
            sub_point_1_title:
                'Bei der Erstellung und späteren Nutzung von Oparko ist es Voraussetzung, dass der Nutzer korrekte und gültige Angaben macht:',
            sub_point_1_1: {
                sub_point_1_1_1: 'Name',
                sub_point_1_1_2: 'E-Mail-Addresse',
                sub_point_1_1_3: 'Handy Nummer',
                sub_point_1_1_4: 'Passwort',
                sub_point_1_1_5:
                    'Zulassungsnummer, auf mindestens einem Fahrzeug',
                sub_point_1_1_6: {
                    sub_point_1_1_6_title: 'Zahlungsmittel',
                    sub_point_1_1_6_1: {
                        sub_point_1_1_6_1_title:
                            'Gruppen von Mitarbeitern mit Zugriff auf die registrierten Informationen:',
                        sub_point_1_1_6_1_1:
                            'Subunternehmer (während der Durchführung des Supports)',
                        sub_point_1_1_6_1_2:
                            'Mitarbeiter des Parkunternehmens (bei der Unterstützung im Zusammenhang mit Benutzeranfragen)',
                    },
                },
            },
        },
        sub_point_2:
            'Ein Parken wird per App oder eingeleitet <a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk.</a>',
        sub_point_3: {
            sub_point_3_title:
                'Um Oparko nutzen zu können, muss eine gültige Zahlungsmethode verbunden und in der App oder auf ausgewählt werden <a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk.</a>.',
            sub_point_1_1: {
                sub_point_1_1_1:
                    'HINWEIS: Ein Wechsel des Zahlungsmittels im Zusammenhang mit einem laufenden Parkvorgang ist nicht möglich, weshalb es in der Verantwortung des Nutzers liegt, dass vor dem Parken das richtige und gültige Zahlungsmittel ausgewählt wird.',
            },
        },
        sub_point_4: {
            sub_point_4_title:
                'Die Anweisungen, die auf dem Bildschirm erscheinen, müssen befolgt werden:',
            sub_point_1_1: {
                sub_point_1_1_1:
                    'Damit der Nutzer Oparko zum Bezahlen des Parkens nutzen kann, ist es eine Gültigkeitsbedingung, dass der Nutzer eine korrekte und gültige Registrierungsnummer (Nummernschild), Vorwahl, Zahlungsmittel und Parkzeit registriert.',
                sub_point_1_1_2:
                    'Es muss überprüft werden, ob die registrierte Registrierungsnummer und Vorwahl vom Benutzer korrekt angegeben wurden.',
                sub_point_1_1_3:
                    'Oparko behält sich das Recht vor, einen Antrag auf Erstellung und Bezahlung von Parkplätzen abzulehnen.',
                sub_point_1_1_4:
                    'Es wird klargestellt, dass eine gültige Zahlung für den Parkplatz nur dann erfolgt ist, wenn das Kennzeichen des geparkten Fahrzeugs korrekt eingegeben wurde und die eingegebene Vorwahl mit der Vorwahl übereinstimmt, die auf der für den betreffenden Parkplatz geltenden Oparko-Beschilderung erscheint , wo sich das Fahrzeug befindet.',
            },
        },
        sub_point_5:
            'Wenn der Parkplatz nicht gültig registriert ist, kann das Fahrzeug mit einer Kontrollgebühr belastet werden.',
        sub_point_6:
            'Wenn der Parkplatz nicht gültig registriert ist, kann das Fahrzeug mit einer Kontrollgebühr belastet werden.',
        sub_point_7:
            'Beim Kauf über Oparko wird auf dem ausgewählten Parkplatz/Parkplatz und im ausgewählten Zeitraum ein unbestimmter Parkstand zur Verfügung gestellt. Das Parkunternehmen kann nicht haftbar gemacht werden, wenn keine Parkplätze verfügbar sind (da dies keine Buchung ist). Der Parkplatz sollte daher nicht gekauft werden, bevor das Fahrzeug ordnungsgemäß auf dem Parkplatz abgestellt ist.',
        sub_point_8:
            'Der Nutzer hat keinen Anspruch auf Erstattung nicht genutzter Parkplätze. Siehe Punkt 5.2.1.2.',
    },
    Terms_main_point_7: {
        main_title: 'Pflichten und Verantwortlichkeiten des Benutzers',
        sub_point_1: {
            sub_point_1_1: `Der Benutzer ist jederzeit dafür verantwortlich, korrekte Informationen über den Benutzer bereitzustellen und dass relevante Fahrzeuge korrekt bei Oparko registriert sind. Der Benutzer muss sich unmittelbar nach der Erstellung/Registrierung bei „Mein Konto“ anmelden, um sicherzustellen, dass die registrierten Informationen korrekt sind. Oparko kann unabhängig von der Registrierungsmethode niemals für falsche Registrierungen/Einträge verantwortlich sein oder werden.`,
            sub_point_1_2: `Der Nutzer ist dafür verantwortlich, dass die über Oparko registrierte und genutzte Zahlungsmethode nicht gesperrt, gültig und eine ausreichende Deckung der registrierten Zahlungsmethode vorhanden ist. Nach Ablauf eines registrierten Zahlungsmittels muss der Nutzer Oparko (durch Aktualisierung der App unter „Zahlungsarten“) über ein neues Zahlungsmittel informieren. Die Information über ein neues Zahlungsmittel muss spätestens zum Ende des Kalendermonats erfolgen, der dem Kalendermonat vorausgeht, in dem das angemeldete Zahlungsmittel nicht mehr verwendbar ist.`,
            sub_point_1_3: `Die sichere und ordnungsgemäße Aufbewahrung der Zugangsdaten liegt in der Verantwortung des Nutzers.`,
            sub_point_1_4: `Weiterhin liegt es in der Verantwortung des Nutzers, dass die Zugangsdaten nicht an Unbefugte weitergegeben oder anderweitig zugänglich gemacht werden.`,
            sub_point_1_5: {
                sub_point_1_5_title: `Es liegt allein in der Verantwortung des Benutzers, dass das Oparko-Konto des Benutzers verwendet wird und nur von den richtigen Personen verwendet wird.`,
                sub_point_1_5_1: {
                    sub_point_1_5_1_title: `Der Benutzer muss Oparko unverzüglich informieren (z. B. durch Kontaktaufnahme mit dem Oparko-Kundendienst oder durch Aktualisierung der Informationen in „Mein Konto“), wenn:`,
                    sub_point_1_5_1_1: `Der Benutzer hat Grund zu der Annahme, dass eine unbefugte Person Zugang zu oder Kenntnis von den Login-Informationen erlangt hat;`,
                    sub_point_1_5_1_2: `registrierte Informationen über den Benutzer wurden geändert und/oder sollten aktualisiert werden; oder`,
                    sub_point_1_5_1_3: `der Nutzer kein zugelassenes Fahrzeug mehr hat oder für das Parken mit einem zugelassenen Fahrzeug nicht mehr bezahlen muss,`,
                },
                sub_point_1_5_2: `Die Informationen müssen zunächst vom Benutzer bereitgestellt werden, der die Informationen in Oparko selbst aktualisiert. Es liegt in der Verantwortung des Nutzers, dass keine Fahrzeuge angemeldet werden, für die der Nutzer keine Zahlung leisten muss. Oparko kann dadurch die notwendigen Vorkehrungen treffen, zum Beispiel die Anmeldeinformationen und/oder das Konto des Benutzers ändern/sperren oder den Benutzer auffordern, die registrierten Informationen des Benutzers zu aktualisieren. Der Benutzer ist auch verpflichtet, Oparko über andere Angelegenheiten zu informieren, die für den Vertrag und/oder die Erbringung der Dienste von Bedeutung sind.`,
                sub_point_1_5_3: `Der Benutzer ist dafür verantwortlich, dass die App jederzeit auf dem neuesten Stand ist. Die ordnungsgemäße Funktionsfähigkeit von Mobiltelefonen oder anderen technischen Geräten liegt jederzeit in der Verantwortung des Nutzers. Es liegt in der Verantwortung des Benutzers, dass die verwendeten Medien mit den Diensten und der App kompatibel sind. Die geltenden funktionalen Anforderungen für die Dienste und die App können jederzeit beim Kundendienst erfragt werden.`,
            },
            sub_point_1_6: `Der Benutzer ist verpflichtet, jederzeit die geltenden Gesetze und Vorschriften sowie die Regeln und Bedingungen einzuhalten, die sich aus der Beschilderung des betreffenden Parkplatzes ergeben, auf dem der Benutzer sein Fahrzeug abstellt.`,
            sub_point_1_7: `Der Benutzer ist für den korrekten Beginn des Parkens verantwortlich, indem er das Kennzeichen des geparkten Fahrzeugs und die für den benutzten Parkplatz gültige Vorwahl angibt. Der Nutzer ist dafür verantwortlich, dass die vom Nutzer angegebene Vorwahl korrekt ist, auch wenn die Vorwahl in der Standortfunktion der App vorgeschlagen wird. HINWEIS: Wenn das korrekte Kennzeichen und/oder die Vorwahl nicht eingegeben werden, wurde für das Parken keine gültige Zahlung geleistet, weshalb für das geparkte Fahrzeug eine Kontrollgebühr erhoben werden kann.`,
            sub_point_1_8: `Der Nutzer ist dafür verantwortlich, dass ein begonnenes Parken zu Ende geführt wird. Gibt der Nutzer bei Parkbeginn keine vorläufige Endzeit oder Gesamtparkzeit an, ist der Nutzer für die manuelle Beendigung des Parkens verantwortlich. Das Beenden des Parkens kann über die App oder durch Kontaktaufnahme mit Oparko unter Verwendung der Kontaktinformationen erfolgen, die auf der App, der Website und den Oparko-Schildern erscheinen.`,
            sub_point_1_9: {
                sub_point_1_9_title: `Wenn der Parkdienst nicht verfügbar oder außer Betrieb ist, wenn der Nutzer parken muss oder während der Nutzer parkt (z oder Verzögerung von Telefon, Internet oder anderen Kommunikationsnetzen), ist der Benutzer dafür verantwortlich, dass die Zahlung auf andere Weise und gemäß den Hinweisen auf dem Parkplatz erfolgt (z. B. bei Zahlung an dem entsprechenden Automaten).`,
                sub_point_1_9_1: `Der Benutzer ist verantwortlich für die Zahlung aller Kontrollgebühren oder Gebühren für unsachgemäßes Parken (gemeldet oder erhoben im Zusammenhang mit Verstößen gegen Parkbeschränkungen).`,
                sub_point_1_9_2: `Wenn das geparkte Fahrzeug auf ein Unternehmen registriert ist, gilt das Parken als in einem Arbeitskontext durchgeführt, weshalb das Unternehmen in dieser Situation als richtiger Schuldner angesehen wird.`,
            },
            sub_point_1_10: `Der Nutzer hat die Möglichkeit, die Parkhistorie seiner über Oparko bezahlten Parkplätze einzusehen.`,
            sub_point_1_11: `Alle über Oparko gekauften/reservierten Parkplätze erfolgen auf Ihr eigenes Risiko, sofern in diesen Allgemeinen Geschäftsbedingungen nicht ausdrücklich etwas anderes angegeben ist.`,
            sub_point_1_12: `Das Parkunternehmen übernimmt keine Verantwortung im Zusammenhang mit Unfällen, Diebstahl, Schäden an Personen, Fahrzeugen, Geräten und Gegenständen im Zusammenhang mit dem Abstellen des Fahrzeugs auf einem Parkplatz. Das Parkunternehmen kann nicht für indirekte Verluste oder Folgeschäden jeglicher Art haftbar gemacht werden.`,
        },
    },
    Terms_main_point_8: {
        main_title: 'Zugang zu Beschwerden',
        sub_point_1: `Im Falle eines fehlerhaften Dienstes oder einer falschen Belastung muss der Benutzer unverzüglich und spätestens 30 Tage nach Erbringung der betreffenden Dienstleistung oder nachdem der Benutzer die betreffende falsche Belastung festgestellt hat oder hätte feststellen müssen, eine schriftliche Beschwerde bei der Parkeringskompagnieteinreichen Kundendienst. Die Reklamation muss den Mangel oder Fehler eindeutig angeben. Der Benutzer muss dem Parkunternehmen im Zusammenhang mit Untersuchungen im Zusammenhang mit der Beschwerde angemessene Unterstützung leisten.`,
        sub_point_2: `Beschwerden über auferlegte Kontrollgebühren sind an den Herausgeber der auferlegten Kontrollgebühr(en) zu richten.`,
    },
    Terms_main_point_9: {
        main_title: 'Bedingungen und Preise',
        sub_point_1: `Der Nutzer haftet jederzeit für alle über Oparko getätigten Zahlungen.`,
        sub_point_2: `Zahlungen erfolgen, indem der Nutzer ein gültiges Zahlungsmittel in der App registriert. Die Zahlung erfolgt über die vom Nutzer registrierten Zahlungsmittel.
        Wenn ein Fahrzeug als aktiv gekennzeichnet ist, hat der Nutzer akzeptiert, dass die Zahlung automatisch über das angegebene bevorzugte Zahlungsmittel erfolgt.
        Zahlungen werden dem Administrator in Rechnung gestellt, wenn Erhvervsparkering als Zahlungsmittel registriert ist. Der Nutzer wählt und genehmigt die Zahlungsmethode im Zusammenhang mit dem Registrierungsantrag.
        Es liegt in der Verantwortung des Benutzers, vor Beginn des Parkens das korrekte und gültige Zahlungsmittel anzugeben.
        Die falsche Nutzung des gewerblichen Parkplatzes ist ausschließlich eine Angelegenheit zwischen dem Administrator und dem Benutzer.`,
        sub_point_3: `Ved forsinket betaling vil der blive opkrævet eventuelle rykkere i overensstemmelse med gældende lovgivning.`,
        sub_point_4: `Alle Preise sind in dänischen Kronen angegeben und enthalten die gesetzliche Mehrwertsteuer. Der für das Parken zu zahlende Preis ist abhängig vom gewählten Stellplatz, der gewählten Parkdauer und ob ein laufendes Parken verlängert wird.`,
        sub_point_5: `Bei der Erstellung eines Stellplatzes und bei dessen Erweiterung wird angegeben, wie viel das jeweilige Parken oder die Erweiterung kostet, und dieser Betrag muss genehmigt werden, bevor der Stellplatz erstellt und/oder erweitert wird.`,
        sub_point_6: `Teilweise wird zusätzlich zum Stundensatz für das Parken eine Betriebsgebühr erhoben. Diese erscheint beim Kauf einer Genehmigung immer in der App oder auf der Website.`,
        sub_point_7: `Das Parkunternehmen behält sich das Recht vor, Gebühren und Preise zu ändern.`,
        sub_point_8: `HINWEIS: Oparko behält sich das Recht vor, den Inhalt der App sowie der Website ohne vorherige Ankündigung zu aktualisieren und zu ändern.`,
    },
    Terms_main_point_10: {
        main_title: 'Rücktrittsrecht',
        sub_point_1: `Gemäß dem dänischen Verbrauchervertragsgesetz (Gesetz Nr. 1457 vom 17. Dezember 2013) hat der Benutzer das Recht, vom Vertrag zurückzutreten, indem er den Oparko-Kundendienst innerhalb von 14 Tagen nach Genehmigung der Registrierung benachrichtigt.`,
        sub_point_2: `Der Benutzer stimmt zu, dass das normale Widerrufsrecht des Kaufgesetzes (Gesetz Nr. 1853 vom 24.09.2021) von 14 Tagen nur für Parkplätze gilt, die zum Zeitpunkt der Stornierung noch nicht in Kraft (gültig) sind. Bestellt der Nutzer somit einen Parkplatz mit einer Gültigkeitsdauer ab demselben Tag (sofort), endet auch das Widerrufsrecht am selben Tag (sofort) und das sonst geltende 14-tägige Widerrufsrecht wird dadurch seitens des Nutzers aufgehoben. Beim Erwerb eines Parkplatzes durch den Nutzer mit Gültigkeitsbeginn später als 14 Tage nach Abschluss des Abonnementvertrages hat der Nutzer ein 14-tägiges Widerrufsrecht.`,
        sub_point_3: {
            sub_point_3_title: `Um das Widerrufsrecht auszuüben, muss die Widerrufserklärung spätestens am 14. Tag des Tages, an dem die Zahlung bei Oparko registriert wird, bei Oparko eingehen.`,
            sub_point_3_1: `Läuft die Frist an einem Feiertag, Samstag, Verfassungstag, Heiligabend oder Silvester ab, verlängert sich die Frist bis zum folgenden Werktag.`,
            sub_point_3_2: `Um das Widerrufsrecht auszuüben, muss der Benutzer dem Parkunternehmen eine E-Mail senden, in der der Benutzer schreibt, dass der Kauf/die Reservierung storniert wird (siehe Abschnitt 12.2.8.).`,
        },
        sub_point_4: `Bitte beachten Sie, dass Sie den Parkplatz betreten/den Kauf in der App oder <a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk.</a> autorisieren , gilt als Annahme des Parkbeginns. Wenn Sie dann Ihren Kauf in der App oder <a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk.</a> bereuen, erhalten Sie eine Rückerstattung reduziert um einen proportionalen Teil des Gesamtpreises für das Parken während des gekauften Zeitraums, basierend auf der Anzahl der Tage/Stunden/Minuten, die das Fahrzeug geparkt wurde, bis Oparko die Registrierung der Ausübung des Widerrufsrechts erhalten hat.`,
    },
    Terms_main_point_11: {
        main_title: 'Bedingungen für die Stornierung',
        sub_point_1: `Der Benutzer kann wählen, ob er das Benutzerkonto in der App oder durch Schliessen des Benutzerkontos über die Website schließen möchte, wodurch das Oparko-Konto des Benutzers ungültig wird, sodass der Benutzer nicht mehr die Möglichkeit hat, das Parken über Oparko zu bezahlen.`,
        sub_point_2: `Nicht bezahlte Beträge werden gemäß der abgeschlossenen Vereinbarung in Rechnung gestellt, auch wenn der Benutzer das Oparko-Konto schließt.`,
    },
    Terms_main_point_12: {
        main_title: 'Laufzeit, vorzeitige Kündigung etc.',
        sub_point_1: `Diese Vereinbarung gilt ab der Zustimmung von Oparko durch den Benutzer und bis sie gemäß den Bedingungen der Vereinbarung gekündigt oder bis anderweitig schriftlich vereinbart wird.`,
        sub_point_2: {
            sub_point_2_title: `Oparko ist berechtigt, den Zugang des Benutzers zu den Diensten sofort zu sperren, das Konto des Benutzers zu kündigen und/oder den Vertrag mit sofortiger Wirkung zu kündigen, wenn`,
            sub_point_2_1: `Der Benutzer verstößt in erheblichem Maße gegen seine Verpflichtungen aus dieser Vereinbarung;`,
            sub_point_2_2: `Der Nutzer kommt seinen Zahlungsverpflichtungen gegenüber Oparko nicht nach oder es besteht Grund zu der Annahme, dass der Nutzer seinen Zahlungsverpflichtungen gegenüber Oparko nicht nachkommen wird,`,
            sub_point_2_3: `Es ist nach vernünftigem Ermessen von Oparko mit einer Zahlungsunfähigkeit des Nutzers zu rechnen;`,
            sub_point_2_4: `Der Benutzer verwendet Oparko-Dienste unter Verstoß gegen die Vereinbarung oder die Oparko oder Dritte anderweitig schädigen oder negativ beeinflussen;`,
            sub_point_2_5: `Der Benutzer hat wiederholt ein Fahrzeug unter Verstoß gegen geltende Gesetze, Regeln und/oder Vorschriften des jeweiligen Parkanbieters geparkt;`,
            sub_point_2_6: `Der Benutzer hat falsche, unvollständige oder irreführende Informationen übermittelt, oder`,
            sub_point_2_7: `Oparko hält es auf der Grundlage einer Gesamtbewertung für wahrscheinlich, dass der Benutzer an kriminellen Aktivitäten beteiligt oder daran beteiligt sein könnte.`,
            sub_point_2_8: `Die Kündigung des Vertrags muss schriftlich an service@oparko.com oder über das Formular auf der Website erfolgen: <a href="https://parkeringskompagniet.dk/kontakt/" class="text-pk-blue">https://parkeringskompagniet.dk/kontakt/</a>`,
        },
        // eslint-disable-next-line no-irregular-whitespace
        sub_point_3: `Die Kündigung der Vereinbarung (aus welchem ​​Grund auch immer) berührt nicht die Rechte und/oder Pflichten, die eine Partei gegenüber der anderen Partei vor dem Datum der Kündigung der Vereinbarung eingegangen ist.`,
    },
    Terms_main_point_13: {
        main_title: 'Oparko-Haftung',
        sub_point_1: `Die Gesamthaftung von Oparko für Schäden darf unter keinen Umständen einen Betrag in Höhe von DKK übersteigen. 38.000,00, sofern Oparko nicht vorsätzlich oder grob fahrlässig gehandelt hat.`,
        sub_point_2: `Das Parkunternehmen haftet nicht für Unfälle, Diebstahl, Personenschäden, Schäden an Fahrzeugen, Einrichtungen oder anderen am oder im Fahrzeug montierten Gegenständen.`,
        sub_point_3: `Das Parkunternehmen haftet weder für mittelbare Schäden noch für Folgeschäden. Das Parkunternehmen und Oparko haften nicht für Schäden in Bezug auf die Beziehung des Benutzers zu irgendwelchen dritte Seite`,
        sub_point_4: {
            sub_point_4_title: `Das Parkunternehmen haftet nicht für Schäden oder Verluste, die dem Verwalter und/oder dem Benutzer entstehen durch:`,
            sub_point_4_1: `Parken auf einem Parkplatz;`,
            sub_point_4_2: `Fehler oder Fahrlässigkeit des Benutzers, einschließlich wenn der Benutzer ein Parken nicht korrekt begonnen oder beendet hat (unabhängig davon, ob er eine Erinnerung oder eine Push-Nachricht von Oparko erhalten hat) oder wenn der Benutzer in Bezug darauf eine falsche Vorwahl eingegeben hat zu einem Parkplatz und/oder Kennzeichen, zu Beginn eines Parkvorgangs (unabhängig davon, ob der Nutzer eine solche Vorwahl manuell eingegeben hat oder dies über die Ortungsfunktion in der App vorgeschlagen wurde);`,
            sub_point_4_3: `dass der Benutzer seinen Verpflichtungen gemäß dieser Vereinbarung und den Nutzungsbedingungen von Oparko nicht nachgekommen ist oder dass der Benutzer die Anweisungen von Oparko anderweitig nicht befolgt hat;`,
            sub_point_4_4: `dass der Benutzer das Fahrzeug unter Verstoß gegen geltende Gesetze, Regeln und/oder Vorschriften geparkt hat, die auf der angebrachten Beschilderung auf dem betreffenden Parkplatz angegeben sind, auf dem der Benutzer das Fahrzeug parkt;`,
            sub_point_4_5: `Ausfall oder unzureichende Funktionalität des Mobiltelefons oder anderer technischer Geräte des Benutzers (z. B. Abschalten des Geräts oder Erschöpfung des Akkus);`,
            sub_point_4_6: `Ausfälle, Unterbrechungen oder Verzögerungen in Telefon-, Internet- oder anderen Kommunikationsnetzen oder Handlungen oder Unterlassungen von Telekommunikationsbetreibern, die die Funktionalität oder Verfügbarkeit des Dienstes beeinträchtigen;`,
            sub_point_4_7: `Fehler oder Defekt in einer technischen Funktion, die den Preis der betreffenden Parkgebühr anzeigt;`,
            sub_point_4_8: `dass der Dienst von Oparko aufgrund von Tatsachen unterbrochen wurde, die sich als falsch herausstellten, von denen Oparko jedoch zum Zeitpunkt der Unterbrechung Grund zu der Annahme hatte, dass sie richtig waren, und die die Unterbrechung rechtfertigten;`,
            sub_point_4_9: `Unterbrechungen oder unzureichender Zugriff auf einen oder mehrere Dienste, die Oparko vernünftigerweise nicht hätte vorhersehen können;`,
            sub_point_4_10: `dass ein von Oparko vorgeschlagener Parkplatz nicht verfügbar ist, wenn der Benutzer dort ankommt;`,
            sub_point_4_11: `unbefugte Nutzung von Login-Informationen und/oder Diensten; oder`,
            sub_point_4_12: `höhere Gewalt (siehe Punkt 16).`,
        },
        sub_point_5: `Oparko haftet unter keinen Umständen für indirekte Schäden wie Betriebsausfall und Datenverlust. Oparko haftet nicht für Schäden in Bezug auf die Beziehung des Administrators und/oder des Benutzers zu Dritten.`,
    },
    Terms_main_point_14: {
        main_title: `Haftung des Nutzers für Schäden`,
        sub_point_1: `Der Benutzer muss Parkeringskompagniet von Schadensersatzansprüchen oder Verlusten freistellen, die auf einen Fehler oder eine Fahrlässigkeit des Benutzers, die Nichterfüllung seiner Verpflichtungen aus diesem Vertrag durch den Benutzer oder die anderweitige Nichteinhaltung der Anweisungen von Oparko zurückzuführen sind. Der Benutzer muss Oparko auch von Ansprüchen Dritter freistellen, die eine direkte oder indirekte Folge der Nutzung eines Dienstes durch den Benutzer sind.`,
    },
    Terms_main_point_15: {
        main_title: `Rechte an geistigem Eigentum`,
        sub_point_1: `Der Nutzer akzeptiert, dass Software niemals in allen möglichen Situationen getestet werden kann und dass Abweichungen von der vereinbarten Funktionalität sowie unvorhergesehene Fehler und Unterbrechungen auftreten können. Oparko ist im Allgemeinen 24 Stunden am Tag verfügbar, mit Ausnahme geplanter Unterbrechungen für Upgrades, Änderungen und Wartung. Das Recht, Oparko mit neuen Funktionen zu aktualisieren oder anderweitig Änderungen vorzunehmen, beispielsweise um das System an neue Technologien, neue Sicherheitsstandards oder neue Verwaltungsverfahren usw. anzupassen, behält sich die Parkeringskompagnietvor. Das Parkunternehmen übernimmt keine Verantwortung für Schäden oder Verluste im Zusammenhang mit einem fehlenden Zugang zu Oparko, Oparko, das außer Betrieb ist oder Fehler enthält.`,
        sub_point_2: `Alle Urheberrechte (einschließlich Rechte an Quellcodes, Computerprogrammen, Algorithmen und Objektcodes) und andere geistige Eigentumsrechte (einschließlich Patentrechte und Marken) in Bezug auf Oparko und seine Inhalte sind Eigentum oder lizenziert von Parkeringskompagnietoder seinen Lieferanten und Partnern. Dem Administrator oder dem Benutzer werden gemäß dieser Vereinbarung keine dieser Rechte übertragen. Es ist nicht gestattet, Oparko oder seine Inhalte für kommerzielle Zwecke zu nutzen. Der Benutzer hat kein Recht, die in Oparko enthaltene Software zu kopieren, zu verteilen, zu verkaufen, zu veröffentlichen, zu übertragen, zu verleihen, unterzulizenzieren, zu modifizieren oder anderweitig darüber zu verfügen. Der Benutzer hat kein Recht, den Quellcode der Software zu disassemblieren, zurückzuentwickeln, zu dekompilieren oder anderweitig zu versuchen, darauf zuzugreifen.`,
        sub_point_3: `Dass alle an Oparko übermittelten Informationen und Daten frei von schädlichen Elementen, Quellcodes oder Malware (wie Viren, Würmern und Trojanischen Pferden) sind, muss vom Nutzer gesichert und garantiert werden. Informationen, die der Nutzer auf „Meine Seiten“ hochlädt oder über die App zur Verfügung stellt, dürfen keine geistigen Eigentumsrechte Dritter verletzen oder anderweitig gegen Gesetze, Vorschriften oder die Rechte anderer verstoßen. Es liegt in der Verantwortung des Benutzers, dass die geistigen Eigentumsrechte Dritter oder anderer nicht verletzt werden.`,
        sub_point_4: `Sofern Oparko nicht grob fahrlässig oder vorsätzlich gehandelt hat, ist Oparko nicht dafür verantwortlich, dass Daten Dritten zugänglich gemacht werden. Oparko verwendet übliche Sicherheitsmaßnahmen zum Schutz der Daten`,
    },
    Terms_main_point_16: {
        main_title: `Höhere Gewalt`,
        sub_point_1: `Oparko ist nicht haftbar für Schäden oder Haftung für Ausfälle oder Verzögerungen bei der Umsetzung der Bedingungen dieser Vereinbarung aufgrund von Umständen, die außerhalb der angemessenen Kontrolle von Oparko liegen.`,
    },
    Terms_main_point_17: {
        main_title: `Vertraulichkeit`,
        sub_point_1: `Der Benutzer akzeptiert, dass Oparko jeden Missbrauch der Dienste, illegale Aktivitäten, illegales oder unangemessenes Verhalten und/oder jeden Verdacht darauf der Polizei oder anderen zuständigen Behörden meldet.`,
    },
    Terms_main_point_18: {
        main_title: `Rechtswahl und Streitigkeiten`,
        sub_point_1: `Diese Vereinbarung unterliegt dänischem Recht und ist entsprechend auszulegen. Alle Streitigkeiten, Meinungsverschiedenheiten oder Ansprüche, die sich auf der Grundlage oder im Zusammenhang mit dieser Vereinbarung ergeben, oder jede Verletzung, Beendigung oder Ungültigkeit derselben müssen grundsätzlich durch freiwillige Vereinbarung zwischen den Parteien beigelegt werden. Kann auf Anhieb keine Einigung erzielt werden, findet ein Gespräch zwischen einem vom Nutzer gewählten Vertreter und dem Parkunternehmen statt. Wenn die Parteien keine Einigung erzielen können, kann der Streit von den dänischen Gerichten mit dem Bezirksgericht in Aarhus als erster Instanz behandelt werden. Diese Klausel 19.1 gilt nach der Kündigung oder dem Ablauf dieser Vereinbarung.`,
        sub_point_2: `Wird eine Bestimmung dieser Vereinbarung als ungültig aufgehoben, muss die Vereinbarung im Übrigen aufrechterhalten und die ungültige Bestimmung soweit möglich durch eine gültige Bestimmung ersetzt werden.`,
        sub_point_3: `Das Parkunternehmen behält sich das Recht vor, diese Allgemeinen Geschäftsbedingungen für die Nutzung des Parkunternehmens zu ändern. In diesem Fall muss Parkeringskompagniet unverzüglich darüber informieren und eine neue Version der Allgemeinen Geschäftsbedingungen für die Nutzung von Oparko zur Verfügung stellen. Nutzt der Nutzer die Dienste anschließend weiter, gilt dies als Zustimmung zu diesen Änderungen. Ungeachtet dessen behält sich das Parkhaus vor, Preise und Entgelte aufgrund von Betriebskostenerhöhungen, sonstigen Kosten und Entgelterhöhungen etc. zu ändern. Die jeweils geltenden Allgemeinen Geschäftsbedingungen finden Sie in der App oder auf www.parkeringskompagniet.dk unter dem Punkt „Kundenservice“.`,
    },
    Subscription_terms_heading: `ABONNEMENTBEDINGUNGEN`,
    Subscription_terms_1: {
        main_title: `Beim Erstellen und Verwenden von Oparko-Abo-Parken akzeptieren Sie zusätzlich zu den nachstehenden auch die allgemeinen Geschäftsbedingungen für die Nutzung von Oparko. Diese Bedingungen erscheinen in der Oparko-App und unter <a href="https://www.parkeringskompagniet.dk/oparko" class="text-pk-blue">https://www.parkeringskompagniet.dk/oparko</a>`,
        sub_point_1: `Mit dem Abschluss eines Vertrages über das Abo-Parken erhält der Nutzer einen zeitlich begrenzten digitalen Parkausweis.`,
    },
    Subscription_terms_2: {
        main_title: `Der Abonnement-Parkvertrag gilt für die gewählte Anzahl an Abonnement-Parkverträgen, die Sie zum Parken auf der gleichen Anzahl von Parkplätzen auf dem ausgewählten Parkplatz berechtigen. Für das Abo-Parken ist die Gültigkeitsbedingung, dass das Fahrzeug korrekt bei Oparko registriert ist. Eine korrekte und gültige Registrierung setzt voraus, dass die Registrierungsnummer korrekt registriert ist und dass das Fahrzeug korrekt auf dem Abonnement-Parkprodukt registriert ist. Das zugelassene Fahrzeug darf auf nicht anderweitig reservierten Parkplätzen parken. Dies gilt rund um die Uhr, an jedem Tag der Woche.`,
        sub_point_1: `Abo-Parken erscheint unter aktives Parken, gemietete Parkplätze.`,
        sub_point_2: `Voraussetzung für eine ordnungsgemäße und gültige Registrierung ist, dass das registrierte Fahrzeug als Lieblingsauto ausgewählt wurde und auf dem Abo-Parkprodukt unter Angemietete Parkplätze korrekt registriert erscheint.`,
    },
    Subscription_terms_3: {
        main_title: `Der Abo-Parkvertrag gilt für die Nutzung eines gekennzeichneten Parkhauses nach dem Prinzip „Wer zuerst kommt, mahlt zuerst“. Das ist Mit dem Abo-Parkvertrag erwirbt der Nutzer lediglich ein „schwebendes Recht“ auf einen gekennzeichneten, nicht anderweitig reservierten Stellplatz. Für den Fall, dass kein gekennzeichneter Parkplatz verfügbar ist, kann Parkeringskompagniet nicht dafür verantwortlich gemacht werden, ebenso wie der Benutzer keinen Anspruch auf Minderung des Entgelts hat.`,
        sub_point_1: `Die Nichtinanspruchnahme des Abo-Parkens durch den Nutzer für den gesamten gewählten Zeitraum begründet keinen Anspruch des Nutzers auf Minderung der Vergütung.`,
    },
    Subscription_terms_4: {
        main_title: `Der Abo-Parkvertrag tritt ab einschließlich dem vom Nutzer gewählten Datum in Kraft und läuft für die angegebene Dauer. Der Abonnement-Parkvertrag kann von der Parkeringskompagnietohne weitere Begründung fristlos gekündigt werden. Der Nutzer kann frühestens zum Ende eines gekauften Zeitraums kündigen. Die Kündigung tritt ein, wenn der Nutzer den Abonnement-Parkvertrag nicht erneuert oder verlängert. Für den Fall, dass der Nutzer die automatische Verlängerung gewählt hat, muss der Nutzer die automatische Verlängerung stoppen, um den Abonnement-Parkvertrag zu kündigen.`,
        sub_point_1: `Der Nutzer kann den Abonnement-Parkvertrag jederzeit nur bis zum Ende des gewählten Zeitraums kündigen. In diesem Fall wird kein Geld von der Zahlungskarte des Benutzers abgebucht, bis ein neuer Abonnement-Parkvertrag erstellt wird.`,
        sub_point_2: `Das Abo-Parken ist ab dem gewählten Datum und für den gewählten Zeitraum gültig. Ein Abonnement-Parkvertrag ist daher nicht zwingend vom 1. auf den 1. eines Monats gültig, es sei denn, der Nutzer hat dies gewählt.`,
    },
    Subscription_terms_5: {
        main_title: `Der Nutzer ist jederzeit dafür verantwortlich, dass für das auf dem Parkplatz abgestellte Fahrzeug ein gültiger Abonnementvertrag besteht. Es liegt auch in der eigenen Verantwortung des Benutzers, dass zugehörige Fahrzeuge korrekt und ausreichend auf dem Profil registriert sind. Eine Kontrollgebühr kann erhoben werden, wenn ein geparktes Fahrzeug nicht korrekt und ausreichend registriert wurde. Die Kontrollgebühr kann nachträglich nicht erlassen werden.`,
    },
    Subscription_terms_6: {
        main_title: `Der Inhalt des Abonnement-Parkvertrags, Preise und etwaige Sonderkonditionen für die Produktpakete werden jederzeit auf der Website und in der App angezeigt. Die Vergütung wird im Voraus gezahlt. Erstmalig bei Abschluss des Abo-Parkvertrages.`,
        sub_point_1: `In einigen Fällen wird dem Benutzer ein eindeutiger Produktcode zur Verfügung gestellt, der bei der Registrierung verwendet werden muss. Im Zusammenhang mit dem Erhalt des eindeutigen Produktcodes wird der Benutzer darüber informiert, wie sich der Benutzer im Zusammenhang mit der Zahlungsverpflichtung verhalten muss und wie die Registrierung des eindeutigen Produktcodes erfolgen muss.`,
        sub_point_2: `Für den Fall, dass der Abonnement-Parkvertrag gekündigt, widerrufen, rückgängig gemacht oder der gelieferte eindeutige Produktcode storniert oder gesperrt wird, ist die Parkeringskompagnietberechtigt, die registrierten Informationen, einschließlich der E-Mail-Adresse des Benutzers usw., zu verwenden. bezüglich Punkt. 17., hierüber eine Mitteilung zu machen.`,
    },
    Subscription_terms_7: {
        main_title: `Die Preiszahlvergütung wird jedes Jahr zum 1. Januar ohne weitere Ankündigung angepasst, wobei sie mit dem Nettopreisindex für Oktober angepasst wird. im Vorjahr, mindestens jedoch mit 3 % (auf volle Kronen gerundet) bezogen auf die Vergütungszahlung im Vorjahr. Die Indexanpassung der Vergütung erfolgt auf Basis der unmittelbar vor der Anpassung geltenden Vergütung, unabhängig davon, ob diese Vergütung durch Anpassung möglicherweise auf andere Weise als durch Indexanpassung entstanden ist.`,
        sub_point_1: `Zusätzlich zu der vorstehenden Indexregelung behält sich die Parkeringskompagnietdas Recht vor, Preise und Gebühren aufgrund von Betriebskostenerhöhungen, sonstigen Kosten und Gebührenerhöhungen usw. zu ändern. Solche Änderungen treten frühestens zum Ende des laufenden Monats + 1 Monat, nachdem der Nutzer über solche Änderungen informiert wurde, in Kraft.`,
    },
    Subscription_terms_8: {
        main_title: `Das Parkunternehmen behält sich Preisirrtümer, Preisänderungen und Aktualisierungsfehler vor.`,
    },
    Subscription_terms_9: {
        main_title: `Wenn der Benutzer die automatische Verlängerung gewählt hat, liegt es in der eigenen Verantwortung des Benutzers, die automatische Verlängerung zu „stoppen“, indem er den Abonnement-Parkvertrag kündigt. Die Einstellung der Zahlung gilt nicht als Kündigung. Die Zahlung wird bis zur Beendigung des Abonnement-Parkvertrags eingezogen.`,
    },
    Subscription_terms_10: {
        main_title: `Der gesamte Verkehr und das Parken erfolgt auf eigene Gefahr des Benutzers. Das Parkunternehmen ist nicht verantwortlich für Diebstahl oder Schäden, die am Fahrzeug des Benutzers verursacht werden können, unabhängig von der Ursache dafür.`,
    },
    Subscription_terms_11: {
        main_title: `Der Abonnement-Parkvertrag darf ohne schriftliche Zustimmung des Parkunternehmens nur für das Abstellen des ordnungsgemäß zugelassenen Fahrzeugs mit einem zugelassenen Gesamtgewicht von max. 3.500 kg.`,
    },
    Subscription_terms_12: {
        main_title: `Mit diesem Abonnement-Parkvertrag wird der Nutzer ausdrücklich darauf hingewiesen, dass der Abschluss dieses Abonnement-Parkvertrags den Nutzer nicht berechtigt, auferlegte Kontrollgebühren zu stornieren. Der Abo-Parkvertrag gilt erst ab dem Zeitpunkt der korrekten Registrierung des Kennzeichens. Der Abo-Parkvertrag gilt nur für das korrekt angemeldete Fahrzeug.`,
        sub_point_1: `Der Abonnement-Parkvertrag berechtigt nur zum Parken in dem im Abonnement-Parkvertrag ausgewiesenen Bereich auf den gekennzeichneten Parkplätzen, sofern nicht ersichtlich ist, dass für das Parken auf diesen Parkplätzen besondere Regelungen gelten. Es wird darauf hingewiesen, dass der Abo-Parkvertrag kein gültiger Parkausweis für das Parken in reservierten Parkboxen ist.`,
    },
    Subscription_terms_13: {
        main_title: `Bei erheblicher Vertragsverletzung des Nutzers kann Parkeringskompagniet den Abonnement-Parkvertrag mit sofortiger Wirkung kündigen. Als wesentlicher Verstoß gilt insbesondere die Nichtzahlung der vereinbarten Vergütung, der Verstoß gegen die Bestimmungen dieses Vertrages sowie jeder Verstoß gegen die Parkordnung. Im Falle eines erheblichen Verstoßes ist Parkeringskompagniet berechtigt, eine Kontrollgebühr zu erheben und das Fahrzeug des Benutzers gegebenenfalls zu entfernen oder bis zur Zahlung der fälligen Gebühren und Kosten zu beschlagnahmen. Der Nutzer hat keinen Anspruch auf Aufrechnung, auch wenn Meinungsverschiedenheiten über die Forderung bestehen.`,
        sub_point_1: `Das Parkunternehmen kann den Abonnement-Parkvertrag mit einer Frist von 1 Monat schriftlich kündigen, wenn Wartungsarbeiten, Umbauten, Renovierungen etc. durchgeführt werden müssen. Bedingungen, die dazu führen, dass Parkeringskompagniet den Parkplatz dauerhaft nicht mehr nutzen kann.`,
    },
    Subscription_terms_14: {
        main_title: `Gemäß dem dänischen Verbrauchervertragsgesetz (Gesetz Nr. 1457 vom 17. Dezember 2013) hat der Benutzer das Recht, vom Vertrag zurückzutreten, indem er den Kundendienst von Parkeringskompagniet innerhalb von 14 Tagen nach Genehmigung der Registrierung benachrichtigt. Um das Widerrufsrecht auszuüben, muss die Widerrufserklärung spätestens am 14. Tag des Tages, an dem die Zahlung bei Oparko registriert wurde, beim Parkunternehmen eingereicht werden.`,
        sub_point_1: `Läuft die Frist an einem Feiertag, Samstag, Verfassungstag, Heiligabend oder Silvester ab, verlängert sich die Frist bis zum folgenden Werktag`,
        sub_point_2: `Um das Widerrufsrecht auszuüben, muss der Nutzer uns eine E-Mail oder einen Brief senden, in dem der Nutzer schreibt, dass der Kauf/die Reservierung storniert wird. Bitte beachten Sie, dass das Betreten des Parkplatzes/die Autorisierung des Kaufs in der App oder auf www.parkeringskompagniet.dk als Zustimmung gilt, dass mit dem Parken begonnen wurde und dass auf das 14-tägige Widerrufsrecht verzichtet wird. Wenn Sie Ihren Kauf dann in der App oder auf www.parkeringskompagniet.dk bereuen, wird Ihre Rückerstattung um einen proportionalen Teil des Gesamtpreises für das Parken im gekauften Zeitraum reduziert, basierend auf der Anzahl der Tage/Stunden/Minuten, die das Fahrzeug hat geparkt, bis Parkeringskompagnietdie Anmeldung zur Ausübung des Widerrufsrechts erhalten hat`,
    },
    Subscription_terms_15: {
        main_title: `Alle Streitigkeiten zwischen dem Parkunternehmen und dem Benutzer bezüglich des Verständnisses dieser Vereinbarung müssen von den ordentlichen Gerichten nach dänischem Recht entschieden werden. Als Gerichtsstand wird das Gericht in Aarhus vereinbart.`,
    },
    Subscription_terms_16: {
        main_title: `Der Nutzer ist nicht berechtigt, die Parkbox unterzuvermieten. Ein Verstoß hiergegen gilt als Ordnungswidrigkeit, vgl. Punkt 13.`,
    },
    Subscription_terms_17: {
        main_title: `Das Parkplatzunternehmen ist der Parkplatzverwalter im Auftrag des Flächeneigentümers/der Grundstücksverwalter des Flächeneigentümers. Alle Anfragen bzgl dieser Vertrag muss gegenüber dem Parkunternehmen abgeschlossen werden. Wenn Parkeringskompagnietnicht mehr der Parkplatzverwalter ist, wird der Vertrag auf einen neuen Parkplatzverwalter übertragen.`,
    },
    Subscription_terms_18: {
        main_title: `In Bezug auf die Speicherung und Verarbeitung personenbezogener Daten lesen Sie bitte die jederzeit geltende Datenrichtlinie, die unter parkingskompagniet.dk/datapolitik/ erscheint. Sollen die Informationen gelöscht werden, gilt dies als Kündigung des Abonnement-Parkvertrages. Denn in diesen Fällen kann Parkeringskompagniet den Vertrag ohne die genannten Informationen nicht aufrechterhalten.`,
    },
    Subscription_terms_footer_heading_1: `BEACHTEN SIE, dass der Benutzer verpflichtet ist, die in Oparko registrierten Informationen auf dem neuesten Stand zu halten. Das ist Änderungen der Adresse, E-Mail usw. müssen vom Benutzer dem Parkunternehmen mitgeteilt werden. oder evtl Registrierungsnummer des Fahrzeugs, das in Oparko verwendet werden soll. Wird dies nicht eingehalten, ist Parkeringskompagniet berechtigt, die bereits registrierten Informationen zu löschen und den Abonnement-Parkvertrag zu kündigen, um die geltenden Gesetze zum Schutz personenbezogener Daten einzuhalten.`,
    Subscription_terms_footer_heading_2: `Parkingkompaniet ApS`,
    Footer_contact_info: {
        contact_title: `Kontakt Informationen`,
        company_name: `Parkingkompaniet ApS`,
        address_line: `Trindsøvej 4, 8000 Aarhus`,
        cvr: `CVR: 37127485`,
        email: `E-mail: <a href="mailto:service@oparko.com" class="text-pk-blue">service@oparko.com</a>`,
        tel: `Telefon: <a href="tel:+ 45 88 74 31 94" class="text-pk-blue">+ 45 88 74 31 94</a>`,
        website: `<a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk</a>`,
        updatedAt: `Habe es überarbeitet 16.11.2022`,
    },

    // Act as user
    'Take Over': 'Benutzer übernehmen',

    // Old Terms
    Terms_title: 'Geschäftsbedingungen',
    Terms_heading: 'DATENRICHTLINIE UND COOKIES',
    Terms_last:
        'Es liegt in der vollen Verantwortung des Fahrers, dass die bereitgestellten Informationen korrekt sind. Gleichzeitig lehnt das Parkhaus die Verantwortung für eventuelle Fehler während der Erstellung ab.',

    Terms_1: {
        para_1: 'Diese Richtlinien gelten für die Informationen, die wir über Personen sammeln, die sie über die Website, E-Mail und Telefonanfragen übermitteln. Wenn Sie der Parkeringskompagnietpersonenbezogene Daten zur Verfügung stellen, stimmen Sie auch zu, dass wir Ihre personenbezogenen Daten gemäß unserer Datenrichtlinie verarbeiten. Personenbezogene Daten umfassen alle Arten von Informationen über eine identifizierbare Person. Wir behalten uns das Recht vor, unsere Datenrichtlinie gemäß der geltenden Gesetzgebung in diesem Bereich kontinuierlich zu aktualisieren, und werden unsere Kunden darüber so weit wie möglich informieren.',
        para_2: 'Databehandler er ParkeringskompagnietApS',
        para_3: 'Ryesgade 7b, 8000 Aarhus',
        para_4: 'CVR-nr: 37127485',
        link_1: 'Telefon: <a href="tel:+ 45 88 77 31 50" class="text-pk-blue">+ 45 88 77 31 50</a>',
        link_2: 'Mail: <a href="mailto:service@oparko.com" class="text-pk-blue">service@oparko.com</a>',
    },

    Terms_2: {
        heading: 'Datenschutzrichtlinie',
        para_1: 'Personenbezogene Daten werden nur gespeichert, wenn diese freiwillig angegeben werden, und wir speichern sie nur so lange, wie sie für uns relevant sind.',
        para_2: 'Wenn Sie uns Informationen über eine andere Person zur Verfügung stellen, liegt es in Ihrer Verantwortung, sicherzustellen, dass Sie das Recht haben, uns diese Informationen zur Verfügung zu stellen.',
        para_3: 'Wir geben Ihre personenbezogenen Daten nur weiter, wenn dies für die von uns erbrachte Leistung erforderlich ist.',
        link_1: 'Sie haben jederzeit das Recht, Einsicht in die personenbezogenen Daten zu erhalten, die wir über Sie haben, und wenn diese nicht korrekt sind, haben Sie das Recht, die betreffenden Informationen berichtigen zu lassen. Darüber hinaus haben Sie jederzeit das Recht, aus unseren Systemen gelöscht zu werden. Darüber hinaus haben Sie auch immer die Rechte, die die Datenschutz-Grundverordnung der Europäischen Union Einzelpersonen gewährt. Wenn Sie Fragen zu Ihren persönlichen Daten haben, wenden Sie sich bitte an <a href="mailto:service@oparko.com" class="text-pk-blue">service@oparko.com</a>.',
    },

    Terms_3: {
        heading: 'Erhebung und Verarbeitung Ihrer personenbezogenen Daten',
        para_1: 'Abhängig von den von Ihnen genutzten Diensten erfassen und speichern wir Ihre personenbezogenen Daten. Wenn Sie z. eine Beschwerde an uns schreiben oder Kunde werden, speichern wir in der Regel Ihren Namen, Ihre E-Mail-Adresse und Ihre Telefonnummer. Wir speichern die Informationen, um Sie bestmöglich bedienen zu können. Sie werden jederzeit im Zusammenhang mit der Erhebung von Informationen informiert.',
        ul: {
            heading:
                'Die Erhebung personenbezogener Daten erfolgt meistens im Zusammenhang mit:',
            list: [
                '♦ Zahlung der Parkgebühr',
                '♦ Kontakt bei Beschwerden oder Interesse an einer Einigung',
                '♦ Ausgabe der Parkgebühr',
                '♦ Parkkontrolle',
                '♦ Bei Anmietung oder Reservierung eines Stellplatzes',
                '♦ Beim Betreten unserer App',
                '♦ Für kostenpflichtige Dienste speichern wir notwendige Kreditkartendaten und Rechnungsinformationen',
                '♦ Für Newsletter und Monatsberichte, wenn Sie bereits Kunde der Parkeringskompagnietsind',
            ],
        },
    },

    Terms_4: {
        heading: 'Weitergabe personenbezogener Daten',
        para_1: 'Wir geben Ihre personenbezogenen Daten nicht an Dritte weiter, es sei denn, Sie haben uns dazu die Erlaubnis erteilt oder wenn dies durch geltende Gesetze zur Richtlinie für personenbezogene Daten vorgeschrieben ist.',
        row_1: {
            heading: 'Die Rechtsgrundlage für die Verarbeitung',
            para_1: 'Artikel 6 der Datenschutzverordnung, Unterabschnitt 1, Buchstabe b, wenn ein Vertrag zwischen Ihnen als Parker und der ParkeringskompagnietApS zustande gekommen ist',
            para_2: 'Artikel 6 der Datenschutzverordnung, Unterabschnitt 1, Buchstabe f, wenn die ParkeringskompagnietApS ein berechtigtes Interesse daran hat, einen Anspruch gegen Sie als Parker geltend zu machen, z. wenn Sie ordnungswidrig auf einem Parkplatz geparkt haben und Ihnen eine Parkgebühr in Rechnung gestellt wurde.',
            para_3: 'Artikel 6 Absatz 1 Buchstabe a der Datenschutzverordnung, wenn die ParkeringskompagnietApS Ihre Zustimmung zum Versand von Newslettern an Sie eingeholt hat.',
        },

        row_2: {
            heading: 'Quelle',
            para_1: 'Das Parkunternehmen erhebt personenbezogene Daten direkt von Ihnen und/oder Dritten, z. mit unserem Kunden oder mit unseren Subunternehmern.',
        },

        row_3: {
            heading: 'Wo werden Ihre persönlichen Daten gespeichert?',
            para_1: 'Ihre personenbezogenen Daten werden in unserem internen Netzwerk gespeichert und sind nur mit besonderen Rechten zugänglich. Wir haben keinen Zugriff auf Zahlungsinformationen, da diese von unseren Zahlungspartnern verschlüsselt verarbeitet werden.',
            para_2: 'Cookies sind Textdateien, die auf dem Computer abgelegt werden, den Sie zum Besuch unserer Website verwenden, und die es ermöglichen, den Computer zu erkennen und Informationen darüber zu sammeln, welche Seiten und Funktionen besucht werden. Cookies enthalten Informationen in anonymisierter Form.',
            para_3: 'Sie sind in unseren Cookie-Daten anonym, bis Sie sich für einen unserer Dienste registrieren oder uns eine Anfrage senden, in der Sie selbst personenbezogene Daten angeben.',
            link: 'Unsere Website verwendet Google Analytics, eine Website, die uns bei Webanalysediensten hilft – sie verwenden Cookies. Sie tun dies ausschließlich, damit wir die Erfahrung für unsere Benutzer verbessern können. Wir erfassen nur Informationen über Ihre Nutzung der Website des Parkunternehmens. Google Analytics erfasst Informationen über Ihr Betriebssystem, Ihren Browser, Ihre IP-Adresse, die zuvor besuchte Website sowie Datum und Uhrzeit Ihres Besuchs auf unserer Website. Wir verwenden keine Daten von Cookies, um persönliche Informationen über Sie zu sammeln, und keine der Informationen, die wir von Cookies erhalten, wird weitergeleitet oder verkauft. Weitere Informationen über die Verwendung von Cookies durch Google Analytics finden Sie auf der folgenden Website: <a href="https://policies.google.com/privacy?hl=da" class="text-pk-blue">HTTPS://POLICIES.GOOGLE.COM/PRIVACY?HL=DA</a>',
        },

        row_4: {
            heading: 'Deaktivieren von Cookies',
            link: 'Wenn Sie Cookies irgendwann ablehnen möchten, können Sie dies über Ihre Browsereinstellungen tun. Wie Sie Cookies über Ihren Browser ablehnen können, erfahren Sie unter <a href="http://www.minecookies.org/" class="text-pk-blue">WWW.MINECOOKIES.ORG</a>',
        },

        row_5: {
            heading: 'Was passiert, wenn ich Cookies ablehne?',
            para_1: 'Wenn Sie Cookies ablehnen, werden sie beim Surfen nicht registriert. Sie müssen sich darüber im Klaren sein, dass Sie mit der Deaktivierung von Cookies auch einige Funktionen der Website deaktivieren, was dazu führen kann, dass die Website möglicherweise nicht richtig funktioniert.',
        },

        row_6: {
            heading: 'Warum informieren wir über Cookies?',
            para_1: 'Wir informieren über Cookies, weil alle dänischen Websites dazu verpflichtet sind, darüber zu informieren, vgl. Exekutivverordnung zu Informations- und Zustimmungserfordernissen beim Speichern von oder Zugreifen auf Informationen im Endgerät des Endbenutzers.',
        },

        row_7: {
            heading: 'Sie möchten uns kontaktieren?',
            link: 'Wenn Sie ungelöste Fragen haben oder weiter mit uns Kontakt aufnehmen möchten, verweisen wir Sie auf <a href="https://parkeringskompagniet.dk/kontakt/" class="text-pk-blue">kontakt siden</a>',
        },
    },
    ReceptionUser: {
        countryError: 'CountryCode wurde nicht ausgewählt',
        registrationError: 'Registrierungs-Nr. wurden nicht gewählt',
        emailError: 'E-Mail wurde nicht ausgewählt',
        customEndDateError:
            'Endzeit auswählen (mindestens 30 Minuten ab der aktuellen Zeit)',
        phoneNumberError: 'Telefonnummer wurde nicht ausgewählt',
        acceptError: 'Bedingungen akzeptieren wurden nicht ausgewählt',
        countryCodeError: 'Ländercode wurde nicht ausgewählt',
    },
    'Select the Date of Parking': 'Wählen Sie das Parkdatum',
    'Enter your Full Name': 'Geben Sie Ihren vollständigen Namen ein',

    Phonenumber: 'Telefonnummer',
    'Enter your Email': 'Email adresse',
    'Choose your enddate': 'Enddatum',
    'Valid from': 'Gültig ab',
    'Valid until': 'Gültig bis',
    'Create new guest parking': 'Neuen Gästeparkplatz erstellen',
    'Custom end date': 'Wählen Sie die Endzeit selbst',
    'Max time in min (0 is no limit)':
        'Maximale Zeit in Minuten (0 = unbegrenzt)',

    'Please enter your registration number':
        '* Bitte geben Sie Ihre Registrierungsnummer ein.',
    'Please enter your E-mail address':
        '* Bitte geben Sie Ihre E-Mail-Adresse ein.',
    'Please choose a date/time':
        '* Das Endzeitpunkt sollte mindestens 30 Minuten nach der aktuellen Zeit sein',
    'Please enter your phone number':
        '* Bitte geben Sie Ihre Telefonnummer ein',
    'Please accept our terms to continue': '* Erforderlich',

    ConfirmPermit: {
        lineOne: 'Er is nu een gastenparkeerplaats ingericht bij',
        for: 'voor',
        on: 'op',
        at: 'bij',
        lineTwo: 'die geldig is tot en met',
    },
    'Receive SMS?': 'Sms ontvangen?',
    'Guest Parking has been created!': 'Parkeren voor gasten is gemaakt!',
    'A Guest parking permit has now been created':
        'Er is nu een Parkeervergunning voor gasten aangemaakt',

    // Entities

    Reception: 'Rezeption',
    HotelReception: 'Hotelrezeption',
    EmployeeReception: 'MitarbeiterEmpfang',
    TimesReception: 'Zeiten Rezeption',
    HotelAdmin: 'Hotelverwaltung',
    ConfirmationReception: 'Bestätigungsempfang',
    HotelReceptionConfirmation: 'Bestätigung der Hotelrezeption',
    StayNTouchReception: 'Stay-N-Touch-Empfang',
    APIPartner: 'API-Partner',
    SMSConfirmationReception: 'SMS-Bestätigungsempfang',
    Guest_parking_created:
        'Ein Gästeparkplatz für {{regNo}} wurde erstellt, gültig bis {{date}}.',
    'Something went wrong. Please try again.':
        'Er is iets misgegaan. Probeer het alstublieft opnieuw.',
    Enter_registration_number: 'Geben Sie Ihr Nummernschild ein',
    Confirm: 'Bestätigen',
    Clear_all: 'Alles löschen',

    View: 'Aussicht',
    Edit: 'Bearbeiten',
    AppFines: 'AppFines',
    AppPermits: 'App-Berechtigungen',
    NOT_DEFINED: 'NICHT DEFINIERT',
    SELF_TICKETING: 'SELF_TICKETING',
    COMBI: 'KOMBI',
    P_WARDEN: 'P_WÄRTER',
    CAMERA_CONTROL: 'KAMERA_STEUERUNG',
    Partner: 'Partner',
    Private: 'Privatgelände',
    Company: 'Gesellschaft',
    'Not Handled': 'Nicht behandelt',
    'Not handled': 'Nicht behandelt',
    Approved: 'Genehmigt',
    Rejected: 'Abgelehnt',
    'Other - write a comment in the note':
        'Sonstiges - schreiben Sie einen Kommentar in die Notiz',
    'Outside Booth': 'Außenstand',
    'No permission to the area': 'Keine Erlaubnis für das Gebiet',
    'Parking disc exceeded': 'Parkscheibe überschritten',
    'Parking or full stop on prohibited area':
        'Parken oder Vollstopp auf verbotenem Gebiet',
    'Parking on fire access road': 'Parken auf Feuerwehrzufahrt',
    'Handicapped parking without permission':
        'Behindertenparkplatz ohne Erlaubnis',
    Deleted: 'Gelöscht',
    'Guest Parking': 'Gästeparkplatz',
    Resident: 'Bewohner',
    'Paid ticket': 'Bezahltes Ticket',
    'Employee paid ticket': 'Vom Mitarbeiter bezahltes Ticket',
    'Employee upper basement': 'Mitarbeiter im Obergeschoss',
    'Employee lower basement': 'Mitarbeiter im Untergeschoss',
    Permanent: 'Dauerhaft',
    License: 'Lizenz',
    Reminder: 'Erinnerung',
    'Debt collection - Paragraph': 'Inkasso - Absatz',
    Ended: 'Beendet',
    Foreign: 'Ausländisch',
    Paused: 'Angehalten',
    'Debt collection - Paragraph - installment agreement with demand':
        'Inkasso - Paragraph - Ratenzahlungsvereinbarung mit Forderung',
    'Debt collection - Paragraph - with demand':
        'Inkasso - Paragraph - mit Nachfrage',
    'Debt collection - Liquidation': 'Inkasso - Liquidation',
    'Debt collection - Liquidation - installment agreement with demand':
        'Inkasso - Liquidation - Ratenzahlungsvereinbarung mit Mahnung',
    'Complaint Council': 'Beschwerderat',
    All: 'Alle',

    // Subscription
    Next: 'Nächste',
    Previous: 'Vorherige',
    Submit: 'Einreichen',
    Subscribers: 'Abonnenten',
    'Already subscribed': 'Bereits abonniert',
    'E.g.': 'z.B.',
    '12th Star Avenue': 'Sternenstraße 12',
    'Step 1: Which parking lot do you want to park at?':
        'Schritt 1: Auf welchem Parkplatz möchten Sie parken?',
    'Step 2: Which license plate does your car have?':
        'Schritt 2: Welches Nummernschild hat Ihr Auto?',
    'Step 3: When should your subscription start?':
        'Schritt 3: Wann soll Ihr Abonnement beginnen?',
    'Step 4: Your monthly price is based on the selected parking lot, and will be:':
        'Shritt 4: Ihr monatlicher Preis richtet sich nach dem gewählten Stellplatz und ist:',
    'Step 5: To be allowed to create a subscription for this private parking lot, you must enter a pin code below. Next, click on Create to go to the payment site. Once you are done, you will be redirected back to our page, where you can find your subscription in the table.':
        'Shritt 5: Um ein Abonnement für diesen Privatparkplatz erstellen zu können, müssen Sie unten einen PIN-Code eingeben. Klicken Sie anschließend auf Erstellen, um zur Zahlungsseite zu gelangen. Sobald Sie fertig sind, werden Sie auf unsere Seite zurückgeleitet, wo Sie Ihr Abonnement in der Tabelle finden.',
    'Step 5:  Click on Create to go to the payment site. Once you are done, you will be redirected back to our page, where you can find your subscription in the table.':
        'Shritt 5:  Klicken Sie auf Erstellen, um zur Zahlungsseite zu gelangen. Wenn Sie fertig sind, werden Sie auf unsere Seite zurückgeleitet, wo Sie Ihr Abonnement in der Tabelle finden.',
    'Below you can below see the available days, times and prices':
        'Sie können unten die verfügbaren Tage, Zeiten und Preise sehen',
    'Subscription Info': 'Abonnement-Info',
    'Monthly price': 'Monatlicher Preis',
    'Last recurring payment date': 'Datum der letzten wiederkehrenden Zahlung',
    'Car Info': 'Auto-Info',
    'Enter registration number': 'Nummernschild eingeben',
    'Buy monthly subscription': 'Monatsabonnement kaufen',
    'Buy short ticket': 'Einzelticket kaufen',
    'The price is': 'Der Preis beträgt',
    'kr pr. month, but the price on specific parking spots may vary':
        'kr pr. Monat, aber der Preis für bestimmte Parkplätze kann variieren',
    'Registration number': 'Registrationsnummer',
    'PIN code': 'Geheimzahl',
    'Email id already registered with us':
        'Die E-Mail ist bereits bei uns registriert',
    'Buy ticket': 'Ticket kaufen',
    'Get ticket': 'Ticket besorgen',
    'Permit id': 'Parkausweis id',
    'Last payment': 'Letzte Zahlung',
    'Price per period': 'Preis pro Periode',
    'Stop subscription': 'Abonnement beenden',
    'Price: 500 kr. per month (normal price is 625 kr. per month - The discount is active for the rest of 2023)':
        'Preis: 500 kr. pro Monat (normaler Preis ist 625 kr. pro Monat - Der Rabatt ist für den Rest des Jahres 2023 aktiv)',
    'Payment Options': 'Zahlungsmöglichkeiten',

    // FAQs
    'How do I change the numberplate on an existing permit?':
        'Wie ändere ich das Nummernschild eines bestehenden Ausweises?',

    'How do I extend a permit?': 'Wie verlängere ich eine Erlaubnis?',
    "What do I do if something doesn't work?": `Was mache ich, wenn etwas nicht funktioniert?`,
    'How often do you pay out customer shares?': `Wie oft zahlen Sie Kundenanteile aus?`,
    'Why am I being paid for a parking fee that is several years old now?': `Warum wird mir eine mehrere Jahre alte Parkgebühr bezahlt?`,
    'Status and their meanings': 'Status und ihre Bedeutung',

    answer_5: {
        paragraph: `Geben Sie auf der Seite „Genehmigungen“ das Nummernschild des Autos in das Suchfeld ein und drücken Sie die Eingabetaste/Suchen. Klicken Sie in der Liste unten auf die entsprechende Berechtigung. Im 'reg. NEIN.' Feld auf die neue Registrierungsnummer ändern. Überprüfen Sie dann, ob der Parkplatz, das Enddatum und der Rest korrekt aussehen, und klicken Sie auf „Aktualisieren“.`,
    },
    answer_6: {
        paragraph: `Geben Sie auf der Seite „Genehmigungen“ das Nummernschild des Autos in das Suchfeld ein und drücken Sie die Eingabetaste/Suchen. Klicken Sie in der Liste unten auf die entsprechende Berechtigung. Im 'reg. NEIN.' Feld auf die neue Registrierungsnummer ändern. Überprüfen Sie dann, ob der Parkplatz, das Enddatum und der Rest korrekt aussehen, und klicken Sie auf „Aktualisieren“.`,
    },
    answer_7: {
        paragraph: `Oft hilft es, sich ab- und wieder anzumelden, daher empfehlen wir Ihnen, dies immer zuerst zu tun. Wenn es immer noch nicht funktioniert, können Sie uns jederzeit eine E-Mail an service@oparko.com schreiben. Damit wir Ihnen schneller helfen können, senden Sie uns gerne Screenshots des Problems (falls vorhanden) Wenn Sie hier und jetzt Hilfe benötigen, ist unser Telefon Montag, Dienstag und Donnerstag 11-15 Uhr und Freitag 11-15 Uhr besetzt. 13. Rufen Sie 8874 3194 an.`,
    },
    answer_8: {
        paragraph: `In der von uns abgeschlossenen Kooperationsvereinbarung/Vertrag finden Sie Antworten, wie oft Kundenanteile ausbezahlt werden. Der Standardwert ist einmal alle drei Monate.`,
    },
    answer_9: {
        paragraph: `Wie schnell eine Parkgebühr nach Ausstellung bezahlt wird, kann von „sofort“ bis „nie“ variieren. Dies bedeutet, dass Parkgebühren, die vor Monaten oder Jahren ausgestellt wurden, möglicherweise auf Ihrer heutigen Auszahlung erscheinen, da sie seit unserer letzten Abrechnung mit Ihnen bezahlt wurden.`,
    },
    answer_10: {
        paragraph1: `Aktiv: Die Parkgebühr wird in unserem System erstellt.`,
        paragraph2: `Samllung: Die Parkgebühr wurde von unserem Geschäftspartner zum Inkasso geschickt und ein Schreiben an den Eigentümer des Autos gesendet`,
        paragraph3: `Bezahlt: Wenn die Parkgebühr bezahlt wurde, ändert sich der Status auf bezahlt`,
        paragraph4: `Erledigt: Der Kundenanteil der Parkgebühr wurde ausbezahlt `,
        paragraph5: `Versteckt: Die Parkgebühr wurde storniert`,
        paragraph6: `Geschützt: Das Auto gehört einer Person, die nicht in Dänemark lebt`,
    },

    // weekdays
    Mon: 'Mo',
    Tue: 'Di',
    Wed: 'Mi',
    Thur: 'Do',
    Fri: 'Fr',
    Sat: 'Sa',
    Sun: 'So',
    Monday: 'Montag',
    Tuesday: 'Dienstag',
    Wednesday: 'Mittwoch',
    Thursday: 'Donnerstag',
    Friday: 'Freitag',
    Saturday: 'Samstag',
    Sunday: 'Sonntag',

    Hour: 'Stunde',
    hour: 'stunde',
    hours: 'Stunden',
    Day: 'Tag',
    day: 'tag',
    'half-hour': 'halbe Stunde',
    'half-hours': 'halbe Stunden',
    days: 'Tage',
    Week: 'Week',
    weeks: 'weeks',
    Month: 'Month',
    months: 'months',
    'Number of': 'Anzahl von',
    First: 'Erstens',
    to: 'zu',
    Free: 'Kostenlos',
    'Subscription per month': 'Abonnement pro Monat',
    'No limit on': 'Keine Begrenzung auf',
    After: 'Nach',

    // popup box - accepted
    accepted_title: 'Purchase accepted',
    accepted_description1: 'Ihr Parkscheinkauf wurde durchgeführt',
    accepted_description2:
        'Wenn im vorherigen E-Mail-Feld eine E-Mail angegeben wurde, wird eine Bestätigungs-E-Mail an die angegebene E-Mail-Adresse gesendet.',
    accepted_description3:
        'Als Bestätigung der Erlaubnis können Sie verwenden:',
    accepted_description4: 'Auszug der abgebuchten Banküberweisung',
    accepted_description5: 'Ausschnitt der MobilePay-Zahlung',
    accepted_description6: 'Gesendete Bestätigungs-E-Mail',
    accepted_description7:
        'Bei weiteren Fragen und/oder Zweifeln bezüglich der Zahlung kontaktieren Sie uns bitte unter:',
    accepted_description8: 'Das Fenster kann nun geschlossen werden.',

    Ok: 'OK',
    // popup box - successfull
    successfull_title: 'Willkommen bei Oparko!',

    successfull_description: 'Ihr Abonnement ist jetzt aktiv.',

    successfull_description1:
        'Sie haben alle Informationen zu Ihrem Abonnement per E-Mail erhalten. Sie können Details zu Ihrem Abonnement direkt auf der Plattform einsehen und aktualisieren, indem Sie sich einloggen.',

    successfull_description2:
        'Sie können jetzt dieses Fenster schließen, um auf Ihr Konto zuzugreifen.',

    declined_title: 'Leider gab es einen Fehler bei Ihrer Zahlung',

    declined_description:
        'Ihr Abonnement wurde dennoch erstellt, ist jedoch nicht aktiv. Das bedeutet, dass wir keine Zahlungen von Ihnen vornehmen werden, und Sie keine aktive Erlaubnis haben, Ihr Auto zu parken.',

    declined_description1:
        'Sie haben alle notwendigen Informationen erhalten, um sich über Ihre E-Mail auf unserer Plattform anzumelden. Über unsere Plattform können Sie den Zahlungslink finden, um einen weiteren Zahlungsversuch zu unternehmen, falls Sie dies wünschen.',

    declined_description2:
        'Alternativ können Sie den unten stehenden Link verwenden, um es erneut zu versuchen:',

    declined_description3: 'Oder kontaktieren Sie hier den Oparko-Support',

    declined_description4:
        'Wenn Sie Fragen zur Zahlung haben, können Sie uns kontaktieren bei',
    ticket_service_disclaimer_1:
        'Bitte beachten Sie, dass eine vorzeitige Beendigung des Parkausweises nicht möglich ist. Es ist nicht möglich, Ihren Parkausweis zu verlängern, wenn Sie ihn behalten möchten, müssen Sie einen neuen Parkausweis kaufen, wenn der erste abgelaufen ist. Darüber hinaus ist es nicht möglich, eine einmal erstellte Genehmigung zu stornieren.',
    ticket_service_disclaimer_2:
        'Wir weisen insbesondere darauf hin, dass die Verantwortung für die Richtigkeit der bereitgestellten Informationen vollumfänglich bei der Partei liegt. Gleichzeitig lehnt das Parkhaus die Verantwortung für eventuelle Fehler während der Erstellung ab. Mit dem Kauf eines Parktickets stimmen Sie auch unseren AGB zu. Sie stimmen daher auch unserer Richtlinie zu personenbezogenen Daten zu.',
    subscription_service_disclaimer:
        'Wir weisen insbesondere darauf hin, dass die Verantwortung für die Richtigkeit der bereitgestellten Informationen vollumfänglich bei der Partei liegt. Gleichzeitig lehnt das Parkhaus die Verantwortung für eventuelle Fehler während der Erstellung ab. Wenn Sie ein Abonnement kaufen, stimmen Sie auch unseren Allgemeinen Geschäftsbedingungen zu. Sie stimmen daher auch unserer Richtlinie zu personenbezogenen Daten zu',

    // Months
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Mär',
    Apr: 'Apr',
    May: 'Mai',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Oct: 'Okt',
    Nov: 'Nov',
    Dec: 'Dez',

    // redirectFromPK
    'Important announcement': 'Wichtige Ankündigung',
    'Welcome! You have landed here because Parkeringskompagniet has now become':
        'Willkommen! Sie sind hier gelandet, weil Parkeringskompagniet jetzt geworden ist',
    'We have upgraded to a new management platform to offer you an even better user experience':
        'Wir haben ein Upgrade auf eine neue Verwaltungsplattform durchgeführt, um Ihnen ein noch besseres Benutzererlebnis zu bieten.',
    'What should you do now?': 'Was sollten Sie jetzt tun?',
    'Your existing information is automatically transferred. You just have to log in from the new platform now':
        'Ihre vorhandenen Daten werden automatisch übernommen. Sie müssen sich jetzt nur noch auf der neuen Plattform anmelden',
    'New Functions': 'Neue Funktionen',
    'The new platform can do everything you are used to, plus much more!':
        'Die neue Plattform kann alles, was Sie gewohnt sind, und noch viel mehr!',
    'Do you have questions? Contact us at service@oparko.com':
        'Habt ihr Fragen? Kontaktieren Sie uns unter service@oparko.com',
    'E-mail at': 'E-mail an',
    'Call at': 'Anrufen unter',
    'Phone hours:': 'Telefonzeiten:',
    'Go to new admin platform': 'Zur neuen Admin-Plattform gehen',

    // Pay fine
    'How to appeal': 'How to appeal',
    'Get documentation': 'Get documentation',
    'Complaint form': 'Complaint form',
    'Select a reason': 'Select a reason',
    Appeal: 'Appeal',
    'Submit an appeal': 'Submit an appeal',
    'Pay fine': 'Pay fine',
    'License plate': 'License plate',
    'Fine id': 'Fine id',
    retrieve_fine_documentation_text:
        "Retrieve fine documentation. Note that not all fines will result in documentation. The 'Fine id' is the id that comes with your fine when you receive it in the mail.",
    Subject: 'Subject',
    'Valid permit': 'Valid permit',
    'Type in complaint here': 'Type in complaint here',
    Attachments: 'Attachments',
    'Select files': 'Select files',
    'Select file': 'Select file',
    complaint_form_text:
        'All inquiries regarding parking charges (complaints, payments, reminders or the like) are only received in writing using the form below. It is important that we receive all information in writing.',
    'Fine documentation': 'Fine documentation',
    Price: 'Price',
    Offense: 'Offense',
    Location: 'Location',
    Continue: 'Continue',
    Topic: 'Topic',
    'Why you received a control fee': 'Why you received a control fee',
    'Incorrect Fine ID or Reg no. Please check again!':
        'Falsche Gebühren-ID oder Kennzeichen. Bitte überprüfen Sie erneut!',
    pay_fine_documentation_error_message: `Unable to retrieve documentation. Check if 'Fine id' and 'License plate' are correct.`,
};

export default deutsche;
