const english = {
    // Generic
    test: 'test',
    Update: 'Update',
    Cancel: 'Cancel',
    Create: 'Create',
    Delete: 'Delete',
    'Delete singular': 'Delete',
    Upload: 'Upload',
    Close: 'Close',
    Read: 'Read',
    'Generate Report': 'Generate Report',
    'Search the table': 'Search the table',
    Page: 'Page',
    of: 'of',
    Images: 'Images',
    Dates: 'Dates',
    Date: 'Date',
    Note: 'Note',
    Status: 'Status',
    Country: 'Country',
    'Select all': 'Select all',
    Allow: 'Allow',
    Business: 'Business',
    Search: 'Search',
    More: 'More',
    Hide: 'Hide',
    Other: 'Other',
    Save: 'Save',
    Unknown: 'Unknown',
    Choose: 'Choose',
    Website: 'Website',
    more: 'more',
    'Created date': 'Created date',
    'Last login web': 'Last login web',
    'Last login app': 'Last login app',
    Parkinglots: 'Parkinglot',

    // User
    Username: 'Username',
    Password: 'Password',
    'Created By': 'Created By',
    'Created by': 'Created by',
    'Created Date': 'Created Date',
    Admin: 'Admin',

    // Menu
    Home: 'Home',
    Dashboard: 'Dashboard',
    'Parking Lots': 'Parking Lots',
    'Map of Parking Lots': 'Map of Parking Lots',
    Users: 'Users',
    Permits: 'Permits',
    Fines: 'Fines',
    Tickets: 'Tickets',
    Customers: 'Customers',
    Backoffice: 'Backoffice',
    Map: 'Map',
    Statistics: 'Statistics',
    Subscriptions: 'Subscriptions',
    Finance: 'Finance',
    'Ticket Service': 'Buy a permit',
    'Buy a permit': 'Buy a permit',
    'Get a permit': 'Get a permit',
    'Private Ticket Service': 'Get a permit',
    Reports: 'Reports',
    Rent: 'Rent',
    Prices: 'Prices',
    'Short term': 'Short term',
    Rental: 'Pricing Terms',

    // Settings
    Settings: 'Settings',
    'User Details': 'User Details',
    Language: 'Language',
    'Parking Lot': 'Parking Lot',
    'Parking lot': 'Parking lot',
    Street: 'Street',
    'Street Number': 'Street Number',
    ZipCode: 'ZipCode',
    City: 'City',
    'Notification settings': 'Notification settings',
    'WELCOME TO': 'WELCOME TO',

    // Login
    Login: 'Login',
    'Forgot Password': 'Forgot Password',
    Email: 'Email',
    'Reset Password': 'Reset Password',
    'Password reset email sent. Please check your email.':
        'Password reset email sent. Please check your email',
    'Something went wrong': 'Something went wrong',
    'Wrong username or password': 'Wrong username or password',
    'Error resetting password, please retry':
        'Error resetting password, please retry',
    'Digital parking solution for every need':
        'Digital parking solution for every need',

    // Search bar
    'None selected': 'None selected',
    'All selected': 'All selected',
    'Only active': 'Only active',
    'Show cancelled': 'Show cancelled',

    // Frontoffice
    'Latest fines': 'Latest fines',
    'No data available!': 'No data available!',
    'There were no datapoints to show on this graph':
        'There were no datapoints to show on this graph',
    'Good fines': 'Good fines',
    Annulled: 'Annulled',
    'Could not fetch fines - please refresh and try again':
        'Could not fetch fines - please refresh and try again',
    'Something went wrong, could not find any users - Please refresh and try again.':
        'Something went wrong, could not find any users - Please refresh and try again.',
    Activated: 'Activated',
    Customer: 'Customer',
    'User list': 'User list',
    'Create User': 'Create User',
    Yes: 'Yes',
    No: 'No',
    'Permit list': 'Permit list',
    'Create Permit': 'Create Permit',
    'Start date': 'Start date',
    'Start Date': 'Start Date',
    'End date': 'End date',
    'End Date': 'End Date',
    Type: 'Type',
    'Something went wrong, could not find any parking lots - Please refresh and try again.':
        'Something went wrong, could not find any parking lots - Please refresh and try again.',
    'Parking Lots List': 'Parking Lots List',
    Address: 'Address',
    'Parking Map': 'Parking Map',
    'Something went wrong, could not find any hourly data - Please refresh and try again.':
        'Something went wrong, could not find any hourly data - Please refresh and try again.',
    'Something went wrong, could not find any daily data - Please refresh and try again.':
        'Something went wrong, could not find any daily data - Please refresh and try again.',
    'Something went wrong, could not find any look up data - Please refresh and try again.':
        'Something went wrong, could not find any look up data - Please refresh and try again.',
    'Hourly visits count': 'Hourly visits count',
    'Daily visits count': 'Daily visits count',
    'Fine list': 'Fine list',
    'Could not generate the report - please refresh and try again':
        'Could not generate the report - please refresh and try again',
    'Country code': 'Country code',
    cancelFine: 'Why are you canceling this Fine? (Min. 20 characters)',
    Comments: 'Comments',
    'Cancelled fines': 'Cancelled fines',
    'Active fines': 'Active fines',
    'Total fines': 'Total fines',
    'Parking Lot list': 'Parking Lot list',
    'Reg. nr.': 'Reg. no.',

    // Backoffice
    'Customer list': 'Customer list',
    'Something went wrong in searching for customers':
        'Something went wrong in searching for customers',
    'Create Customer': 'Create Customer',
    Name: 'Name',
    'Create Fine': 'Create Fine',
    'Generate DMR report': 'Generate DMR report',
    'Advanced Report': 'Advanced Report',
    'Mass change': 'Mass change',
    'Customers info': 'Customers info',
    'Accounting info': 'Accounting info',
    'Create p-lot': 'Create p-lot',
    'Organisation list': 'Organisation list',
    Add: 'Add',
    'Parking Lot Info': 'Parking Lot Info',
    'Add ruleset': 'Add ruleset',
    Rules: 'Rules',
    'Show Spots and Groups': 'Show Spots and Groups',

    // Modals
    'Send Password': 'Send Password',
    'Settlement dates': 'Settlement dates',
    Offence: 'Offence',
    Contractor: 'Contractor',
    'Incident information': 'Incident information',
    'Incident Information': 'Incident Information',
    'Leave a comment...': 'Leave a comment...',
    'Cancel Fine': 'Cancel Fine',
    Latitude: 'Latitude',
    'Loading...': 'Loading...',
    'Could not find the parking lot you were looking for.':
        'Could not find the parking lot you were looking for.',
    'Successfully updated the parking lot!':
        'Successfully updated the parking lot!',
    'Successfully created new parking lot!':
        'Successfully created new parking lot!',
    'You can now close the modal.': 'You can now close the modal.',
    'Geographic coordinate': 'Geographic coordinate',
    Longitude: 'Longitude',
    'Parking lot details': 'Parking lot details',
    'Rules for parking': 'Rules for parking',
    'Rules for guest parking': 'Rules for guest parking',
    'Show customer': 'Show customer',
    'Number of active permits created by one user at the same time':
        'Number of active permits created by one user at the same time',
    'Maximum length for created permits in app':
        'Maximum length for created permits in app',
    'Note for app': 'Note for app',
    Warning: 'Warning',
    'Changing Reg. No.': 'Changing Reg. No.',
    'Country and reg. no.': 'Country and reg. no.',
    'If reg. no. is not correct and you need to adjust it, "Registered before" will not update. Please doublecheck your changes are correct before applying them':
        'If reg. no. is not correct and you need to adjust it, "Registered before" will not update. Please doublecheck your changes are correct before applying them',
    'Control Type': 'Control Type',
    'Camera Fines': 'Camera Fines',
    'Camera fine list': 'Camera fine list',
    'Camera Settings': 'Camera Settings',
    'Camera control': 'Camera control',
    'Camera Control': 'Camera Control',
    'Guard control': 'Guard control',
    'Guard Control': 'Guard Control',
    'Self control': 'Self control',
    'Need for collection': 'Need for collection',
    'Parking Spots': 'Parking Spots',
    'Rent?': 'Rent?',
    Distribution: 'Distribution',
    'Amount of Duty': 'Amount of Duty',
    'Why are you canceling this Fine? - Minimum 20 characters':
        'Why are you canceling this Fine? - Minimum 20 characters',
    'Send and Cancel Fine': 'Send and Cancel Fine',
    'Could not find the fine you were looking for.':
        'Could not find the fine you were looking for.',
    'Could not find the permit you were looking for.':
        'Could not find the permit you were looking for.',
    'Belongs to': 'Belongs to',
    'Internal note': 'Internal note',
    'External note': 'External note',
    'Could not create the permit - close and try again please':
        'Could not create the permit - close and try again please',
    'Could not update the permit - close and try again please':
        'Could not update the permit - close and try again please',
    'User successfully created, you can close the modal again':
        'User successfully created, you can close the modal again',
    'User successfully updated, you can close the modal again':
        'User successfully updated, you can close the modal again',
    'User credentials': 'User credentials',
    'Confirm password': 'Confirm password',
    'The password matches': 'The password matches',
    'The password does not matches': 'The password does not matches',
    Permissions: 'Permissions',
    'Users to manage': 'Users to manage',
    'Activate User': 'Activate User',
    'Access to Website': 'Access to Website',
    'Access to App': 'Access to App',
    'Access to API': 'Access to API',
    'Handle Fines': 'Handle Fines',
    'Handle Permits': 'Handle Permits',
    User: 'User',
    Permit: 'Permit',
    Fine: 'Fine',
    'Update customer': 'Update customer',
    'Customer Type': 'Customer Type',
    Created: 'Created',
    Updated: 'Updated',
    'Connected Users': 'Connected Users',
    'Connected parking lots': 'Connected parking lots',
    'Total amount of users:': 'Total amount of users:',
    'Fine successfully updated, you can close the modal again':
        'Fine successfully updated, you can close the modal again',
    'Fine successfully created, you can close the modal again':
        'Fine successfully created, you can close the modal again',
    'Update Fine': 'Update Fine',
    'New Fine': 'New Fine',
    Owner: 'Owner',
    'Parking guard': 'Parking guard',
    'House number': 'House number',
    'House nr.': 'House nr.',
    'Open Parking Lot': 'Open Parking Lot',
    'Update parking lot': 'Update parking lot',
    'External Customers': 'External Customers',
    'Back to Fine': 'Back to Fine',
    'Update user': 'Update user',
    'Update User': 'Update User',
    'New user': 'New user',
    'Update subscription': 'Update subscription',
    'New subscription': 'New subscription',
    'Create subscription': 'Create subscription',
    'Create Subscription': 'Create Subscription',
    'Creating subscription...': 'Creating subscription...',
    'Show Expired': 'Show Expired',
    'Please select a company': 'Please select a company',
    "Customer's parking lots": "Customer's parking lots",
    'User Identity': 'User Identity',
    Identity: 'Identity',
    'User Identities': 'User Identities',
    'No identities for user': 'No identities for user',
    'Fine Details': 'Fine Details',
    'No one has commented or changed status yet.':
        'No one has commented or changed status yet.',
    'Leave a note on the incident': 'Leave a note on the incident',
    'Update Fines': 'Update Fines',
    'This will reset the password and email the new password. Do you want to continue?':
        'This will reset the password and email the new password. Do you want to continue?',
    'Parking Info': 'Parking Info',
    Organizations: 'Organizations',
    Organization: 'Organization',
    'Payout Details': 'Payout Details',
    'Bank Registration Number': 'Bank Registration Number',
    'Bank Account Number': 'Bank Account Number',
    '% for parking guard': '% for parking guard',
    '% for customer': '% for customer',
    '% for PK': '% for OPARKO',
    'Charge Distributions': 'Charge Distributions',
    'There are no Parking Lots': 'There are no Parking Lots',
    'There are no Users': 'There are no Users',
    'External Organizations': 'External Organizations',
    'Select image': 'Select image',
    'Outside booth': 'Outside booth',
    'No permit to the parking area': 'No permit to the parking area',
    'P-disc exceeded': 'P-disc exceeded',
    'Parking or stopping in prohibited area':
        'Parking or stopping in prohibited area',
    'Parking in fire lane': 'Parking in fire lane',
    'Handicap booth without permission': 'Handicap booth without permission',
    "Organization's Parking Lots": "Organization's Parking Lots",
    'Choose Parking bays': 'Choose Parking bays',
    'Permanent user': 'Permanent user',
    'New Permit': 'New Permit',
    'Update Permit': 'Update Permit',
    'Created for user': 'Created for user',
    Notes: 'Notes',
    'Permit Information': 'Permit Information',
    'Date not assigned': 'Date not assigned',
    'Note from creation': 'Note from creation',
    'Created at': 'Created at',
    'Updated at': 'Updated at',
    'Address name': 'Address name',
    'Arrival date': 'Arrival date',
    'Arrival Date': 'Arrival Date',
    'Depart. Date': 'Depart. Date',
    'Depart. date': 'Depart. date',
    Camera: 'Camera',
    'Camera note': 'Camera note',
    'Other - Leave a note': 'Other - Leave a note',
    'Total parking time': 'Total parking time',
    Approve: 'Approve',
    Decline: 'Decline',
    Back: 'Back',
    'Employee Paid Ticket': 'Employee Paid Ticket',
    'Street name': 'Street name',
    'Postal code': 'Postal code',
    'Fine price': 'Fine price',
    'Profile Image': 'Profile Image',
    'Parking Terms': 'Parking Terms',
    'No results found': 'No results found',
    'Buffer time in minutes': 'Buffer time in minutes',
    'Active permits': 'Active permits',
    'Duration of permits': 'Duration of permits',
    selected: 'selected',
    Distance: 'Distance',
    'Organization type': 'Organization type',
    'Organization address': 'Organization address',
    'Organization details': 'Organization details',
    'Create Organization': 'Create Organization',
    'Comment added by': 'Comment added by',
    'Status changed from': 'Status changed from',
    'Status changed by': 'Status changed by',
    'Not defined': 'Not defined',
    'Not Defined': 'Not Defined',
    'Self ticketing': 'Self ticketing',
    Combination: 'Combination',
    'P-guard': 'P-guard',
    'Registered before': 'Registered before',
    'Send receipt copy': 'Send receipt copy',
    'You already have a user': 'You already have a user',
    'Login to purchase an extra subscription':
        'Login to purchase an extra subscription',
    'Missing payment in min': 'Missing payment in min',
    'Open Subscriber': 'Open Subscriber',
    'Every permit row is clickable and show data in Permit Modal':
        'Every permit row is clickable and show data in Permit Modal',
    'Choosing multiple parking lots creates a singular permit for each address selected':
        'Choosing multiple parking lots creates a singular permit for each address selected',
    'These rules only affects the permits created from the app, not permits created by admin from Web':
        'These rules only affects the permits created from the app, not permits created by admin from Web',
    '0 = none and empty = unlimited': '0 = none and empty = unlimited',
    Location: 'Location',
    'Additional images': 'Additional images',
    'Fine rules': 'Fine rules',
    'Permit rules': 'Permit rules',
    'Connected organisations & users': 'Connected organisations and users',
    'Profile image': 'Profile image',
    'ID generated upon creation': 'ID generated upon creation',
    'Bank name': 'Bank name',
    'Bank country': 'Bank country',
    'Billing Email': 'Billing Email',
    'Payment frequency': 'Payment frequency',
    'Agreed by': 'Agreed by',
    'Agreed on (Date - Start)': 'Agreed on (Date - Start)',
    'Agreed on (Date - End)': 'Agreed on (Date - End)',
    'Connected parking lots & users': 'Connected parking lots and users',

    // Finance
    DKK: 'DKK',
    pcs: 'pcs',
    k: 'k',
    Revenue: 'Revenue',
    'Paid Permits': 'Paid Permits',
    'Coming soon': 'Coming soon',
    'Total revenue': 'Total revenue in DKK',
    'Total paid out': 'Total paid out',
    'Total outstanding': 'Total unsettled',
    'Revenue sources for permits in DKK': 'Revenue sources for permits in DKK',
    'Permits per parking operator': 'Permits per parking operator',
    'Number of parking tickets made through each parking operator.':
        'Number of parking tickets made through each parking operator.',
    'You have no permits to show': 'You have no permits to show',
    'Revenue by type in DKK': 'Revenue by type in DKK',
    'Revenue distribution from the car parks owned by your organisation. The graph visualizes the turnover of the different products offered.':
        'Revenue distribution from the car parks owned by your organisation. The graph visualizes the turnover of the different products offered. ',
    'Fines by status': 'Fines by status',
    'Post collections': 'Post collections',
    'Post collection': 'Post collections',

    finance_revenue_barchart:
        'Revenue in DKK per month for the current year separated by revenue type',

    revenue_by_type_explanation:
        'Revenue is based on the revenue generated from the parking lots that your organization has ownership of. The chart visualizes the revenue for each revenue type.',
    'Fines: Every fine that is paid or settled':
        'Fines: Every fine that is paid or settled',
    'Permits: Every permit that is paid': 'Permits: Every permit that is paid',
    'Subscriptions: Every payment on all subscriptions':
        'Subscriptions: Every payment on all subscriptions',
    post_collection_explanation: `Post collection: Calculated as 'collection fee' + 'price per hour' * 'hours parked more than permitted' and is an extra charge to parkers that have parked longer than their permits allowed them too and serves as an alternative to parking fees.`,

    'Active: status explained':
        'Active: A fine that has been created but is still in process',
    'Cancelled: status explained':
        'Cancelled: Charges cancelled by you or OPARKO',
    'Paid: status explained': 'Paid: The fee is paid to OPARKO',
    'Settled: status explained': 'Settled: Fine have been paid to customer',
    'Protected: status explained': 'Protected: Its on hold for now.',
    'Debt collection/Collectia: status explained':
        'Debt collection/Collectia: Debt collection is ongoing.',

    permit_revenue_sources_explanation:
        'The amount of revenue in DKK, that has been made on permits for each parking operator.',
    number_of_permits_per_parking_operator_explained:
        'The number of permits that have been made by each of the parking operators.',

    fine_status_distribution_explanation:
        'The percentage distribution of fine statuses.',
    'Please wait, your data is being prepared...':
        'Please wait, your data is being prepared...',

    // Statistic
    fee_search: 'Old App',
    fine_search: 'App - fine created',
    permit_search: 'App - permit created',
    scanner_used: 'App - Scanner used',
    'Shows a graph of app events for multiple days, sorted by hours.':
        'Shows a graph of app events for multiple days, sorted by hours.',
    'Shows a graph of app events, sorted by dates.':
        'Shows a graph of app events, sorted by dates.',
    'The use of the old app.': '- The use of the old app.',
    'Creation of permits in the oparko app.':
        '- Creation of permits in the oparko app.',
    'Creation of Fines in the oparko app':
        '- Creation of Fines in the oparko app',
    'Scanning of license plates in the oparko app.':
        '- Scanning of license plates in the oparko app.',
    'Permits and Subscriptions per month':
        'Permits and Subscriptions per month',
    'Show Day Graph Date': 'Show Day Graph Date',

    'Occupancy by permits': 'Occupancy by permits',
    'Total parking spots': 'Total parking spots',
    'Permits: Shows the amount of permits by period. If more than one week is chosen, the sum of all weeks will be shown on a give period on a weekday.':
        'Permits: Shows the amount of permits by period. If more than one week is chosen, the sum of all weeks will be shown on a give period on a weekday.',
    'Total parking spots: Shows the total amount of parking spots':
        'Total parking spots: Shows the total amount of parking spots',
    'Average hourly occupancy over the selected period.':
        'Average hourly occupancy over the selected period.',
    'Average daily occupancy over the selected period.':
        'Average daily occupancy over the selected period.',
    'Average monthly occupancy over the selected period.':
        'Average monthly occupancy over the selected period.',
    'Average for the chosen period': 'Average for the chosen period',
    'Fines for the chosen period': 'Fines for the chosen period',
    Occupancy: 'Occupancy',
    'Highest Occupancy': 'Highest Occupancy',
    'Lowest Occupancy': 'Lowest Occupancy',
    'Highest avg for the days in the period.':
        '- Highest avg for the days in the period.',
    'Lowest avg for the days in the period.':
        '- Lowest avg for the days in the period.',
    'Avg for the days in the period.': '- Avg for the days in the period.',
    no_permit: 'No Permit',
    permit: 'Permit',
    ticket: 'Ticket',
    subscription: 'Subscription',
    'Too many organisations selected': 'Too many organisations selected',
    'Can not generate report for the selected parking lots.':
        'Can not generate report for the selected parking lots.',
    'Can not generate report for the selected organisations.':
        'Can not generate report for the selected organisations.',

    Average: 'Average',
    Highest: 'Highest',
    Lowest: 'Lowest',
    'Hourly camera activity count': 'Hourly camera activity count',
    'Daily camera activity count': 'Daily camera activity count',
    'Camera activity': 'Camera activity',
    'App activity': 'App activity',
    Arrivals: 'Arrivals',
    Departures: 'Departures',
    'Show only fine activity': 'Show only fine activity',
    'Hourly app activity count': 'Hourly app activity count',
    'Daily app activity count': 'Daily app activity count',
    Scanner: 'Scanner',
    'Fine created': 'Fine created',

    // Date Picker
    Between: 'Between',
    From: 'From',
    To: 'To',
    Unit: 'Unit',
    Max: 'Maximum count',
    Min: 'Minimum count',
    Prior: 'Prior',
    Custom: 'Custom',
    Today: 'Today',
    'Since Yesterday': 'Since Yesterday',
    'This Week': 'This Week',
    Anytime: 'Anytime',
    'Last month': 'Last month',
    'Last 3 months': 'Last 3 months',
    'Last 12 months': 'Last 12 months',
    Details: 'Details',
    Ticket_Rule: 'Ticket Rule',
    Rule_sets: 'Rule Sets',
    InformationGuide: 'Information Guide',
    RuleSetsDescription:
        "Under 'Rule Sets', you can define unique ticket rules. You can make as many as you need:",
    PickDays: 'Choose which days the rules apply for',
    PickTime: 'Choose the time of day',
    PickUnit:
        'Choose the unit followed by the "minimum" and "maximum" number of hours/days that can be purchased with this rule',

    'Parking time exceeded': 'Parking time exceeded',
    'The permit will be made for every parking lot chosen, and based on the users start and end date.':
        'The permit will be made for every parking lot chosen, and based on the users start and end date.',

    // Table Values
    company: 'company',
    private: 'private',
    Inactive: 'Inactive',
    Active: 'Active',
    Paid: 'Paid',
    'Not paid': 'Not paid',
    Settled: 'Settled',
    Hidden: 'Hidden',
    Cancelled: 'Cancelled',
    Protected: 'Protected',
    'Guest parking': 'Guest parking',
    Ticket: 'Ticket',
    'Paid Ticket': 'Paid Ticket',
    'Only for backoffice': 'Only for backoffice',
    'Editable permit': 'Redigerbar tilladelse',
    Employee: 'Employee',
    'Permanent permit': 'Permanent permit',
    'Debt collection': 'Debt collection',
    Collectia: 'Collectia',
    'Active - system': 'Active - system',
    'Active - handled manually': 'Active - handled manually',
    'Active - manuel': 'Active - manuel',

    // Validation
    'Issues: ': `Issues: `,

    'no Name chosen, ': 'no Name chosen, ',
    'no Organization chosen, ': 'no Organization chosen, ',
    'no Street Name chosen, ': 'no Street Name chosen, ',
    'no Street Number chosen, ': 'no Street Number chosen, ',
    'no Postal Code chosen, ': 'no Postal Code chosen, ',
    'no City chosen, ': 'no City chosen, ',
    'no Latitude chosen, ': 'no Latitude chosen, ',
    'Latitude must be above -90, ': 'Latitude must be above -90, ',
    'Latitude must be below 90, ': 'Latitude must be below 90, ',
    'no Longitude chosen, ': 'no Longitude chosen, ',
    'Longitude must be above -90, ': 'Longitude must be above -90, ',
    'Longitude must be below 90, ': 'Longitude must be below 90, ',
    'no Type chosen, ': 'no type chosen, ',
    'parking terms do not accept more than 255 character':
        'parking terms do not accept more than 255 character',

    Reset: 'Reset',
    'Accept our terms': 'Accept our terms',
    'Enter your registration no.': 'Enter your registration no.',
    'phone number': 'phone number',
    email: 'email',
    'then press ok': 'and then press create',
    'Your permit ends': 'Your permit ends',
    'by today 23:59': 'by today 23:59',
    after: 'after',
    'after the creation': 'after the creation',
    minutes: 'minutes',
    and: 'and',
    until: 'at',
    'ends in': 'in ',

    'Reg. no.': 'Reg. no.',
    'Phone number': 'Phone number',
    Subscription_percentage_not_within_range: `Subscription '% for OPARKO' must be within the range 0% to 100%`,
    Permit_percentage_not_within_range: `Permit '% for OPARKO' must be within the range 0% to 100%`,
    Camera_control_percentage_not_within_range: `Camera control '% for OPARKO' must be within the range 0% to 100%`,
    Selfticketing_percentage_not_within_range: `Self ticketing '% for OPARKO' must be within the range 0% to 100%`,
    Guard_control_percentage_not_within_range: `Guard control '% for OPARKO' must be within the range 0% to 100%`,

    // Ticket Service validation
    'must be a positive integer': 'must be a positive integer',
    'Reg. no. have not been specified': 'Reg. no. have not been specified',
    'E-mail has not been specified': 'E-mail has not been specified',
    'Terms and conditions must be accepted':
        'Terms and conditions must be accepted',
    Issues: 'Issues',

    // Terms
    Terms_titles:
        'Terms and conditions for use of Oparko and parkingskompagniet.dk',
    Terms_para_1: `We store and transmit user information encrypted. The entered personal data is registered as described in our data policy. By accepting these terms and conditions, you also agree to be familiar with and to have accepted these terms and conditions for using Parkeringskompagniet’s products. For special rules on Oparko subscription parking, see below under "Oparko Subscription parking". Parkeringskompagniet may collect information and prepare statistics and reports in order to improve and deliver the best product and service to the User.`,
    Terms_main_point_1: {
        main_title: 'Application',
        sub_point_1: `These General Terms and Conditions (the "General Terms and Conditions") apply to the provision of Services from Parkeringskompagniet ApS, CVR no. 37127385, Trindsøvej 4, 8200 Aarhus, ("Parkeringskompagniet"), to users ("User") in Denmark.`,
        sub_point_2: `NOTE! Use of Parkeringskompagniet’s services requires that you are a registered owner or user of the vehicle or that you otherwise have permission to use and register the vehicle.`,
        sub_point_3: {
            sub_point_3_title: `All Services are provided in accordance with applicable law and in accordance with:`,
            sub_point_3_1: `any individually agreed terms and conditions, and`,
            sub_point_3_2: `these General Terms and Conditions.`,
            sub_point_3_3: `In the event of inconsistencies between the above provisions, they have priority in the above order.`,
        },
        sub_point_4: `Sections 5.1.4, 6.4 and 7.7 contain special provisions on correct and valid entry of the registration number and area code`,
        sub_point_5: `Point 5.3. contains special provisions on the use of the Oparko charge.`,
        sub_point_6: `By registering with Oparko (see point 5), the User agrees to be subject to these General Terms and Conditions. A binding agreement ("the Agreement") is entered into when Parkeringskompagniet has confirmed the Users registration and the User gains access to Parkeringskompagniet‘s Services.`,
        sub_point_7: `These General Terms and Conditions constitute the Agreement between Parkeringskompagniet and the User.`,
        sub_point_8: `These General Terms and Conditions apply to all transactions and registrations via Parkeringskompagniet, including *service2* and Oparko Subscription parking.`,
    },

    Terms_main_point_2: {
        main_title: 'Definitions',
        sub_point_1: {
            sub_point_1_title: `In these General Terms and Conditions and in connection with the Services, the following definitions apply:`,
            sub_point_1_1: `"Oparko" refers to the digital parking service that can be used in connection with payment for parking, via the App and the website.`,
            sub_point_1_2: `"App" refers to Parkeringskompagniet’s mobile phone application.`,
            sub_point_1_3: `"Oparko System" is the Oparko digital system for up-to-date parking, which the User gains access to via the App and/or via the Website.`,
            sub_point_1_4: `For "Website", refer Parkingkompagniet’s website <a class="text-pk-blue" href="https://parkeringskompagniet.dk/">https://parkeringskompagniet.dk/</a>.`,
            sub_point_1_5: `"Parking service" is the Users ability to manage parking spaces in the App;`,
            sub_point_1_6: `A "Parking space" is a physical area that matches a parking area marked on the overview map (a parking space does not mean a marked parking stall);`,
            sub_point_1_7: `"Parties" and "Party" refer to Parkeringskompagniet and/or the User;`,
            sub_point_1_8: `"User" is used by Parkeringskompagniet in these General Terms and Conditions in reference to the user of the car, who uses Parkeringskompagniet in relation to payment for parking.`,
            sub_point_1_9: `"Partner" refers to partners with whom Parkeringskompagniet cooperates;`,
            sub_point_1_10: `“Service(s)” has the meaning as defined in clause 3.1.1.`,
            sub_point_1_11: `The "parking fee" is the price for parking.`,
            sub_point_1_12: `"Active vehicle" refers to a vehicle that the user has chosen to automatically pay for in connection with parking in so-called ANPR parking spaces. Payment is made via the payment method that the User has indicated as preferred.`,
            sub_point_1_13: `"ANPR" stands for Automatic Number Plate Registration, which means that that the number plate of the vehicle is read automatically, and the registration number is registered in the parking system. Parking spaces with ANPR systems are marked with a camera in Oparko and it is clear from the signage in the parking lot that the number plate can be read.`,
            sub_point_1_14: `"Oparko Charge" refers to Parkeringskompagniet’s own and associated systems and apps from 3rd parties that can be used in connection with charging electric and hybrid cars. These are both charging stations, payment systems and apps.`,
        },
        sub_point_2: `Definitions may be provided elsewhere in these General Terms and Conditions.`,
    },

    Terms_main_point_3: {
        main_title: `General Terms and Conditions for the Services`,
        sub_point_1: {
            sub_point_1_title: `In general`,
            sub_point_1_1:
                'Parkeringskompagniet offers a digital system for parking ("Oparko"), whereby Parkeringskompagniet, (in collaboration with other Partners,) enables the User to manage the parking of vehicles ("Parking Service") and charging of electric vehicles. The parking service and the other services provided at any time by Parkeringskompagniet are collectively referred to as the "Services".',
        },
        sub_point_2: `For a detailed description of the Services, please refer to the information provided at any time on the Website and <a href="https://parkeringskompagniet.dk/" class="text-pk-blue">https://parkeringskompagniet.dk/.</a>`,
    },

    Terms_main_point_4_new: {
        main_title: 'Collection and processing of personal data',
        sub_point_1: {
            sub_point_title: 'Collection and processing of personal data',
            sub_point_1_1: `When the User uses Parkeringskompagniet's services, the User simultaneously consents to Parkeringskompagniet collecting and processing the personal data and personal data provided by the User in accordance with Parkeringskompagniet's applicable data policy (<a href="https://parkeringskompagniet.dk/datapolitik/" class="text-pk-blue">https://parkeringskompagniet.dk/datapolitik/</a>).`,
            sub_point_1_2:
                "Personal data is only stored if it is voluntarily provided and only for as long as it is relevant for Parkeringskompagniet. The data in question is stored on Parkeringskompagniet's internal network and can only be accessed with special rights.",
        },
        sub_point_2: {
            sub_point_title: "The user's rights",
            sub_point_2_1:
                'The user has the right to gain insight into the personal data stored about him at Parkeringskompagniet, and to have the information in question corrected.',
            sub_point_2_2: `The user has the right to have his information deleted from Parkeringskompagniet's systems.`,
            sub_point_2_3: `The user also has the rights granted to individuals by the European Union's General Data Protection Regulation.`,
        },
        sub_point_3: {
            sub_point_title: 'Duties of the user',
            sub_point_3_1:
                'When the user provides information about someone else, the user guarantees that this person has the right to provide the information to Parkeringskompagniet.',
        },
        sub_point_4: {
            sub_point_title: 'Transfer of Data',
            sub_point_4_1: `The user accepts that if Parkeringskompagniet deems it necessary to move the user's information to another platform, created with the same purpose as contained in this agreement, this will be done without prior notification and in accordance with applicable data protection legal regulations.`,
        },
        sub_point_5: {
            sub_point_title: 'Disclosure of information',
            sub_point_5_1: `The user's information is only passed on if it is necessary for the service provided by Parkeringskompagniet.`,
        },
    },
    Terms_main_point_5_new: {
        main_title: `Special terms and conditions for using Oparko`,
        sub_point_1: {
            sub_point_1_title: `Terms of use`,
            sub_point_1_1: {
                sub_point_1_1_title: `The parking service can only be used in parking lots and in parking areas/zones (each a "Parking Space"):`,
                sub_point_1_1_1: `Where Parkeringskompagniets signage is posted, and`,
                sub_point_1_1_2: `and have machines with Oparko labels, or otherwise are marked in a manner indicating that Oparko can be used.`,
            },
            sub_point_1_2: `When the Parking Service is used, the User must initiate a parking by activating either; The app; or an affiliated and approved payment system`,
            sub_point_1_3: `In order to ensure that parking has started correctly, the User must check that a confirmation of correct and valid started parking has been received via`,
            sub_point_1_3_1: `The App, if the App is used; or`,
            sub_point_1_3_2: `E-mail confirmation`,
            sub_point_1_4: `A parking is only valid, if the User has received confirmation from the App stating an ongoing correctly registered parking/payment.`,
            sub_point_1_5: `When using the Parking Service, the User must in this connection correctly enter the registration number of the parked vehicle and the area code of the Parking Space in question.`,
            sub_point_1_6: `It is the User's responsibility that the vehicle's registration number and the area code are entered correctly. Errors in registration and entry will result in a control fee.`,
            sub_point_1_7: `The user must ensure that the area code corresponds to the area code of the Car Park or E-charge stand in question, which appears on the Oparko signage in the car park or near the Car Park's machines/E-charge stands. The user is responsible for entering the correct area code and registration number. Oparko assumes no responsibility in connection with incorrectly entered registration numbers and/or area codes.`,
            sub_point_1_8: `When parking in parking lots where the vehicle's number plate is read, the area code must not be entered. The registration number used for parking must be entered correctly.`,
            sub_point_1_9: `It is a condition of validity for the parking registration that the registration number and the area code are entered correctly.`,
            sub_point_1_10: {
                sub_point_1_10_title: `'Registration of parking in Oparko can be done in one of the following ways:`,
                sub_point_1_10_1: `With manual registration, the User must specify the desired parking time, and in this connection will be informed of the price for the desired parking. Upon the User's confirmation of the entered registration, the stated amount is reserved. When the User stops the parking, the reserved amount is modified so that only the registered parking time is paid.`,
                sub_point_1_10_2: {
                    sub_point_1_10_2_title: `In case of automatic registration (when parking in ANPR spaces), the User must have activated the vehicle under "vehicles" prior to parking. When parking in an ANPR parking space, the parking starts at the entrance and ends and is settled at the exit. It is paid for the parking time used.`,
                    sub_point_1_10_2_1: `When the User activates the vehicle, the User agrees that automatic payment will be made via the preferred payment method when parking in an ANPR parking space.`,
                },
            },
            sub_point_1_11: `Oparko cannot be held responsible for the precision of the User's phone's GPS position, nor can Oparko be held responsible for mobile coverage/signal in/on the parking area. The user is responsible for the specified position corresponding to the position of the user's parked vehicle.`,
            sub_point_1_12: `Information about parking made by the User will be available to the User via "Parking", and such information will generally be available for at least 12 months.`,
        },
        sub_point_2: {
            sub_point_1_title: `In general`,
            sub_point_1_1: {
                sub_point_1_1_title: `The parking service makes it possible for the User to start a parking, and when starting manually, specify the provisional parking time, stop and end the started parking. Furthermore, it is possible for the User to extend the provisionally specified parking time.`,
                sub_point_1_2_1: `Reminder: It is possible to get a reminder before the manually initiated parking expires, provided that push notification permissions have been granted in the App. It is the users responsibility to ensure that parking is completed correctly, regardless of whether the reminder does not appear.`,
                sub_point_1_2_2: {
                    sub_point_1_2_2_title: `Stop parking: If the parking lot if left before the selected parking time expires, it is possible to actively stop the ongoing parking, so that payment is only charged for the period during which the parking has been active. The User is advised to always pay attention to the parking price, which appears on the signage and/or the payment machine. Depending on the price structure of the parking lot, in most parking lots it will be possible to stop parking in order to not pay for "unused time". NB! Some parking spaces require payment per started time period and possibly in advance, which means that in the event that such parking is stopped, the pre-approved amount will be charged regardless of the termination of the parking. If parking is not stopped, payment for "excess time" will not be refunded.`,
                    sub_point_1_2_2_1: `Excess time is to be understood as the period during which there is a valid registration of parking in the parking lot, but during which the vehicle has not been in the parkinglot.`,
                },
                sub_point_1_2_3: `Extension: Extension of a parking is done by pressing "extend" and then stating the new end time for the parking, after which the amount is approved. After this, the countdown of the parking period for the vehicle will be updated based on the extended parking time. Extension means that a new parking is initiated when the original parking expires.`,
                sub_point_1_2_4: `If it is not possible to make a purchase at a given time using the Oparko software, the User is responsible for paying for the parking in another way, e.g. by buying a valid P-ticket in a payment machine and otherwise observing the posted rules and conditions for parking. Reasons why Oparko cannot be used include, but are not limited to, for example: poor/no internet access, server downtime due to maintenance, updates, etc.`,
            },
            sub_point_1_2: `If the User was not the driver of the vehicle at the time of parking, but the user has indicated the vehicle as active, then the User has accepted that payment is automatically made via the preferred payment method. The internal relationship between the User and any third party is beyond Oparko’s control.`,
            sub_point_1_3: {
                sub_point_1_3_title: `If a control fee is imposed on the parked vehicle, for parking inconsistent with the rules and conditions that appear from the signage in the parking lot, the driver is liable for payment of such an control fee, regardless of who made the payment for the parking.`,
                sub_point_1_3_1: `"Control fee" is used in this context as a collective term for respectively inspection fee, control fee, parking fine etc.`,
            },
            sub_point_1_4: `In the event that the parking is carried out with a vehicle where the registered owner/user is a company, and the vehicle is subject to a control fee, it is the User responsibility to adequately document that the parking carried out was not done for business purposes. If the User cannot meet this burden of proof, the registered owner/user is deemed to be the correct debtor and, in this case, in the event of delayed payment, in addition to any reminders, a compensation amount of DKK 310.00 will be charged in accordance with claim no. 105 of 31/01/2013 § 2, subsection 1.`,
            sub_point_1_5: `If the User was not the driver of the vehicle at the time of parking, the User hereby agrees to have to disclose who the driver was. In the event of incomplete or incorrect information, the control fee, as well as any reminder fees, in their entirety, will be held against the User.`,
            sub_point_1_6: `The User payment of the relevant parking fee (including VAT) is determined based on the applicable rates (the "Parking Fee") in connection with the User ending a parking space, or alternatively starting a fixed parking time through the Parking Service.`,
            sub_point_1_7: `The user cannot make a claim for a reduction of the control fee on the basis of completed payment in Oparko. This condition applies regardless of whether the control fee is imposed on the basis of the User misregistration in Oparko or the User parking in violation of the other posted rules and conditions in the parking lot.`,
            sub_point_1_8: `The User is also not entitled to a refund of payment made via Oparko, unless the charge is due to an error on the part of Oparko, which the User did not have or could not influence.`,
        },
        sub_point_3: {
            sub_point_1_title: `Oparko charge terms`,
            sub_point_1_1: `Payment for vehicle charging can be made through apps in relation to the charging stations set up on the site. Payment for parking must be made separately through Oparko (see point 5).`,
            sub_point_1_2: `BEMÆRK: Ved parkering i parkeringsbåse reserveret til el-køretøjer under opladning, skal opladningen være registreret i den benyttede app, inden parkering og opladning påbegyndes. Desuden skal parkering være registreret i Oparko-appen, som angivet i punkt 5.`,
            sub_point_1_3: `The app used must be used to pay for charging via installed charging stations.`,
            sub_point_1_4: `When using Oparko in connection with the use of an installed charging stand, the User notifies Parkeringskompagniet that charging of the parked vehicle has begun, the preliminary charging time, completion of charging and, if necessary, about extended charging time.`,
            sub_point_1_6: {
                sub_point_1_6_title: `When paying and using an installed charging stand, a charging fee is paid.`,
                sub_point_1_6_1: `The charging fee is variable.`,
                sub_point_1_6_2: `Payment for the charging fee is made separately and the requirement appears in the app used before registration and payment are completed.`,
            },
            sub_point_1_7: `When registering, the other terms and conditions for using the Oparko app also apply, including that correct and valid registration and payment require correct entry of the area code and the registration number of the parked vehicle. It is the user's responsibility that the information entered is correct and verifiable and that payment can be completed.`,
            sub_point_1_8: {
                sub_point_1_8_title: `The area code that must be used in connection with payment for charging and parking can be seen from the signage set up outside the stalls, which are reserved for electric vehicles. It is the user's responsibility to enter the correct area code.`,
                sub_point_1_8_1: `When paying to an area code associated with an Oparko-charge charging station, but without using a parking bay reserved for Oparko-charge, there will be no refund of the payment.`,
            },
            sub_point_1_9: {
                sub_point_1_9_title: `It is the user's responsibility at all times to observe the signposted rules and conditions that appear in the car park. In the event of inconsistencies between the signage and these terms and conditions, the wording of the signage takes precedence. If parking is not carried out in accordance with the posted rules and conditions, an inspection fee may be imposed, which cannot subsequently be cancelled.`,
                sub_point_1_9_1: `Parking in stalls reserved for electric vehicles can only be done against payment and the vehicle must be charged for the entire period that the parking stall is used. If the vehicle is not being charged and/or the parking is not registered in Oparko, an inspection fee that cannot be canceled will be imposed.`,
                sub_point_1_9_2: `If problems arise in connection with the use of the Oparko-charge charging station, the user must contact Parkeringskompagniet on the telephone number stated on the charging station.`,
            },
            sub_point_1_10: `Information that the User has registered in connection with registration and payment is done in the same way as mentioned in section 4.`,
        },
    },
    Terms_main_point_6: {
        main_title: `Access to the Services, registration of information, etc.`,
        main_text: `Access to the Services, registration and payment for parking via Oparko can happen by the User being registered as user. Use of Oparko requires that a valid method of payment has been selected in the User’s Oparko App. Valid method of payment is; cash card. It is a condition of validity that chosen cards holds funds enough to cover the costs.`,
        sub_point_1: {
            sub_point_1_title: `When creating and later using Oparko, it is a prerequisite that the User provides correct and valid information`,
            sub_point_1_1: {
                sub_point_1_1_1: `Name`,
                sub_point_1_1_2: `Email address`,
                sub_point_1_1_3: `Mobile number`,
                sub_point_1_1_4: `Password`,
                sub_point_1_1_5: `Registration number, on at least one vehicle`,
                sub_point_1_1_6: {
                    sub_point_1_1_6_title: `Method of payment`,
                    sub_point_1_1_6_1: {
                        sub_point_1_1_6_1_title: `Groups of employees with access to the registered information:`,
                        sub_point_1_1_6_1_1: `Subcontractors (while performing support)`,
                        sub_point_1_1_6_1_2: `Parkeringskompagniet's Employees (while providing support in connection with User inquiries)`,
                    },
                },
            },
        },
        sub_point_2: `Parking is initiated via the App or <a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk.</a>`,
        sub_point_3: {
            sub_point_3_title: `In order to use Oparko, a valid payment method must be connected and selected in the App or at <a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk</a> prior to registering the parking.`,
            sub_point_1_1: {
                sub_point_1_1_1: `NOTE: It is not possible to change the means of payment in connection with an ongoing parking, which is why it is the User responsibility that the correct and valid means of payment is selected prior to parking.`,
            },
        },
        sub_point_4: {
            sub_point_4_title: `The instructions that appear on the screen must be followed:`,
            sub_point_1_1: {
                sub_point_1_1_1: `In order for the User to use Oparko to pay for parking, it is a condition of validity that the User registers a correct and valid registration number (number plate), area code, means of payment and parking time.`,
                sub_point_1_1_2: `It must be checked that the registered registration number and area code are correctly specified by the User.`,
                sub_point_1_1_3: `Oparko reserves the right to reject an application for creation and payment for parking.`,
                sub_point_1_1_4: `It is clarified that a valid payment for the parking has only been made if the registration number of the vehicle being parked has been entered correctly and that the entered area code matches the area code that appears on the Oparko signage applicable to the parking bay in question, where the vehicle located.`,
            },
        },
        sub_point_5: `If the parking is not validly registered, the vehicle risks being charged an control fee.`,
        sub_point_6: `Upon successful purchase/reservation, a confirmation is sent via e-mail to the e-mail address provided by the User`,
        sub_point_7: `When purchasing through Oparko, an unspecified parking stall is made available at the selected car park/parking location and during the selected period. The parking company cannot be held responsible if there are no available parking spaces (as this is not a booking). Parking should therefore not be purchased before the vehicle is properly parked in the parking lot.`,
        sub_point_8: `The User is not entitled to a refund of unused parking. See point 5.2.1.2`,
    },

    Terms_main_point_7: {
        main_title: `Obligations and responsibilities of the user`,
        sub_point_1: {
            sub_point_1_1: `The User is always responsible for providing correct information about the User and that relevant vehicles are correctly registered with Oparko. The User must log on to "My Account" immediately after creation/registration to ensure that the registered information is correct. Oparko can never be or become responsible for incorrect registrations/entries, regardless of registration method.`,
            sub_point_1_2: `The User is responsible for continuously ensuring that the payment method that is registered and used via Oparko is not blocked, is valid and that there is sufficient coverage on the registered payment method. Upon expiry of a registered means of payment, the User must provide Oparko with information (by updating the App under "payment methods") about a new means of payment. The information about a new means of payment must be given at the latest at the end of the calendar month preceding the calendar month in which the registered means of payment is no longer usable.`,
            sub_point_1_3: `Safe and proper storage of the login information is the responsibility of the User.`,
            sub_point_1_4: `Furthermore, it is the User's responsibility that the login information is not passed on or otherwise made available to unauthorized persons.`,
            sub_point_1_5: {
                sub_point_1_5_title: `It is solely the User's responsibility that the User's Oparko account is used and is only used by the right people.`,
                sub_point_1_5_1: {
                    sub_point_1_5_1_title: `The User must immediately inform Oparko (e.g. by contacting Oparko Customer Service or by updating the information in "My Account") if:`,
                    sub_point_1_5_1_1: `The User has reason to believe that an unauthorized person has gained access or knowledge of the Login information;`,
                    sub_point_1_5_1_2: `Registered information about the User has been changed and/or should be updated; or`,
                    sub_point_1_5_1_3: `The User no longer has a registered vehicle or no longer has to pay for parking with a registered vehicle,`,
                },
                sub_point_1_5_2: `The information must initially be provided by the User updating the information in Oparko themselves. It is the User's responsibility that no vehicles are registered for which the User does not have to pay. Oparko can thereby take the necessary precautions, for example to change/block the Login information and/or the User's account or ask the User to update the User's registered information. The user is also obliged to inform Oparko about other matters of importance for the Agreement and/or the delivery of the Services.`,
                sub_point_1_5_3: `The User is responsible for ensuring that the App is updated at all times. The correct functionality of mobile phones or other technical equipment is always the User’s responsibility. It is the User's responsibility that the media used is compatible with the Services and the App. The applicable functional requirements for the Services and the App at any time can be obtained by contacting Customer Service.`,
            },
            sub_point_1_6: `The User is always obliged to comply with applicable laws and regulations as well as the rules and conditions that appear from the signage at the parking lot in question where the User parks his vehicle.`,
            sub_point_1_7: `The User is responsible for starting parking correctly by specifying the parked vehicle's registration number and area code applicable to the parking lot used. The User is responsible for ensuring that the area code provided by the User is correct, even if the area code is suggested in the App's location function. NOTE: If the correct registration number and/or area code is not entered, no valid payment has been made for the parking, which is why the parked vehicle risks being charged an control fee.`,
            sub_point_1_8: `The user is responsible for ensuring that a started parking is completed. If the User does not specify a provisional end time or a total parking time when parking begins, the User is responsible for ending the parking manually. Ending the parking can be done via the App, or by contacting Oparko using the contact information that appears on the App, the website and the Oparko signs.`,
            sub_point_1_9: {
                sub_point_1_9_title: `If the Parking Service is not available or is out of order when the User needs to park or while the User is parking (e.g. for reasons attributable to the telephone, mobile phone or other technical equipment used by the User, or due to errors, interruptions or delay of telephone, internet or other communication networks), the User is responsible for payment being made in another way and as indicated by the posted signage in the parking lot (for example when paying in the relevant machine).`,
                sub_point_1_9_1: `The User is responsible for payment of any control fees or charges for improper parking (notified or collected in connection with parking restriction violations).`,
                sub_point_1_9_2: `If the parked vehicle is registered to a company, the parking is considered to have been carried out in a work context, which is why the Company in this situation will be considered the correct debtor.`,
            },
            sub_point_1_10: `The User has the option of viewing the parking history for the parking spaces the user has paid for via Oparko.`,
            sub_point_1_11: `All parking purchased/reserved via Oparko is at your own risk, unless otherwise specifically stated in these terms and conditions.`,
            sub_point_1_12: `Parkeringskompagniet assumes no responsibility in connection with accidents, theft, damage to persons, vehicles, equipment and effects, in connection with the vehicle being parked in a parking lot. Parkeringskompagniet cannot be held responsible for any kind of indirect loss or consequential damage.`,
        },
    },
    Terms_main_point_8: {
        main_title: 'Access to complaints',
        sub_point_1: `In the event of a defective Service or an incorrect charge, the User must immediately and no later than 30 days after the service in question was delivered or after the User ascertained or should have ascertained the incorrect charge in question, submit a written complaint to Parkeringskompagniet's customer service. The complaint must clearly state the deficiency or error. The user must provide reasonable assistance to Parkeringskompagniet in connection with any investigations in connection with the complaint.`,
        sub_point_2: `Complaints about imposed control fees must be made to the issuer of the imposed control fee(s).`,
    },
    Terms_main_point_9: {
        main_title: 'Terms and Prices',
        sub_point_1: `The User is liable at any time for all payments made via Oparko.`,
        sub_point_2: `Payments are made by the User registering a valid means of payment in the App. Payment will be charged via the means of payment registered by the User.
        When a vehicle is marked as active, the User has accepted that payment is automatically made via the specified preferred means of payment.
        Administrator will be invoiced for payments where Erhvervsparkering is registered as a means of payment. The user selects and approves the payment method in connection with the application for registration.
        It is the User's responsibility to indicate the correct and valid means of payment before starting the parking.
        Incorrect use of Commercial Parking is solely a matter between the Administrator and the User.`,
        sub_point_3: `In the event of late payment, any reminders will be charged in accordance with current legislation.`,
        sub_point_4: `All prices are stated in Danish kroner and include VAT. The price to be paid for the parking depends on the selected parking space, the selected parking period, and whether an ongoing parking is extended.`,
        sub_point_5: `When creating a parking space and when extending it, it is stated how much the parking or extension in question costs, and this amount must be approved before the parking space is created and/or extended.`,
        sub_point_6: `In some cases, an operating fee will be charged in addition to the hourly rate for parking. This will always appear on the App or Website when purchasing a permit.`,
        sub_point_7: `Parkeringskompagniet reserves the right to change charges and prices.`,
        sub_point_8: `NOTE: Oparko reserves the right to update and change the content of the App as well as the website without prior notice.`,
    },
    Terms_main_point_10: {
        main_title: 'Right of withdrawal',
        sub_point_1: `Pursuant to the Danish Consumer Contracts Act (Act No. 1457 of 17 December 2013), the User has the right to withdraw from the Agreement by notifying Oparko customer service within 14 days of approval of the registration.`,
        sub_point_2: `The user agrees that the Purchase Act (Act No. 1853 of 24/09/2021) normal right of cancellation of 14 days only applies to parking which has not yet entered into force (valid) at the time of cancellation. If the User thus orders parking with a validity period from the same day (immediately), the right of cancellation also ends on the same day (immediately) and the otherwise valid 14-day right of cancellation is thus waived by the User. In the case of the User's purchase of parking with the start of the validity period later than 14 days from the conclusion of the subscription agreement, the User has a 14-day right of cancellation.`,
        sub_point_3: {
            sub_point_3_title: `To exercise the right of cancellation, the cancellation notice must be submitted to Oparko no later than the 14th day of the day on which the payment is registered with Oparko.`,
            sub_point_3_1: `If the deadline expires on a public holiday, Saturday, Constitution Day, Christmas Eve or New Year's Eve, the deadline is extended to the following weekday.`,
            sub_point_3_2: `In order to exercise the right of cancellation, the User must send Parkeringskompagniet an e-mail in which the User writes that the purchase/reservation is cancelled (see section 12.2.8.).`,
        },
        sub_point_4: `Please be aware that entering the parking lot/authorizing the purchase in the app or <a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk</a> is considered an acceptance of the start of parking. If you regret your purchase in the app or <a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk</a>, your refund will be reduced by a proportional part of the total price for the parking in the purchased period, based on the number of days/hours/minutes the vehicle has been parked until Oparko received the registration on the exercise of the right of withdrawal`,
    },
    Terms_main_point_11: {
        main_title: 'Conditions for cancellation',
        sub_point_1: `The User can choose to close the User's account in the App, or by closing the User's account via the website, whereby the User's Oparko account is invalidated, so that the User no longer has the opportunity to pay for parking via Oparko.`,
        sub_point_2: `Unpaid matters will be charged in accordance with the agreement entered, even if the User closes the Oparko account.`,
    },
    Terms_main_point_12: {
        main_title: 'Duration, early termination, etc.',
        sub_point_1: `This Agreement is valid from Oparko's approval of the User and until it is terminated in accordance with the terms of the Agreement or until otherwise agreed in writing.`,
        sub_point_2: {
            sub_point_2_title: `Oparko is entitled to immediately suspend the User's access to the Services, cancel the User's account and/or terminate the Agreement with immediate effect, if`,
            sub_point_2_1: `The User is in material breach of its obligations under this Agreement;`,
            sub_point_2_2: `The User does not fulfill, or there is reasonable reason to assume that the User will not fulfill his payment obligations in relation to Oparko,`,
            sub_point_2_3: `The user, in Oparko's reasonable assessment, can be expected to become unable to pay;`,
            sub_point_2_4: `The user uses Oparko Services in violation of the Agreement or which otherwise damages or negatively affects Oparko or any third parties;`,
            sub_point_2_5: `The User has repeatedly parked a vehicle in violation of applicable laws, rules and/or regulations specified by the relevant Parking Provider;`,
            sub_point_2_6: `The user has submitted incorrect, incomplete or misleading information, or`,
            sub_point_2_7: `Oparko, based on an overall assessment, finds it likely that the User may be involved in or involved in criminal activities.`,
            sub_point_2_8: `Termination of the Agreement must be done in writing to <a href="mailto:service@oparko.com" class="text-pk-blue">service@oparko.com</a> or via the form on the Website: <a href="https://parkeringskompagniet.dk/kontakt/" class="text-pk-blue">https://parkeringskompagniet.dk/kontakt/</a>`,
        },
        sub_point_3: `Termination of the Agreement (for whatever reason) does not affect the rights and/or obligations that one Party has incurred towards the other Party prior to the date of termination of the Agreement.`,
    },
    Terms_main_point_13: {
        main_title: 'Oparko liability',
        sub_point_1: `Oparko's total liability for damages may under no circumstances exceed an amount corresponding to DKK. 38,000.00, unless Oparko has acted intentionally or has shown gross negligence.`,
        sub_point_2: `Parkeringskompagniet is not responsible for accidents, theft, personal injury, damage to vehicles, equipment or other effects mounted on or in the vehicle.`,
        sub_point_3: `Parkeringskompagniet is not responsible for either indirect loss or consequential damage. Parkeringskompagniet and Oparko are not liable for damages in relation to the User's relationship with any third party`,
        sub_point_4: {
            sub_point_4_title: `Parkeringskompagniet is not responsible for damages or losses incurred by the Administrator and/or the User as a result of:`,
            sub_point_4_1: `Parking in a parking lot;`,
            sub_point_4_2: `The User's error or negligence, including if the User has not correctly started or ended a parking (regardless of whether it has been chosen to receive a reminder or a push message from Oparko), or if the User has entered an incorrect area code in relation to a Parking Space and/or registration number, at the start of a parking (regardless of whether the User has entered such an area code manually or this has been suggested via the location function in the App);`,
            sub_point_4_3: `that the User has not complied with his obligations according to this Agreement and terms of use of Oparko or that the User has otherwise not followed Oparko's instructions;`,
            sub_point_4_4: `that the User has parked the vehicle in violation of applicable laws, rules and/or regulations indicated on the posted signage in the parking lot in question where the User parks the vehicle;`,
            sub_point_4_5: `failure or insufficient functionality of the User's mobile phone or other technical equipment (such as the equipment being switched off or the battery running out of power);`,
            sub_point_4_6: `breakdowns, interruptions or delays in telephone, Internet or other communications networks, or the acts or omissions of telecommunications operators, which affect the functionality or availability of the Service;`,
            sub_point_4_7: `error or defect in a technical function which indicates the price of the Parking Fee in question;`,
            sub_point_4_8: `that the Service has been interrupted by Oparko due to matters which turn out to be incorrect, but which Oparko had reason to believe were correct at the time of the interruption and which justified the interruption;`,
            sub_point_4_9: `interruptions or insufficient access to one or more Services that Oparko could not have reasonably foreseen;`,
            sub_point_4_10: `that a Parking Space proposed by Oparko is not available when the User arrives there;`,
            sub_point_4_11: `unauthorized use of Login information and/or Services; or`,
            sub_point_4_12: `force majeure (see clause 16).`,
        },
        sub_point_5: `Under no circumstances is Oparko liable for indirect damages such as loss of operation and loss of data. Oparko is not liable for damages in relation to the Administrator's and/or the User's relationship with any third parties.`,
    },
    Terms_main_point_14: {
        main_title: `The user's liability for damages`,
        sub_point_1: `The User must indemnify Parkeringskompagniet for compensation claims or losses as a result of the User's error or negligence, the User's failure to fulfill its obligations under this Agreement or otherwise failure to comply with Oparko's instructions. The User must also indemnify Oparko for claims from third parties which are a direct or indirect consequence of the User's use of a Service.`,
    },
    Terms_main_point_15: {
        main_title: `Intellectual property rights`,
        sub_point_1: `The User accepts that software can never be tested in all possible situations, and that deviations from the agreed functionality as well as unforeseen errors and interruptions may occur. Oparko is generally available 24 hours a day, with the exception of planned interruptions for upgrades, changes and maintenance. The right to update Oparko with new functions or otherwise make changes, for example to adapt the system to new technology, new safety standards or new administrative procedures etc. is reserved by Parkeringskompagniet. Parkeringskompagniet assumes no responsibility for any damages or losses in connection with a lack of access to Oparko, Oparko being out of service or containing errors.`,
        sub_point_2: `All copyrights (including rights to source codes, computer programs, algorithms and object codes) and other intellectual property rights (including patent rights and trademarks) regarding Oparko and its content are owned or licensed by Parkeringskompagniet or its suppliers and partners. The Administrator or the User is not, pursuant to this Agreement, assigned any of these rights. It is not permitted to use Oparko or its content for commercial purposes. The user has no right to copy, distribute, sell, publish, transfer, lend, sublicense, modify or otherwise dispose of the software contained in Oparko. The user has no right to disassemble, reverse engineer, decompile or otherwise attempt to access the software source code.`,
        sub_point_3: `That all information and data transmitted to Oparko is free of harmful elements, source codes or malware (such as viruses, worms and Trojan horses) must be secured and guaranteed by the User. Information that the User uploads to My Pages or makes available via the App must not infringe the intellectual property rights of third parties or otherwise contravene laws, regulations or the rights of others. It is the User's responsibility that the intellectual property rights of third parties or others are not infringed.`,
        sub_point_4: `Unless Oparko has acted grossly negligently or intentionally, Oparko is not responsible for any data being made available to third parties. Oparko uses usual security measures to protect data`,
    },
    Terms_main_point_16: {
        main_title: `Force majeure`,
        sub_point_1: `Oparko is not liable for damages or liability for breakdowns or delays in the implementation of the terms of this Agreement as a result of circumstances beyond Oparko's reasonable control.`,
    },
    Terms_main_point_17: {
        main_title: `Confidentiality`,
        sub_point_1: `The user accepts that Oparko reports any misuse of the Services, illegal activities, illegal or inappropriate behavior and/or suspicions thereof to the police or other competent authorities.`,
    },
    Terms_main_point_18: {
        main_title: `Choice of law and disputes`,
        sub_point_1: `This Agreement is governed by and must be interpreted in accordance with Danish law. Any dispute, disagreement or any claim arising on the basis of or in connection with this Agreement, or any breach, termination or invalidity thereof, must basically be resolved by voluntary agreement between the Parties. If agreement cannot be reached in the first instance, a discussion will be held between a representative chosen by the User and the Parkeringskompagniet. If the parties cannot reach an agreement, the dispute can be dealt with by the Danish courts with the district court in Aarhus as the first instance. This clause 19.1 applies after the termination or expiration of this Agreement.`,
        sub_point_2: `If a provision in this agreement is set aside as invalid, the Agreement must otherwise be maintained, and the invalid provision replaced with a valid provision, as far as possible.`,
        sub_point_3: `The Parking Company reserves the right to change these general terms and conditions for use of the Parking Company. If this happens, the Parking Company must immediately inform about this and make a new version of the general terms and conditions for use of Oparko available. If the User then continues to use the services, this is considered an acceptance of these changes. Regardless of this, the Parking Company reserves the right to change prices and fees/charges as a result of increases in operating costs, other costs and increases in fees and charges, etc. The general terms and conditions applicable at all times can be found in the App or on www.parkeringskompagniet.dk under the item "Customer service".`,
    },
    Subscription_terms_heading: `SUBSCRIPTION TERMS`,
    Subscription_terms_1: {
        main_title: `When creating and using Oparko Subscription Parking, in addition to the below, you also accept the general terms and conditions for using Oparko. These conditions appear in the Oparko app and at <a href="https://www.parkeringskompagniet.dk/oparko" class="text-pk-blue">www.parkeringskompagniet.dk/oparko</a>`,
        sub_point_1: `By entering into an agreement on subscription parking, the User obtains a time-limited digital parking permit.`,
    },
    Subscription_terms_2: {
        main_title: `The subscription parking agreement applies to the selected number of subscription parking agreements, which entitles you to park in the same number of parking bays at the selected parking lot. For subscription parking, it is a condition of validity that the vehicle is correctly registered in Oparko. Correct and valid registration requires that the registration number is correctly registered and that the vehicle is correctly registered on the subscription parking product. The registered vehicle may park in parking bays that are not otherwise reserved. This applies around the clock, every day of the week.`,
        sub_point_1: `Subscription parking will appear under active parking, rented parking spaces.`,
        sub_point_2: `It is a valid condition for correct and valid registration that the registered vehicle has been selected as a favorite car and appears correctly registered on the subscription parking product under rented parking spaces.`,
    },
    Subscription_terms_3: {
        main_title: `The subscription parking agreement applies to the use of a marked parking stall on a "first come, first served basis". That is With the subscription parking agreement, the user obtains only a "floating right" to a marked parking space that is not reserved in any other way. In the event that there is no available marked parking bay, Parkeringskompagniet cannot be held responsible for this, just as the user is not entitled to a reduction of the fee.`,
        sub_point_1: `The fact that the User does not use subscription parking for the entire selected period does not mean that the User is entitled to a reduction of the remuneration.`,
    },
    Subscription_terms_4: {
        main_title: `The subscription parking agreement comes into force from and including the date chosen by the User, and runs for the stated period. The subscription parking agreement can be terminated immediately by Parkeringskompagniet without further justification. The user can cancel at the earliest at the end of a purchased period. Termination occurs if the User does not renew or extend the Subscription Parking Agreement. In the event that the User has selected automatic renewal, the User must stop automatic renewal in order to terminate the subscription parking agreement.`,
        sub_point_1: `The user can only terminate the subscription parking agreement at any time until the end of the selected period. In that case, no money will be withdrawn from the User's payment card until a new subscription parking agreement is created`,
        sub_point_2: `The subscription parking is valid from the selected date and for the selected period. A subscription parking agreement is therefore not necessarily valid from the 1st to the 1st of a month, unless the User has chosen this.`,
    },
    Subscription_terms_5: {
        main_title: `The user is responsible at all times for ensuring that there is a valid subscription agreement on the vehicle parked in the parking lot. It is also the User's own responsibility that associated vehicles are registered correctly and adequately on the profile. An control fee may be imposed if a parked vehicle has not been correctly and adequately registered. The control fee cannot subsequently be waived.`,
    },
    Subscription_terms_6: {
        main_title: `The content of the subscription parking agreement, prices and any special conditions for the Product Packages will be indicated at all times on the Website and in the App. The remuneration is paid in advance. The first time at the conclusion of the subscription parking agreement.`,
        sub_point_1: `In some cases, the user will be provided with a unique product code, which must be used during registration. In connection with receiving the unique product code, the User is informed about how the user must behave in connection with the payment obligation and how registration of the unique product code must take place.`,
        sub_point_2: `In some cases, the user will be provided with a unique product code, which must be used during registration. In connection with receiving the unique product code, the User is informed about how the user must behave in connection with the payment obligation and how registration of the unique product code must take place.`,
    },
    Subscription_terms_7: {
        main_title: `The price figure remuneration is adjusted each year on 1 January without further notice, where it is adjusted with the net price index for October. in the previous year, however with a minimum of 3% (rounded to the nearest whole kroner) in relation to the remuneration payment in the previous year. Index adjustment of the remuneration takes place on the basis of the remuneration applicable immediately before the adjustment, regardless of whether this remuneration may have arisen by adjustment in a different way than by index adjustment.`,
        sub_point_1: `In addition to the above index regulation, Parkeringskompagniet reserves the right to change prices and fees/charges as a result of increases in operating costs, other costs and increases in fees and charges, etc. Such changes take effect at the earliest at the end of the current month + 1 month after the user has been informed of such changes.`,
    },
    Subscription_terms_8: {
        main_title: `Parkeringskompagniet reserves the right for price errors, price changes and update errors.`,
    },
    Subscription_terms_9: {
        main_title: `If the User has chosen automatic renewal, it is the User's own responsibility to "stop" the automatic renewal by terminating the Subscription Parking Agreement. The fact that payment is stopped is not to be considered a termination. Payment is collected until the Subscription Parking Agreement is terminated.`,
    },
    Subscription_terms_10: {
        main_title: `All traffic and parking is at the user's own risk. Parkeringskompagniet is not responsible for theft or damage that may be caused to the user's vehicle, regardless of the reason for this.`,
    },
    Subscription_terms_11: {
        main_title: `The subscription parking agreement may not, without written consent from Parkeringskompagniet, be used for anything other than parking the correctly registered vehicle, with a registered total weight of max. 3,500 kg.`,
    },
    Subscription_terms_12: {
        main_title: `With this Subscription Parking Agreement, the user is expressly made aware that entering into this Subscription Parking Agreement does not entitle the user to cancel imposed control fees. The subscription parking agreement is only valid from the time the registration number is correctly registered. The subscription parking agreement is only valid for the vehicle that is correctly registered.`,
        sub_point_1: `The subscription parking agreement only entitles you to park in the area that appears in the subscription parking agreement, in the marked parking bays where it does not appear that there are special rules applicable to parking in these parking bays. Attention is drawn to the fact that the subscription parking agreement is not a valid parking permit for parking in parking stalls that have been reserved.`,
    },
    Subscription_terms_13: {
        main_title: `In the event of the user's significant breach of the agreement, Parkeringskompagniet may terminate the Subscription Parking Agreement with immediate effect. Material breach includes, but is not limited to, non-payment of the agreed remuneration, breach of the provisions of this agreement as well as any violation of the parking regulations. In the event of a significant breach, Parkeringskompagniet is entitled to impose an control fee and possibly remove or impound the user's vehicle until the due fees and costs have been paid. The user does not have access to set-off, even if there is/are disagreements about the claim.`,
        sub_point_1: `Parkeringskompagniet can terminate the subscription parking agreement in writing with 1 month's notice in the event that maintenance, conversion, renovation etc. must be carried out. conditions that result in Parkeringskompagniet being permanently unable to use the parking bay any longer.`,
    },
    Subscription_terms_14: {
        main_title: `Pursuant to the Danish Consumer Contracts Act (Act no. 1457 of 17 December 2013), the User has the right to withdraw from the Agreement by notifying Parkeringskompagniet's customer service within 14 days of approval of the registration. In order to use the right of cancellation, the cancellation notice must be submitted to Parkeringskompagniet no later than the 14th day of the day on which the payment is registered with Oparko.`,
        sub_point_1: `If the deadline expires on a public holiday, Saturday, Constitution Day, Christmas Eve or New Year's Eve, the deadline is extended to the following weekday`,
        sub_point_2: `To exercise the right of cancellation, the User must send us an e-mail or a letter in which the User writes that the purchase/reservation is cancelled. Please be aware that entering the parking lot/authorizing the purchase in the app or www.parkeringskompagniet.dk is considered acceptance that parking has begun and an acceptance that the 14-day right of withdrawal is waived. If you then regret your purchase in the app or www.parkeringskompagniet.dk, your refund will be reduced by a proportional part of the total price for the parking in the purchased period, based on the number of days/hours/minutes the vehicle has been parked until Parkeringskompagniet received the registration on the exercise of the right of withdrawal`,
    },
    Subscription_terms_15: {
        main_title: `Any disputes between Parkeringskompagniet and the User regarding the understanding of this agreement must be settled by the ordinary courts applying Danish law. The court in Aarhus has been agreed as the place of jurisdiction.`,
    },
    Subscription_terms_16: {
        main_title: `The user is not entitled to sublet the parking stall. Violation of this is considered a breach, cf. point 13.`,
    },
    Subscription_terms_17: {
        main_title: `Parkeringskompagniet is the parking administrator on behalf of the space owner/space owner's property administrator. All inquiries regarding this contract must be made to Parkeringskompagniet. If Parkeringskompagniet ceases to be the parking administrator, the agreement will be transferred to a new parking administrator.`,
    },
    Subscription_terms_18: {
        main_title: `In relation to the storage and processing of personal data, please refer to the data policy in force at all times, which appears at <a href="www.parkingskompagniet.dk/datapolitik/" class="text-pk-blue">www.parkingskompagniet.dk/datapolitik/</a>. If the information is to be deleted, this is considered termination of the subscription parking agreement. This is because, in these cases, Parkeringskompagniet cannot maintain the agreement without the mentioned information.`,
    },
    Subscription_terms_footer_heading_1: `NOTE that the User has a duty to keep the information registered in Oparko up to date. That is The User must notify Parkeringskompagniet if there is a change in address, e-mail, etc. or possibly registration number of the vehicle to be used in Oparko. If this is not complied with, Parkeringskompagniet is entitled to delete the already registered information and cancel the subscription parking agreement in order to comply with the applicable personal data legislation.`,
    Subscription_terms_footer_heading_2: `Parkingkompaniet ApS`,
    Footer_contact_info: {
        contact_title: `Contact Information`,
        company_name: `Parkingkompaniet ApS`,
        address_line: `Trindsøvej 4, 8000 Aarhus`,
        cvr: `CVR: 37127485`,
        email: `E-mail: <a href="mailto:service@oparko.com" class="text-pk-blue">service@oparko.com</a>`,
        tel: `Phone: <a href="tel:+ 45 88 74 31 94" class="text-pk-blue">+ 45 88 74 31 94</a>`,
        website: `<a href="https://www.parkeringskompagniet.dk" class="text-pk-blue">www.parkeringskompagniet.dk</a>`,
        updatedAt: `Revised on 16.11.2022`,
    },

    // Act as user
    'Take Over': 'Take over',

    // Old Terms
    Terms_title: 'Terms and conditions',
    Terms_heading: 'DATAPOLICY AND COOKIES',
    Terms_last:
        'It is the drivers full responsibility that the information provided is correct. At the same time, Parkeringskompagniet disclaims responsibility for any errors during creation.',

    Terms_1: {
        para_1: 'These guidelines apply to the information we collect on the people who provide these through the website, email and by phone. Upon providing Parkingskompagniet with personal information, you also consent to us processing your personal data in accordance with our datapolicy Personal data refers to any information related to an identifiable person We retain the right to change and update our datapolicy in accordance to the law and will, to the best of our ability, notify our customers of any changes',
        para_2: 'Databehandler er Parkeringskompagniet ApS',
        para_3: 'Ryesgade 7b, 8000 Aarhus',
        para_4: 'CVR-nr: 37127485',
        link_1: 'Telefon: <a href="tel:+ 45 88 77 31 50" class="text-pk-blue">+ 45 88 77 31 50</a>',
        link_2: 'Mail: <a href="mailto:service@oparko.com" class="text-pk-blue">service@oparko.com</a>',
    },

    Terms_2: {
        heading: 'Personal data policy',
        para_1: 'Personal data is only stored, if they are voluntarily submitted and we store them only in so far they are relevant for us',
        para_2: 'If you provide us information regarding another person, it is your responsibility to ensure that you have the right to provide us with this information.',
        para_3: 'We will only disclose your personal information, in so far that it is necessary for the service we provide.',
        link_1: 'You retain the right at all time to perview the personal data we have stored regarding yourself, and should they be incorrect, you retain the right to have the aforementioned information corrected. Furthermore you always retain the right to have your profile deleted from our systems. You always retain all the rights the European Union general data protection regulation has ensured private individuals. Should you have any inqueries regarding your personal data, please contact <a href="mailto:service@oparko.com" class="text-pk-blue">service@oparko.com</a>.',
    },

    Terms_3: {
        heading: 'Collection and processing of your personal data',
        para_1: 'We register and store your personal data, depending on the services you use. Should you eg. submit a complaint or become a customer at Parkeringskompagniet, your name, email-address and phonenumber will typically be stored. We store your personal data in order to facilitate the best service possible for you. You will be informed at any time in connection with the collection of information.',
        ul: {
            heading:
                'Collection of personal information generally happens in connection with:',
            list: [
                '♦ Payment of a parking fee',
                '♦ Contact in case of complaint or interest in making an agreement',
                '♦ Parking fee issuance',
                '♦ Parking control',
                '♦ Renting or reservation of a parking space',
                '♦ By using our app',
                '♦ Use of service that require payment, will necessary creditcard data and invoice information be stored',
                '♦ Newsletters and monthlyreports, insofar as you are already an existing customer at Parkeringskompagniet',
            ],
        },
    },

    Terms_4: {
        heading: 'Disclosure of personal data',
        para_1: 'We do not disclose your personal information with others, unless you have given your consent or in the case that it is required by the applicable legislation on personal data policy.',
        row_1: {
            heading: 'The legal basis for processing',
            para_1: 'Article 6 of the Data Protection Regulation, subsection 1, letter b, when a contract has been entered into between you as a parker and Parkeringskompagniet ApS',
            para_2: 'Article 6 of the Data Protection Regulation, subsection 1, letter f, when Parkeringskompagniet ApS has a legitimate interest in pursuing a claim against you as a parker, e.g. if you have parked in violation of the regulations in a parking space and have been charged a parking fee.',
            para_3: 'Article 6 paragraph 1, letter a of the Data Protection Regulation, when Parkeringskompagniet ApS has obtained your consent to send you newsletters.',
        },

        row_2: {
            heading: 'Source',
            para_1: 'Parkeringskompagniet collects personal data directly from you and/or third parties, eg. from our customer or from our subcontractors.',
        },

        row_3: {
            heading: 'Where is your personal data stored?',
            para_1: 'Your personal data is stored on our internal network and can only be accessed with special rights. We do not have access to paymentinformation, as these are encrypted by our payment partners.',
            para_2: 'Cookies is a textfile, that is placed in the device you use to connect to our website, this makes it possible to recognize the device and gather information on which site and functions that are visited. Cookies contain data in an anonymized form.',
            para_3: 'You are anonymous in our cookie-data, until you yourself subscribe to any of our services or contact us and provide your personal information.',
            link: 'Our website uses Google Analytics, which is a website that provides us with webbased analysis service - they use cookies. This is used solely for the purpose of improving the experience of our users. We collect exclusively information on your use of Parkeringskompagniet’s website. Google Analytics collects information regarding your OS, browser, IP-address, previously visited website and date and time for when you visited our website. We do not use data from cookies to collect personal data and none of the information provided by cookies, is ever sold or disclosed. For more information on Google Analytics’ use of cookies, please visit: <a href="https://policies.google.com/privacy?hl=da" class="text-pk-blue">HTTPS://POLICIES.GOOGLE.COM/PRIVACY?HL=DA</a>',
        },

        row_4: {
            heading: 'Opt-out of cookies',
            link: 'Should you at some point wish to opt out of cookies, you can visit your browser-settings and turn them off from there. You can read more about opting out of cookies on your browser, at: <a href="http://www.minecookies.org/" class="text-pk-blue">WWW.MINECOOKIES.ORG</a>',
        },

        row_5: {
            heading: 'What happens if I opt-out of cookies?',
            para_1: 'If you opt out of cookies, it will not be registered when you browse Please be advised, if you disable cookies, some functions on the website might not work as intended and may result in the website not functioning properly.',
        },

        row_6: {
            heading: 'Why do we provide information on cookies?',
            para_1: 'We provide information on cookies, as all Danish websites are required to inform on the use of these, according to the requirement on information and consent to storing of or access to information of the end user’s terminal equipment.',
        },

        row_7: {
            heading: 'Do you wish to contact us?',
            link: 'If you have unsolved questions or wish to contact us further, please refer to the <a href="https://parkeringskompagniet.dk/kontakt/" class="text-pk-blue">kontakt siden</a>',
        },
    },
    ReceptionUser: {
        countryError: 'Country have not been chosen',
        registrationError: 'Registration no. have not been chosen',
        emailError: 'Email have not been chosen',
        customEndDateError: 'Select end time (at least 30 min from now)',
        phoneNumberError: 'Phone number have not been chosen',
        acceptError: 'Accept terms have not been chosen',
        countryCodeError: 'Country Code have not been chosen',
    },
    'Select the Date of Parking': 'Select the Date of Parking',
    'Enter your Full Name': 'Enter your Full Name',

    Phonenumber: 'Phonenumber',
    'Enter your Email': 'Enter your email',
    'Choose your enddate': 'Choose enddate',
    'Valid from': 'Valid from',
    'Valid until': 'Valid until',
    'Create new guest parking': 'Create new guest parking',
    'Custom end date': 'Ability to choose enddate',
    'Max time in min (0 is no limit)': 'Max time in minutes (0 = no limit)',

    'Please enter your registration number':
        '* Please enter your registration number',
    'Please enter your E-mail address': '* Please enter your E-mail address',
    'Please choose a date/time':
        '* The end date/time should be at least 30 minutes after the current time',
    'Please enter your phone number': '* Please enter your phone number',
    'Please accept our terms to continue': '* Required',

    ConfirmPermit: {
        lineOne: 'A guest parking lot has now been set up at',
        for: 'for',
        on: 'on',
        at: 'at',
        lineTwo: 'which is valid up to and including',
    },
    'Receive SMS?': 'Receive SMS?',
    'Guest Parking has been created!': 'Guest Parking has been created!',
    'A Guest parking permit has now been created':
        'A Guest parking permit has now been created',

    // Entities

    Reception: 'Reception',
    HotelReception: 'Hotel Reception',
    EmployeeReception: 'Employee Reception',
    TimesReception: 'Times Reception',
    HotelAdmin: 'Hotel Admin',
    ConfirmationReception: 'Confirmation Reception',
    HotelReceptionConfirmation: 'Hotel Reception Confirmation',
    StayNTouchReception: 'Stay-N-Touch Reception',
    APIPartner: 'API Partner',
    SMSConfirmationReception: 'SMS Confirmation Reception',
    Guest_parking_created:
        'A guest parking for {{regNo}} has been created, valid until {{date}}.',
    'Something went wrong. Please try again.':
        'Something went wrong. Please try again.',
    Enter_registration_number: 'Enter registration number',
    Confirm: 'Confirm',
    Clear_all: 'Clear all',

    View: 'View',
    Edit: 'Edit',
    AppFines: 'AppFines',
    AppPermits: 'AppPermits',
    NOT_DEFINED: 'NOT_DEFINED',
    SELF_TICKETING: 'SELF_TICKETING',
    SelfTicketing: 'SelfTicketing',
    'Self Ticketing': 'Self Ticketing',
    COMBI: 'COMBI',
    P_WARDEN: 'P_WARDEN',
    CAMERA_CONTROL: 'CAMERA_CONTROL',
    Partner: 'Partner',
    Private: 'Private',
    Company: 'Company',
    'Not Handled': 'Not Handled',
    'Not handled': 'Not handled',
    Approved: 'Approved',
    Rejected: 'Rejected',
    'Other - write a comment in the note':
        'Other - write a comment in the note',
    'Outside Booth': 'Outside Booth',
    'No permission to the area': 'No permission to the area',
    'Parking disc exceeded': 'Parking disc exceeded',
    'Parking or full stop on prohibited area':
        'Parking or full stop on prohibited area',
    'Parking on fire access road': 'Parking on fire access road',
    'Handicapped parking without permission':
        'Handicapped parking without permission',
    Deleted: 'Deleted',
    'Guest Parking': 'Guest Parking',
    Resident: 'Resident',
    'Paid ticket': 'Paid ticket',
    'Employee paid ticket': 'Employee paid ticket',
    'Employee upper basement': 'Employee upper basement',
    'Employee lower basement': 'Employee lower basement',
    Permanent: 'Permanent',
    License: 'License',
    Reminder: 'Reminder',
    'Active - foreign': 'Active - foreign',
    'Debt collection - Paragraph': 'Debt collection - Paragraph',
    Ended: 'Ended',
    Foreign: 'Foreign',
    Paused: 'Paused',
    'Debt collection - Paragraph - installment agreement with demand':
        'Debt collection - Paragraph - installment agreement with demand',
    'Debt collection - Paragraph - with demand':
        'Debt collection - Paragraph - with demand',
    'Debt collection - Liquidation': 'Debt collection - Liquidation',
    'Debt collection - Liquidation - installment agreement with demand':
        'Debt collection - Liquidation - installment agreement with demand',
    'Complaint Council': 'Complaint Council',
    All: 'All',
    'Occupancy in': 'Occupancy in percent',

    // Subscription
    Next: 'Next',
    Previous: 'Previous',
    Subscription: 'Subscription',
    Submit: 'Submit',
    Subscribers: 'Subscribers',
    'Already subscribed': 'Already subscribed',
    'Your Cars': 'Your Cars',
    'E.g.': 'E.g.',
    'Location Name': 'Location Name',
    '12th Star Avenue': '12th Star Avenue',
    'Step 1: Which parking lot do you want to park at?':
        'Step 1: Which parking lot do you want to park at?',
    'Step 2: Which license plate does your car have?':
        'Step 2: Which license plate does your car have?',
    'Step 3: When should your subscription start?':
        'Step 3: When should your subscription start?',
    'Step 4: Your monthly price is based on the selected parking lot, and will be:':
        'Step 4: Your monthly price is based on the selected parking lot, and will be:',
    'Step 5: To be allowed to create a subscription for this private parking lot, you must enter a pin code below. Next, click on Create to go to the payment site. Once you are done, you will be redirected back to our page, where you can find your subscription in the table.':
        'Step 5: To be allowed to create a subscription for this private parking lot, you must enter a pin code below. Next, click on Create to go to the payment site. Once you are done, you will be redirected back to our page, where you can find your subscription in the table.',
    'Step 5:  Click on Create to go to the payment site. Once you are done, you will be redirected back to our page, where you can find your subscription in the table.':
        'Step 5:  Click on Create to go to the payment site. Once you are done, you will be redirected back to our page, where you can find your subscription in the table.',
    'Below you can below see the available days, times and prices':
        'Below you can below see the available days, times and prices',
    'Available days, times and prices': 'Available days, times and prices',
    'Transaction fee': 'Transaction fee',
    'Subscription Info': 'Subscription Info',
    'Monthly price': 'Monthly price',
    'Last recurring payment date': 'Last recurring payment date',
    'Car Info': 'Car Info',
    'Enter registration number': 'Enter registration number',
    'Buy monthly subscription': 'Buy monthly subscription',
    'Buy short ticket': 'Buy short ticket',
    'Short ticket': 'Short ticket',
    'The price is': 'The price is',
    'kr pr. month, but the price on specific parking spots may vary':
        'kr pr. month, but the price on specific parking spots may vary',
    'Registration number': 'Registration number',
    'PIN code': 'PIN code',
    'Email id is already registered with us':
        'Email id is already registered with us',
    'Buy ticket': 'Buy ticket',
    'Get ticket': 'Get ticket',
    'Permit id': 'Permit id',
    'Last payment': 'Last payment',
    'Price per period': 'Price per period',
    'Stop subscription': 'Stop subscription',
    'Price: 500 kr. per month (normal price is 625 kr. per month - The discount is active for the rest of 2023)':
        'Price: 500 kr. per month (normal price is 625 kr. per month - The discount is active for the rest of 2023)',
    'Payment Options': 'Payment options',

    // FAQs
    'How do I see and create a permit?': 'How do I see and create a permit?',
    'How do I see the fines that has been made at my parking space?':
        'How do I see the fines that has been made at my parking space?',
    'How do I cancel a fine?': 'How do I cancel a fine?',
    'How do I create a user?': 'How do I create a user?',
    'How do I change the numberplate on an existing permit?':
        'How do I change the numberplate on an existing permit?',
    'How do I extend a permit?': 'How do I extend a permit?',
    "What to do if something doesn't work?": `What to do if something doesn't work?`,
    'How often do you pay out customer shares?': `How often do you pay out customer shares?`,
    'Why am I being paid for a parking fee that is several years old now?':
        'Why am I being paid for a parking fee that is several years old now?',
    'Status and their meanings': 'Status and their meanings',

    answer_1: {
        paragraph: `Click on “Dashboard” and then “Permits”. Here you can see an overview of the latest permits. To find a specific permit, use the search bar and search for the reg. no.
        Click the blue button “+ Create Permit”. Now you must fill in the information needed. Reg. no., Parking lot and Type are mandatory. The Note can be used to give more information about the permit. External note can be seen, when scanning the reg. no. with our app, and is used to give information to the parking wardens that control the parking lot. This could be information like “Parking lot nr. 22” if the permit only allows the car to park in a specific parking lot. Remember to set the End date for the permit, or click the box “Permanent permit”. Finally, click “Create”.`,
    },

    answer_2: {
        paragraph: `Click on “Dashboard” and then “Fines”. Here you can see the latest fines. If you want to search for a specific fine, use the search bar at the top, and search for either the reg. no. or the fine’s ID. Click on a fine to see more info about it.  “Status” shows how far the fine is in the recovery process. “Active” means that the fine is created and awaiting. “Debt collection” means that the fine is under recovery and letters has been sent. “Paid” means that we have received the payment for the fine. “Settled” means that we have paid you your share of the fine. “Hidden” means that the fine has been canceled.`,
    },
    answer_4: {
        paragraph: `Click on the fine you wish to cancel. A window with more info will open. Click on the field “Status” and select “Hidden”. Scroll down and remember to leave a comment on why the fine has been canceled. Remember to click “Save” on the comment. Finally click “Update” and the fine is cancelled.`,
    },
    answer_3: {
        paragraph: `Click on “Dashboard” and then “Users”. Here you can see a list of all the users connected to your organization. To create a new user click on “+ Create User”. Fill in the information. For simplicity, we recommend that the Email is used as the Username also. Select which parking lots that the user should have access to. Remember to set an end date, or click the box “Permanent user” if there should be no end date. Finally click “Create”. An Email will then be sent to the user with their login info.`,
    },
    answer_5: {
        paragraph: `On the 'Permits' page, type the number plate of the car into the search field and press enter/Search. In the list below, click on the relevant permission. In the 'Reg. no.' field, change to the new registration number. You then check that the parking space, end date and the rest look correct and click on 'Update'.`,
    },
    answer_6: {
        paragraph: `On the 'Permits' page, type the number plate of the car into the search field and press enter/Search. In the list below, click on the relevant permission. In the 'End date' field on the permit, you can decide when the new expiry date should be. If the permission is to be made permanent, simply check the box 'Permanent permission?' NOTE: You must ensure that 'Status' is set to 'Active'. Check that the car park and the rest look correct and click on 'Update'.`,
    },
    answer_7: {
        paragraph: `It often helps to log out and back in, so we always recommend that you do that first. If it still doesn't work, you are always welcome to write us an email at service@oparko.com. In order for us to help you faster, you are very welcome to send screenshots of the problem (if there is one). If you need help here and now, our phone is open Monday, Tuesday and Thursday 11-15 and Friday 11- 13. Call 8874 3194.`,
    },
    answer_8: {
        paragraph: `In the cooperation agreement/contract we have entered into, you will find answers to how often customer shares are paid. The default is once every three months.`,
    },
    answer_9: {
        paragraph: `How quickly a parking fee is paid after issuance can vary from 'immediately' to 'never'. This means that parking fees that were issued months or years ago may appear on your payout of today, because it has been paid since we last settled with you.`,
    },
    answer_10: {
        paragraph1: `Active: The parkering fee is created in our system.`,
        paragraph2: `Collection: The parking fee has been sent for collection by our business partner and a letter has been sent to the owner of the car`,
        paragraph3: `Paid: When the parking fee has been paid, it's status changes to paid`,
        paragraph4: `Settled: The customer share of the parking fee has been paid out `,
        paragraph5: `Hidden: The parking fee has been canceled`,
        paragraph6: `Protected: The car is owned by a person not living in Denmark`,
    },

    // weekdays
    Mon: 'Mon',
    Tue: 'Tue',
    Wed: 'Wed',
    Thur: 'Thu',
    Fri: 'Fri',
    Sat: 'Sat',
    Sun: 'Sun',
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    Sunday: 'Sunday',
    Hourly: 'Hourly',
    Weekly: 'Weekly',
    Hour: 'Hour',
    hour: 'hour',
    hours: 'hours',
    Day: 'Day',
    day: 'day',
    'half-hour': 'half hour',
    'half-hours': 'half hours',
    days: 'days',
    Week: 'Week',
    weeks: 'weeks',
    Month: 'Month',
    months: 'months',
    'Number of': 'Number of',
    First: 'First',
    to: 'to',
    Free: 'Free',
    'Subscription per month': 'Subscription per month',
    'No limit on': 'No limit on',
    After: 'After',
    Percentage: 'Percentage',
    Count: 'Count',
    Organisation: 'Organisation',
    'All days': 'All days',
    'All day': 'All day',
    Valid: 'Valid',
    month: 'month',
    Per: 'Per',

    // Months
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',

    // popup box - accepted
    accepted_title: 'Purchase accepted',
    accepted_description1:
        'Your have successfully bought a parking permit for your car',
    accepted_description2:
        'If an e-mail has been specified in the previous e-mail field, a confirmation e-mail will be sent to the specified e-mail address.',
    accepted_description3:
        'As documentation for the parking permit you can use:',
    accepted_description4:
        'A print screen of a bank transfer which has been debited',
    accepted_description5: 'Print screen of a Mobilepay-payment',
    accepted_description6: 'The confirmation email which has been sent to you',
    accepted_description7:
        'If there should be any further questions and/or doubts about your payment you can contact us on',
    accepted_description8: 'You can now close the window.',

    Ok: 'Ok',
    // popup box - successfull
    successfull_title: 'Welcome to Oparko!',
    successfull_description: 'Your subscription is now active.',
    successfull_description1:
        'You have received all the information about your subscription in your email. You can view and update details about your subscription directly on the platform by logging in.',
    successfull_description2:
        'You can now close this window to access your account.',

    // popup box - declined
    declined_title: 'Unfortunately, there was a mistake with your payment',
    declined_description:
        'Your subscription account was still created, but it is not active. This means we will not take any payments from you, nor will you have an active permit to park your car.',
    declined_description1:
        'You have received all the necessary information to log in to our platform via your email. From our platform, you can find the payment link to attempt another payment if you wish.',
    declined_description2:
        'Alternatively, you can use the link below to try again:',
    declined_description3: 'Or contact Oparko Support here:',
    declined_description4:
        'If you have any questions regarding the payment, you can contact us at',
    'Open Subscription': 'Open Subscription',
    pay_subscription_heading: 'Pay Subscription',
    pay_subscription_description:
        'You are attempting to pay for your subscription account, please confirm to proceed with your payment.',
    'Cancel Subscription': 'Cancel Subscription',
    cancel_sub_description:
        'We are sorry to see you leave Oparko. If you are sure about canceling your subscription, please click "Accept" to confirm your decision.',
    cancel_sub_description1:
        'Please note that your subscription will remain active until the end of your current billing period. We will not charge you further, and your subscription will become inactive starting next month.',
    'No Future payments': 'No Future payments',

    // Disclaimer texts used in ticket service modal when you buy a ticket
    ticket_service_disclaimer_1:
        'Please note that it is not possible to stop your parking permit early. It is not possible to extend your parking permit, if you want to keep parking, you must buy a new permit when the first one has expired. Furthermore, it is not possible to cancel a permit after it has created.',
    ticket_service_disclaimer_2:
        "We draw particular attention to the fact that it is the user of the car's full responsibility that the information provided is correct. At the same time, Parkeringskompagniet disclaims responsibility for any errors during creation. When you buy a parking ticket, you also agree to our terms and conditions. You therefore also consent to our personal data policy.",
    subscription_service_disclaimer:
        "We draw particular attention to the fact that it is the party's full responsibility that the information provided is correct. At the same time, Parkeringskompagniet disclaims responsibility for any errors during creation. When you buy a subscription, you also agree to our terms and conditions. You therefore also consent to our personal data policy.",
    subscription_service_disclaimer_2: `The subscription parking agreement only entitles you to park in the area that appears in the subscription parking agreement, in the marked parking bays where it does not appear that there are special rules applicable to parking in these parking bays. Attention is drawn to the fact that the subscription parking agreement is not a valid parking permit for parking in parking stalls that have been reserved.`,
    'Update Card': 'Update Card',
    'Pay Subscription': 'Pay Subscription',
    'Update payment card': 'Update payment card',
    update_card_description:
        'You are trying to update your credit card information. Please confirm to proceed with the update.',
    update_card_cancel: 'Unfortunately, there was an error updating your card.',
    update_card_cancel_description:
        'Your subscription account is still active, with an active permit. If you wish to update your card information, please try again.',
    update_card_cancel_description2:
        'If you have any questions regarding updating your card, you can contact us at ',
    update_card_accept: 'Your card update was successful.',
    update_card_accept_description:
        'Your card information has been updated to your subscription account.',
    update_card_accept_description2:
        'If you have any questions regarding updating your card, you can contact us at ',

    // Months
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Apr',
    May: 'May',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Dec',

    // redirectFromPK
    'Important announcement': 'Important announcement',
    'Welcome! You have landed here because Parkeringskompagniet has now become':
        'Welcome! You have landed here because Parkeringskompagniet has now become',
    'We have upgraded to a new management platform to offer you an even better user experience':
        'We have upgraded to a new management platform to offer you an even better user experience.',
    'What should you do now?': 'What should you do now?',
    'Your existing information is automatically transferred. You just have to log in from the new platform now':
        'Your existing information is automatically transferred. You just have to log in from the new platform now',
    'New Functions': 'New Functions',
    'The new platform can do everything you are used to, plus much more!':
        'The new platform can do everything you are used to, plus much more!',
    'Do you have questions? Contact us at service@oparko.com':
        'Do you have questions? Contact us at service@oparko.com',
    'E-mail at': 'E-mail til',
    'Call at': 'Call at',
    'Phone hours:': 'Phone hours:',
    'Go to new admin platform': 'Go to new admin platform',

    // Pay fine
    'How to appeal': 'How to appeal',
    'Get documentation': 'Get documentation',
    'Complaint form': 'Complaint form',
    'Select a reason': 'Select a reason',
    Appeal: 'Appeal',
    'Submit an appeal': 'Submit an appeal',
    'Pay fine': 'Pay fine',
    'License plate': 'License plate',
    'Fine id': 'Fine-id',
    'Fine documentation': 'Fine documentation',
    retrieve_fine_documentation_text:
        "Retrieve fine documentation. Please note that not all fines will result in documentation. The 'Fine-id' is the ID that comes with your fine when you receive it in the mail.",
    'Valid permit': 'Valid permit',
    'Type in complaint here': 'Type in complaint here',
    Attachments: 'Attachments',
    'Select files': 'Select files',
    'Select file': 'Select file',
    complaint_form_text:
        'Please note that all inquiries related to parking fines, including complaints, payments, reminders, and similar matters, must be submitted in writing using the form provided below. It is imperative that we receive all necessary information in written form.',
    Received_fine: 'Why you received a parking fine',
    Offense: 'Select reason',
    '01': 'Parked outside of designated space',
    '001': 'The fine has been issued due to parking outside of a marked parking space. This can be either parking beyond the lines or entirely outside of a designated parking space.As indicated by the signage on the premises, parking is only allowed in marked parking spaces. Violation of this condition results in a parking fine. The parking attendant has documented the parking with photos.',
    '02': 'Parked in an inappropriate location, e.g., in front of a loading zone or traffic area',
    '002': 'The fine has been issued because the parking is inappropriate, for example, in relation to a loading zone, access road, or similar.This is the case if your car, for instance, obstructs access to other roads or blocks traffic in the area.',
    '03': 'No valid permit for the area',
    '003': 'The fine has been issued due to a lack of permission. As indicated by the signage on the premises, permission is required for stopping and parking on the premises.The vehicles stay on the premises exceeds the specified observation time. Therefore, a violation of the premises applicable terms has occurred, resulting in a parking fine.',
    '04': 'Permit not visible or expired',
    '004': 'The fine has been issued because the permit was either not visible or had expired.If the parking attendant has not been able to sufficiently verify the permit or if it has expired, the conditions have been violated. Violating the conditions results in a parking fine.',
    '05': 'Not paid for parking in a paid parking space',
    '005': 'The fine has been issued due to a lack of permission. As indicated by the signage on the premises, permission is required for stopping and parking on the premises.We have not registered a purchased permit in our system for the specific space, and thus, the premises conditions have been violated. Violating the conditions results in a parking fine.',
    '06': 'Exceeded the time on the parking disc',
    '006': 'The fine has been issued because the cars parking disc exceeded the allowed time. The signs indicate that parking is allowed for a limited time with a correctly set parking disc.The cars parking disc was set incorrectly, not set at all, or the car was parked for longer than allowed. The parking attendant has documented the parking with photos.',
    '07': 'You have altered the time on the parking disc without moving the car',
    '007': 'The fine has been issued because the parking disc was altered, and parking continued.It is only allowed to park on the premises for a limited time, and by changing the parking disc, one can park for longer than what the rules allow. This results in a parking fine.',
    '08': 'Parking or stopping in a no-stopping zone',
    '008': 'The fine has been issued because the car was parked in an area where stopping and parking are prohibited. This results in a parking fine. The parking has been documented with photos.',
    '09': 'Parking on a fire lane',
    '009': 'The fine has been issued because the car was parked on a fire or rescue lane. Parking on such lanes is not allowed and results in a parking fine. The parking attendant has documented the parking with photos.',
    '10': 'Parking in a disabled parking space without a visible disabled parking sign',
    '010': 'The fine has been issued because the car was parked in a disabled parking space without a visible permit for it. As indicated by the signage, the specific space is a disabled parking space. This means that a disabled parking permit is required.In this regard, reference is made to the "Executive Order on Parking Cards for Persons with Disabilities and Institutional Cards" (BEK no. 782 of 07/08/2019), especially § 13, paragraph 1, which states: "When a parking card or institutional card is used, it must be placed inside the windshield with the front side of the card clearly visible for inspection." Here is a link to the executive order: Link. The parking attendant has documented the parking with photos.',
    '11': 'Parked in a space reserved for electric cars charging, or not moved the car after charging is complete',
    '011': 'The fine has been issued because parking in this area is only allowed for electric cars during charging.If the car is no longer (or was not) charging, parking is not allowed in this area. Therefore, parking is in violation of the premises conditions, resulting in a parking fine. The parking attendant has documented the parking with photos.',
    '12': 'You have not complied with the rules and conditions posted on the premises',
    '012': 'The fine has been issued because the posted conditions on the premises have not been complied with.Violating the conditions results in a parking fine.',
    Disagree:
        'If you believe that your fine is unjustified, you can submit a written complaint, please fill in the fields below',
    Price: 'Price',
    Coords: 'Location',
    Continue: 'Continue',
    Topic: 'Subject',
    'Why you received a control fee': 'Why you received a control fee',
    Cant_find_Fine_id: `Unable to retrieve any 'Fine ID' and 'License Plate'. Please double-check if 'Fine id' and 'License plate' are correct.`,
    Fine_is_paid: 'This fine has already been paid.',
    Cant_get_documentation:
        'It is no longer possible to retrieve documentation for this fine.',
    Send_complain: 'The complaint has been submitted.',
    Error_send_complain:
        'An error occurred, please check that all fields are filled in correctly.',
    // payment accepts
    payment_approve: 'Approved',
    payment_approve_1: 'Payment of the control fee has been completed',
    payment_approve_2:
        'We confirm that your payment of the control fee has been completed. The sent confirmation email serves as documentation that the control fee has been paid.',
    payment_approve_3:
        'We always send the fine notice by mail, but as indicated in the letter, this can be disregarded if sufficient payment has already been made.',
    payment_approve_4:
        'Terms and conditions for payment of the control fee can be read at the following link:',
    payment_approve_5:
        'If you have further questions or doubts regarding the payment, you are welcome to contact us at: ',
    payment_approve_6: 'The window can now be closed.',
    // payment decline
    payment_decline: 'Error during payment',
    payment_decline_l1: 'Sorry, but unfortunately something went wrong.',
    payment_decline_l2:
        'Your payment for the control fee could not be completed.',
    payment_decline_l3:
        'Please double check that the information entered is correct.',
    payment_decline_l4:
        'For more information about payment, please see the terms and conditions at the following link: ',
    payment_decline_l5:
        'If you have further questions or doubts regarding the payment, you are welcome to contact us at:',
    payment_decline_l6: 'The window can now be closed.',
    // Complaint sent
    complaint_appeal_submit: 'Recieved',
    complaint_appeal_submit_1: 'We confirm, that we have received your email',
    complaint_appeal_submit_2:
        'Our complaint team will review your inquiry and respond as soon as possible',
    'Back to start': 'Back to start',
};

export default english;
